import React, { useState, useEffect } from "react";
import {
  Card,
  Upload,
  Button,
  Tag,
  Table,
  Progress,
  Divider,
  Row,
  Col,
  message,
} from "antd";
import {
  UploadOutlined,
  DownloadOutlined,
  ImportOutlined,
  QuestionCircleOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import Papa from "papaparse";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { upsertCheck } from "../../services/checkServices";

const ImportChecksModal = () => {
  const { user } = useAuth();
  const [csvData, setCsvData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]); // Track all records and status
  const STATUS_ALLOWED = [
    "printed",
    "to be printed",
    "emailed",
    "void",
    "mailed",
  ];

  useEffect(() => {
    if (user && accounts.length === 0) {
      fetchAccounts();
    }
  }, [user]);

  const fetchAccounts = async () => {
    try {
      const accountsSnapshot = await getDocs(
        collection(db, `clients/${user.clientId}/accounts`)
      );
      const accountsList = accountsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Filter out accounts with status 'pending validation'
      const filteredAccounts = accountsList.filter(
        account => account.status !== 'pending validation'
      );
      setAccounts(filteredAccounts);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  const handleUpload = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;

        // Validation for required columns
        const requiredColumns = [
          "bankAccountNickname",
          "payableTo",
          "payeeCompanyName",
          "payeeName",
          "payeeEmail",
          "payeePhone",
          "payeeAddress1",
          "payeeAddress2",
          "payeeCity",
          "payeeState",
          "payeeZip",
          "payeeCountry",
          "payeeStatus",
          "amount",
          "date",
          "memo",
          "mailingInfo",
          "comments",
          "status",
          "checkNumber",
        ];

        const missingColumns = requiredColumns.filter(
          (col) => !(col in data[0])
        );
        if (missingColumns.length > 0) {
          alert(
            `Invalid CSV format. Missing columns: ${missingColumns.join(", ")}`
          );
          return;
        }

        if (data.length > 50) {
          alert("You can only import a maximum of 50 checks.");
          return;
        }

        // Validation for status
        let invalidRows = [];

        const validatedData = data.map((row, index) => {
          if (!STATUS_ALLOWED.includes(row.status.toLowerCase())) {
            invalidRows.push(index + 1); // Collect row numbers of invalid rows
            return {
              ...row,
              status: <Tag color="red">Invalid Status</Tag>,
              failReason: `Will be skipped during import. Invalid status "${
                row.status
              }". Allowed statuses: ${STATUS_ALLOWED.join(", ")}`,
            };
          }
          return {
            ...row,
            status: <Tag color="blue">To be Imported</Tag>,
            failReason: "",
          };
        });

        // Initialize table data with valid records
        setCsvData(data);
        setTableData(validatedData); // Show the records in the table
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const processCsv = async () => {
    setLoading(true);
    const updatedTableData = [...tableData];
    setProgress(0.01);

    for (let i = 0; i < csvData.length; i++) {
      const check = csvData[i];

      // Skip if the row is not marked as "To be Imported"
      if (updatedTableData[i].status.props.children !== "To be Imported") {
        continue;
      }

      try {
        // Validation logic
        if (
          !check.payableTo ||
          !check.bankAccountNickname ||
          !check.amount ||
          !check.date
        ) {
          throw new Error(`Missing required fields in row ${i + 1}`);
        }

        // Find or insert payee
        let payee = await findOrInsertPayee(check);
        if (!payee || !payee.id) {
          throw new Error(`Failed to process payee for row ${i + 1}`);
        }

        // Find account
        const account = accounts.find(
          (acc) => acc.accountNickname === check.bankAccountNickname
        );
        if (!account) {
          throw new Error(
            `Account with nickname "${
              check.bankAccountNickname
            }" not found in row ${i + 1}`
          );
        }

        // Prepare new check object
        const newCheck = {
          account: { id: account.id, accountNickname: account.accountNickname },
          payableTo: payee.payableTo,
          payee: {
            id: payee.id,
            name: payee.name,
            companyName: payee.companyName,
            email: payee.email,
            phoneNumber: payee.phoneNumber,
            address: {
              address1: payee.address.address1,
              address2: payee.address.address2,
              city: payee.address.city,
              state: payee.address.state,
              zip: payee.address.zip,
              country: payee.address.country,
            },
          },
          amount: check.amount,
          mailingInfo: check.mailingInfo,
          memo: check.memo,
          comments: check.comments,
          date:
            typeof check.date === "string"
              ? Timestamp.fromDate(new Date(check.date))
              : check.date,
          dateAdded: new Date(),
          isDeleted: false,
          imported: true,
          checkNumber: check.checkNumber || "To Be Printed",
          toBePrinted:
            check.status.toLowerCase() === "to be printed" ? true : false,
          status: check.status || "draft",
          editedBy: { id: user.uid, name: user.displayName },
          createdBy: { id: user.uid, name: user.displayName },
        };

        // Call the centralized upsertCheck function
        const result = await upsertCheck(user, { ...newCheck, imported: true });

        if (result.success) {
          // Update table data with success status
          updatedTableData[i].status = <Tag color="green">Imported</Tag>;
          updatedTableData[i].payeeFound = payee.payeeFound ? (
            <Tag color="blue">Existing</Tag>
          ) : (
            <Tag color="green">Imported</Tag>
          );
        }
      } catch (error) {
        // Catch and log any errors, and update the UI accordingly
        updatedTableData[i].status = <Tag color="red">Failed</Tag>;
        updatedTableData[i].failReason = error.message; // Properly set the error message
        console.error(`Error in row ${i + 1}: ${error.message}`);
      }

      setProgress(Math.round(((i + 1) / csvData.length) * 100));
    }

    // Final table update and loading state
    setTableData(updatedTableData);
    setLoading(false);
  };

  const findOrInsertPayee = async (check) => {
    let payeeFound = false;
    try {
      // Query to find the payee by payableTo name
      const payeesQuery = query(
        collection(db, `clients/${user.clientId}/payees`),
        where("payableTo", "==", check.payableTo)
      );
      const payeesSnapshot = await getDocs(payeesQuery);

      // If payee exists, return the payee data with ID
      if (!payeesSnapshot.empty) {
        payeeFound = true;
        const payeeDoc = payeesSnapshot.docs[0];
        return { ...payeeDoc.data(), id: payeeDoc.id, payeeFound };
      } else {
        // If payee doesn't exist, create a new one
        const newPayee = {
          payableTo: check.payableTo,
          name: check.payeeName,
          email: check.payeeEmail,
          phoneNumber: check.payeePhone,
          address: {
            address1: check.payeeAddress1,
            address2: check.payeeAddress2,
            city: check.payeeCity,
            state: check.payeeState,
            zip: check.payeeZip,
            country: check.payeeCountry,
          },
          status: check.payeeStatus,
          companyName: check.payeeCompanyName,
          dateAdded: new Date(),
          isDeleted: false,
          lastEditedBy: { uid: user.uid, displayName: user.displayName },
        };

        const payeeDocRef = await addDoc(
          collection(db, `clients/${user.clientId}/payees`),
          newPayee
        );
        return { ...newPayee, id: payeeDocRef.id, payeeFound }; // Return new payee with ID
      }
    } catch (error) {
      throw new Error(
        `Error processing payee for "${check.payableTo}": ${error.message}`
      );
    }
  };

  const downloadTemplate = () => {
    const csvTemplate = [
      [
        "bankAccountNickname",
        "payableTo",
        "payeeCompanyName",
        "payeeName",
        "payeeEmail",
        "payeePhone",
        "payeeAddress1",
        "payeeAddress2",
        "payeeCity",
        "payeeState",
        "payeeZip",
        "payeeCountry",
        "payeeStatus",
        "amount",
        "date",
        "memo",
        "mailingInfo",
        "comments",
        "status",
        "checkNumber",
      ],
      [
        "Bank 1",
        "John Doe",
        "Doe Corporation",
        "John Doe",
        "john@doe.com",
        "5556667777",
        "5000 Sunset Blvd",
        "suite 100",
        "Summer Breeze",
        "CA",
        "99911",
        "USA",
        "Active",
        "150.00",
        "2024-10-09",
        "Payment for services",
        `"John Doe\nDoe Corporation\n5000 Sunset Blvd Suite 100\nSummer Breeze, CA, 99911"`,
        "This is a test comment",
        "printed",
        "1044",
      ],
    ];

    const csvContent = csvTemplate.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "expanded_checks_template.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Columns for displaying the uploaded checks
  const columns = [
    { title: "Check Number", dataIndex: "checkNumber", key: "checkNumber" },
    {
      title: "Payable To",
      dataIndex: "payableTo",
      key: "payableTo",
    },
    { title: "Payee Found", dataIndex: "payeeFound", key: "payeeFound" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Memo", dataIndex: "memo", key: "memo" },
    {
      title: "Bank Acc Nickname",
      dataIndex: "bankAccountNickname",
      key: "bankAccountNickname",
    },
    { title: "To Be Printed", dataIndex: "toBePrinted", key: "toBePrinted" },
    { title: "Import Status", dataIndex: "status", key: "status" },
    { title: "Import Error", dataIndex: "failReason", key: "failReason" },
  ];

  return (
    <>
      <h1 className="monospace">
        <FileExcelOutlined /> File Payment Import
      </h1>
      <Card>
        <Row>
          {!user.isPremium && !user.isEnterprise ? (
            <Button
              icon={<UploadOutlined />}
              onClick={() => {
                message.warning(
                  "This feature is only available for premium users. Please go to settings and upgrade your subscription"
                );
                return;
              }}
              className="button-blue"
            >
              Upload Checks Template
            </Button>
          ) : (
            <Upload
              accept=".csv"
              beforeUpload={(file) => {
                handleUpload(file);
                return false;
              }}
              fileList={[]}
            >
              <Button icon={<UploadOutlined />} className="button-blue">
                Upload Checks Template
              </Button>
            </Upload>
          )}

          <Button
            style={{ marginLeft: 15 }}
            icon={<DownloadOutlined />}
            onClick={downloadTemplate}
            className="button-gray"
          >
            Download Template
          </Button>
          <Button
            style={{ marginLeft: 15 }}
            icon={<QuestionCircleOutlined />}
            className="button-yellow"
            onClick={() =>
              window.open(
                "https://simple-checks.com/docs/import-checks-from-csv/",
                "_blank"
              )
            }
          >
            Instructions
          </Button>
        </Row>
        {csvData.length > 0 && (
          <>
            <Divider />
            <Progress percent={progress} style={{ marginTop: 16 }} />
            <Button
              className="button-blue"
              onClick={processCsv}
              loading={loading}
              style={{ marginTop: 16 }}
            >
              Start Import
            </Button>
          </>
        )}
        <Divider />

        <>
          <h3 style={{ fontFamily: "monospace", marginTop: 16 }}>
            List of Checks & Import Status
          </h3>
          <Table
            dataSource={tableData}
            columns={columns}
            style={{ marginTop: 16 }}
          />
        </>
      </Card>
    </>
  );
};

export default ImportChecksModal;
