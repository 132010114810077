import React from 'react';
import PropTypes from 'prop-types';
import PackageCard from './PackageCard';
import { Col, Row } from 'antd';
// import { StyledPackageOneRow } from './index.styled';

const PackageOne = ({ plans, onSubscribeClick, user, cta, trialMessage, isCanceled }) => {
  return (
    // <StyledPackageOneRow style={{width:"100%"}}>
    <Row >
      {plans.map((plan, index) => {
        const isSubscribed = user.clientData?.subscription?.stripeProductId === plan.stripeProductId;
        return (
          <Col xs={24} md={12} lg={8} key={index} style={{marginLeft: 75}}>
            <PackageCard
              plan={plan}
              isSubscribed={isSubscribed}
              onSubscribeClick={onSubscribeClick}
              cta={cta}
              trialMessage={trialMessage}
              isCanceled={isCanceled}
            />
          </Col>
        );
      })}
    </Row>
    // </StyledPackageOneRow>
  );
};
export default PackageOne;

PackageOne.propTypes = {
  plans: PropTypes.array.isRequired,
  onSubscribeClick: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
