import React, { useState, useEffect } from 'react';
import { Switch, Typography, Card, Button } from 'antd';
import { useAuth } from '../../../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore'; // Assuming you're using Firebase
import { db } from '../../../firebase'; // Adjust the import path as needed
import { SettingOutlined } from '@ant-design/icons';
const { Text } = Typography;

// Settings configuration object
const SYSTEM_PREFERENCES = [
  {
    key: 'disableAutoFill',
    title: 'Disable Auto-Fill for Recurring Checks',
    description: 'When enabled, the system will not automatically fill in details for recurring checks',
    type: 'toggle'
  },
  // Add more settings here in the future, for example:
  // {
  //   key: 'enableNotifications',
  //   title: 'Enable Notifications',
  //   description: 'Receive notifications for important updates',
  //   type: 'toggle'
  // }
];

const SystemPreferencesTab = () => {
  const { user } = useAuth();
  const [preferences, setPreferences] = useState(user.clientData.preferences || {});
  const [pendingChanges, setPendingChanges] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    window.history.pushState({}, '', '/settings/system-preferences');
  }, []);

  const handleToggleChange = (checked, setting) => {
    setPendingChanges(prev => ({
      ...prev,
      [setting]: checked
    }));
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const updatedPreferences = {
        ...preferences,
        ...pendingChanges
      };

      // Update Firestore
      const clientRef = doc(db, 'clients', user.clientId);
      await updateDoc(clientRef, {
        'preferences': updatedPreferences
      });

      // Hard refresh the page
      window.location.reload();
    } catch (error) {
      console.error('Error updating preferences:', error);
      setIsSaving(false);
    }
  };

  const renderPreference = (preference) => {
    switch (preference.type) {
      case 'toggle':
        return (
          <div className="preference-item" key={preference.key}>
            <div className="preference-content">
              <Text strong>{preference.title}</Text>
              <Text type="secondary" className="preference-description">
                {preference.description}
              </Text>
            </div>
            <Switch
              checked={
                pendingChanges.hasOwnProperty(preference.key)
                  ? pendingChanges[preference.key]
                  : preferences[preference.key] === true
              }
              onChange={(checked) => handleToggleChange(checked, preference.key)}
            />
          </div>
        );
      // Add more cases here for different types of settings
      // case 'select':
      // case 'input':
      default:
        return null;
    }
  };

  return (
    <div className="preferences-container">
      <Card 
        title={<><SettingOutlined /> Settings</>}
        className="preferences-card"
        extra={
          <Button
            className="button-yellow"
            onClick={handleSave}
            disabled={Object.keys(pendingChanges).length === 0 || isSaving}
            loading={isSaving}
          >
            Save Changes
          </Button>
        }
      >
        {SYSTEM_PREFERENCES.map(preference => renderPreference(preference))}
      </Card>

      <style jsx>{`        .preferences-container {
          max-width: 100%;
          margin: 0 auto;
          padding: 24px;
        }
        .preferences-card {
          border-radius: 8px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
        }
        .preference-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 0;
        }
        .preference-content {
          flex: 1;
          margin-right: 24px;
        }
        .preference-description {
          display: block;
          margin-top: 4px;
        }
      `}</style>
    </div>
  );
};

export default SystemPreferencesTab;
