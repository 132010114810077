import { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";
import { Button } from "antd";
import { useAuth } from "../../../contexts/AuthContext";

export const addPayee = async (clientId) => {
  try {
    const payeeData = {
      additionalInfo: "Test Payee",
      address: {
        address1: "5000 Sunset Blvd",
        address2: "Suite 100",
        city: "Summer Breeze",
        state: "CA",
        zip: "99911",
      },
      //   clientId: user.clientId,
      companyName: "Doe Corporation",
      name: "John Doe",
      email: "john@example.com",
      phoneNumber: "123-456-7890",
      dateAdded: new Date(),
      dateModified: new Date(),
      isDeleted: false,
      status: "Active",
      payableTo: "John Doe",
    };

    // Add a new document in the 'payee' collection
    const docRef = await addDoc(collection(db, `clients/${clientId}/payees`), payeeData);
    return docRef.id
    // console.log("Document written with ID: ", docRef.id);
    // alert("Payee added successfully");
  } catch (error) {
    console.error("Error adding document: ", error);
    // alert("Failed to add payee");
  }
};

export function AddPayeeButton() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  async function handleClick() {
    setLoading(true);
    await addPayee(user);
    setLoading(false);
  }

  return (
    <Button disabled={loading} className="button-blue" onClick={handleClick}>
      Add Payee
    </Button>
  );
}
