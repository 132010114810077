import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import Pricing from '../../Pricing';
import { Alert, App } from 'antd';

const PaymentTab = () => {
  const { user } = useAuth();
  const [showAlert, setShowAlert] = useState(false);
  const [hasShownAlert, setHasShownAlert] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('subscriptionCanceled') && !hasShownAlert) {
      setShowAlert(true);
      setHasShownAlert(true);
    }
  }, [hasShownAlert]);

  return (
    <App>
      {showAlert && (
        <Alert
          description="Subscription canceled successfully. You will have access to the system until the end of your billing cycle."
          type="info"
          showIcon
          closable
          onClose={() => setShowAlert(false)}
          style={{ marginBottom: 16 }}
        />
      )}
      <Pricing />
    </App>
  );
};

export default PaymentTab;
