import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Card, Button } from "antd";
import "../../../shared/styles/stripeCheckout.css";
import leadUpsertService from "../../../services/leadsServices";

const StripeCheckoutForm = ({ plan, user, onSuccess, forwardTo, cta, trialMessage }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const url = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const checkDefaultPaymentMethod = async () => {
      if (user.clientData.stripeCustomerId && url) {
        try {
          const response = await fetch(
            `${url}/v1/payments/stripe/stripeCustomerInfo?stripeCustomerId=${user.clientData.stripeCustomerId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${user.accessToken}`,
              },
            }
          );

          const data = await response.json();

          if (response.ok) {
            setHasDefaultPaymentMethod(data.hasDefaultPaymentMethod);
          } else {
            setErrorMessage(data.error);
          }
        } catch (error) {
          setErrorMessage("Failed to check payment method.");
        }
      }
    };

    checkDefaultPaymentMethod();
  }, [user, url]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("checkout form");
    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      let paymentMethodId = null;

      if (!hasDefaultPaymentMethod) {
        const paymentMethodResult = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            name: user.name,
            email: user.email,
          },
        });

        if (paymentMethodResult.error) {
          setErrorMessage(paymentMethodResult.error.message);
          setIsProcessing(false);
          return;
        }

        paymentMethodId = paymentMethodResult.paymentMethod.id;
      }

      const response = await fetch(`${url}/v1/payments/stripe/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          stripeProductId: plan.stripeProductId,
          paymentMethodId: paymentMethodId || null,
          stripeCustomerId: user.clientData.stripeCustomerId || null,
          stripeSubscriptionId:
            user.clientData.subscription.stripeSubscriptionId ?? null,
        }),
      });

      const data = await response.json();

      //update lead status
      leadUpsertService(user, {
        email: user.email,
        addSegment: ["clients", "subscribers"],
        removeSegment: ["leads"],
      })

      //add to client collection that this client was converted from a

      if (response.ok) {
        if (forwardTo) {
          window.location.href= forwardTo;
        } else {
          window.location.href= '/settings/subscription';
        }
      } else {
        setErrorMessage(data.error);
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again.");
    }

    setIsProcessing(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
        '::placeholder': {
          color: '#aab7c4',
        },
        backgroundColor: '#fff',
        padding: '12px',
      },
      invalid: {
        color: '#dc3545',
        iconColor: '#dc3545',
      },
    },
    hidePostalCode: true,
  };

  return (
    // <Card 
    //   className="payment-form-card"
    //   style={{
    //     maxWidth: '550px',
    //     margin: '0 auto',
    //     boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    //     borderRadius: '8px',
    //   }}
    // >
      <form onSubmit={handleSubmit}>
        <h2 style={{ 
          marginBottom: '24px', 
          fontSize: '24px',
          fontWeight: '600',
          color: '#32325d',
          textAlign: 'center' 
        }}>
          Payment Details
        </h2>

        {!hasDefaultPaymentMethod && (
          <div style={{ marginBottom: '24px' }}>
            <label
              style={{
                display: 'block',
                marginBottom: '8px',
                color: '#32325d',
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              Card Information
            </label>
            <div
              style={{
                padding: '12px',
                border: '1px solid #e6e6e6',
                borderRadius: '6px',
                backgroundColor: '#f8f9fa',
              }}
            >
              <CardElement options={cardElementOptions} />
            </div>
          </div>
        )}

        {errorMessage && (
          <div
            style={{
              padding: '12px',
              marginBottom: '16px',
              color: '#dc3545',
              backgroundColor: '#f8d7da',
              border: '1px solid #f5c6cb',
              borderRadius: '4px',
              fontSize: '14px',
            }}
          >
            {errorMessage}
          </div>
        )}

        <Button
          block
          disabled={isProcessing || !stripe || !elements}
          className="button-blue"
          onClick={handleSubmit}
          style={{
            height: '48px',
            fontSize: '16px',
            fontWeight: '600',
            borderRadius: '6px',
            background: isProcessing ? '#a0aec0' : '#5469d4',
            border: 'none',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.2s ease',
          }}
        >
          {isProcessing ? (
            <span>
              <span style={{ marginRight: '8px' }}>Processing</span>
              {/* You can add a loading spinner icon here */}
            </span>
          ) : (
            'Confirm Payment'
          )}
        </Button>
      </form>
    // </Card>
  );
};

export default StripeCheckoutForm;
