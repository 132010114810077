import React, { useState, useEffect } from 'react'
import { notification, Button, Alert, Form, Select, Switch, Input, Divider, Drawer } from 'antd'
import apiCall from "../../../utils/functions/apiCall"
import { BankOutlined, FolderOpenOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';

const validateImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      if (img.width < 128 || img.height < 128) {
        reject('Image dimensions must be at least 128x128 pixels');
      }
      if (img.width !== img.height) {
        reject('Image must be square');
      }
      resolve();
    };
    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      reject('Error loading image');
    };
  });
};

function DigitalTransactions({ user }) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [configDrawer, setConfigDrawer] = useState(false)
  const [configData, setConfigData] = useState(null)

  useEffect(() => {
    if (user?.clientData?.stripeConnect?.id) {
      fetchStripeSettings();
    }
  }, [user?.clientData?.stripeConnect?.id]);

  const fetchStripeSettings = async () => {
    if (!user?.clientData?.stripeConnect?.id) return;
    
    try {
      const response = await apiCall({
        url: `/v1/payments/stripe/settings?clientId=${user.clientId}`,
        method: 'GET',
      });
      
      form.setFieldsValue({
        payoutFrequency: response.data.payoutFrequency || 'daily',
        businessName: response.data.businessName || user?.clientData?.name,
        automaticTax: response.data.automaticTax || false,
        statementDescriptor: response.data.statementDescriptor || '',
        brandColor: response.data.brandColor || '#000000',
      });
    } catch (error) {
      notification.error({ message: "Error fetching stripe settings" });
      console.log(error);
    }
  };

  const generateStripeConnectLink = async () => {
    setLoading(true)
    try {
      const response = await apiCall({
        url: `/v1/payments/stripe/connect-generate-link?clientId=${user.clientId}`,
        method: 'GET',
      })



      window.location.href = response.data.url


    } catch (error) {
      notification.error({ message: "Error generating stripe connect link" })
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const generateStripeConnectDashboardLink = async () => {
    setLoading(true)
    try {
      const response = await apiCall({
        url: `/v1/payments/stripe/connect-dashboard-link?clientId=${user.clientId}`,
        method: 'GET',
      })

      window.open(response.data.url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      notification.error({ message: "Error generating stripe connect dashboard link" })
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSettingsSubmit = async (values) => {
    setLoading(true)
    try {
      // Debug log to see what values we're receiving
      console.log('Form values:', values);

      // Check if we need to use FormData (if there's a file) or regular JSON
      const fileInput = document.querySelector('input[type="file"]');
      const hasFile = fileInput && fileInput.files && fileInput.files.length > 0;

      if (hasFile) {
        // Use FormData when we have a file
        const formData = new FormData();
        
        // Append all regular fields
        Object.keys(values).forEach(key => {
          if (key !== 'brandIcon' && values[key] !== undefined) {
            formData.append(key, values[key]);
          }
        });

        formData.append('brandIcon', fileInput.files[0]);
        formData.append('clientId', user.clientId);

        await apiCall({
          url: `/v1/payments/stripe/settings`,
          method: 'PUT',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data; boundary=' + Math.random().toString().substr(2),
          },
        });
      } else {
        // Use regular JSON when no file is present
        const data = {
          ...values,
          clientId: user.clientId
        };
        delete data.brandIcon; // Remove the brandIcon field if it exists

        // Ensure we're not sending undefined or null values
        Object.keys(data).forEach(key => {
          if (data[key] === undefined || data[key] === null) {
            delete data[key];
          }
        });

        // Debug log to see final data being sent
        console.log('Data being sent:', data);

        // Only make the API call if we have data to send
        if (Object.keys(data).length > 0) {
          await apiCall({
            url: `/v1/payments/stripe/settings`,
            method: 'PUT',
            body: data,
            headers: {
              'Content-Type': 'application/json',
            },

          });
        } else {
          throw new Error('No data to update');
        }
      }

      notification.success({ message: "Settings updated successfully" });
      await fetchStripeSettings();
    } catch (error) {
      notification.error({ message: error.message || "Error updating settings" });
      console.log('Error details:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStripeConfig = async () => {
    setLoading(true)
    try {
      const response = await apiCall({
        url: `/v1/payments/stripe/config?clientId=${user.clientId}`,
        method: 'GET',
      })
      setConfigData(response.data)
      setConfigDrawer(true)
    } catch (error) {
      notification.error({ message: "Error fetching stripe configuration" })
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderStripeConnectStatus = () => {
    const stripeConnect = user?.clientData?.stripeConnect

    if (!stripeConnect?.id) {
    
      return (
        <div>
          <Alert
            message="To enable digital payment options and receive payments via credit card, you need to open a Stripe Connect account."
            description={<span>Learn more about Stripe Connect Express <a href='https://stripe.com/connect/express' target='_blank' rel='noopener noreferrer'>here</a>.</span>}
            type="info"
            showIcon
          />
          <Button
            className="button-blue"
            className="button-green"
            onClick={generateStripeConnectLink}
            loading={loading}
            style={{ marginTop: 10 }}
          >
            Sign up for Stripe Connect
          </Button>
        </div>
      )
    }

    if (stripeConnect.status === 'pending') {
      return (
        <div>
          <Alert
            message="Click on the link below to continue your Stripe Connect Express application."
            description="If you have completed the application, please allow a few minutes and check back."
            type="warning"
            showIcon
          />
          <Button
            className="button-blue"
            onClick={generateStripeConnectLink}
            loading={loading}
          >
            Continue Stripe Connect Application
          </Button>
        </div>
      )
    }

    if (stripeConnect.status === 'approved') {
      return (
        <div>
          <p>Your Stripe Connect account is fully set up. Use the Stripe Dashboard to manage your payment settings, including payout schedule.</p>
          <Button
            className="button-green"
            loading={loading}
            onClick={generateStripeConnectDashboardLink}
          >

            <BankOutlined /> Open Stripe Dashboard
          </Button>
        </div>
      )
    }
  }

  const renderStripeSettings = () => {
    if (user?.clientData?.stripeConnect?.status !== 'approved') return null

    return (
      <div style={{ marginTop: 24, maxWidth: 600 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            // Debug log before submission
            console.log('Form onFinish values:', values);
            if (!values || Object.keys(values).length === 0) {
              notification.error({ message: "No form values to submit" });
              return;
            }
            handleSettingsSubmit(values);
          }}
        >
          {/* <Divider orientation="left">Stripe Payment Settings</Divider> */}

          {/* <Form.Item
            label="Payout Frequency"
            name="payoutFrequency"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="daily">Daily</Select.Option>
              <Select.Option value="weekly">Weekly</Select.Option>
              <Select.Option value="monthly">Monthly</Select.Option>
            </Select>
          </Form.Item> */}

          {/* <Form.Item
            label="Automatic Tax Calculation"
            name="automaticTax"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item> */}

          <Divider orientation="left">Business Information</Divider>

          <Form.Item
            label="Business Name"
            name="businessName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Statement Descriptor"
            name="statementDescriptor"
            help="This will appear on your customer's credit card statements (max 22 characters)"
            rules={[
              { max: 22, message: 'Maximum 22 characters allowed' },
              { pattern: /^[A-Za-z0-9\s-]*$/, message: 'Only letters, numbers, spaces, and hyphens are allowed' }
            ]}
          >
            <Input />
          </Form.Item>

          <Divider orientation="left">Branding</Divider>

          <Form.Item
            label="Brand Color"
            name="brandColor"
            help="This color will be used in your checkout page"
            rules={[{ required: true, message: 'Please select a brand color' }]}
          >
            <Input type="color" style={{ width: 100 }} />
          </Form.Item>

          {/* <Form.Item
            label="Brand Icon"
            name="brandIcon"
            help="Square image, minimum 128x128px (PNG or JPEG)"
            rules={[{ message: 'Please upload a brand icon' }]}
          >
            <Input
              type="file"
              accept="image/png,image/jpeg"
              onChange={async (e) => {
                const file = e.target.files[0];
                if (file) {
                  try {
                    if (file.size > 5 * 1024 * 1024) {
                      throw new Error("File size should be less than 5MB");
                    }
                    await validateImageDimensions(file);
                  } catch (error) {
                    notification.error({ message: error.toString() });
                    e.target.value = null;
                    form.setFields([
                      {
                        name: 'brandIcon',
                        errors: [error.toString()]
                      }
                    ]);
                  }
                }
              }}
            />
          </Form.Item> */}

          <div style={{ marginTop: 24 }}>
            <Button className="button-blue" htmlType="submit" loading={loading} style={{ marginTop: 25, marginRight: 10 }}>
              <SaveOutlined /> Save Settings
            </Button>
            {user.role === 'employee' && (
              <Button
                onClick={fetchStripeConfig}
                loading={loading}
                style={{ marginTop: 25 }}
                className="button-yellow"
              >
                <SettingOutlined /> Retrieve Config
              </Button>
            )}
          </div>

        </Form>

      </div>
    )
  }

  return (
    <div>
      <div>
        <h1>Digital Transactions</h1>
      </div>
      {renderStripeConnectStatus()}
      {renderStripeSettings()}
      
      <Drawer
        title="Stripe Configuration"
        placement="right"
        onClose={() => setConfigDrawer(false)}
        open={configDrawer}
        width={600}
      >
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          {configData ? JSON.stringify(configData, null, 2) : 'No data available'}
        </pre>
      </Drawer>
    </div>
  )
}


export default DigitalTransactions