import React from "react";
import { Footer } from "antd/es/layout/layout";

function FooterBottom() {
  return (
    <Footer
      style={{
        textAlign: "center",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <span style={{ marginRight: "20px" }}>Simple Checks</span>
      <a
        href="https://simple-checks.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: "20px" }}
      >
        Privacy Policy
      </a>
      <a
        href="https://simple-checks.com/terms-of-service"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>
    </Footer>
  );
}

export default FooterBottom;
