import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  Tag,
  Upload,
  message,
  Select,
  Checkbox,
  Row,
  Col,
  Space,
  Tooltip,
  Popconfirm,
  notification,
  Progress,
} from "antd";
import Papa from "papaparse";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc,
  where,
  orderBy,
  limit,
  startAfter,
  query,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { EditOutlined, DeleteOutlined, StopOutlined } from "@ant-design/icons";
import { formatDate } from "../../../utils/functions/dates";
import * as XLSX from "xlsx";
import apiCall from "../../../utils/functions/apiCall";

const { Option } = Select;

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [segments, setSegments] = useState([]); // Store segments
  const [editingLead, setEditingLead] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [filters, setFilters] = useState({ segment: null, subscribed: null });
  const [existingLeads, setExistingLeads] = useState([]); // Store all existing leads for comparison
  const [lastVisible, setLastVisible] = useState();
  const [emailToUnsubscribe, setEmailToUnsubscribe] = useState("");
  const [isMassDeleteModalVisible, setIsMassDeleteModalVisible] = useState(false);
  const [emailsToDelete, setEmailsToDelete] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  // Fetch leads from Firestore
  const fetchLeads = async (lastVisibleDoc = null) => {
    try {
      // Start with the basic query
      let leadQuery = query(
        collection(db, "marketingLeads"),
        where("isDeleted", "==", false),
        orderBy("dateAdded", "desc"),
        limit(filters.count && filters.count > 0 ? filters.count : 50) // Adjust as needed
      );

      // Apply filters for each selected segment in filters.segment (if it's an array)
      if (filters.segment && Array.isArray(filters.segment) && filters.segment.length > 0) {
        filters.segment.forEach((segmentValue) => {
          leadQuery = query(leadQuery, where("segment", "array-contains", segmentValue));
        });
      }

      if (filters.subscribed !== null) {
        leadQuery = query(leadQuery, where("subscribed", "==", filters.subscribed));
      }
      if (lastVisibleDoc) {
        leadQuery = query(leadQuery, startAfter(lastVisibleDoc));
      }

      // Execute the query
      const querySnapshot = await getDocs(leadQuery);
      const newLeads = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setLeads((prevLeads) => [...prevLeads, ...newLeads]);
      setFilteredLeads(newLeads); // Set data for current batch only

      // Track the last document for pagination
      if (querySnapshot.docs.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
      if (error.code === "failed-precondition") {
        console.warn("Index required for the query. Follow the link in the error message to create the index.");
      }
    }
  };

  // Fetch segments from /systemSettings/marketingHub
  const fetchSegments = async () => {
    const docRef = doc(db, "systemSettings", "marketingHub");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setSegments(docSnap.data().segments || []);
    } else {
      message.error("No segments found.");
    }
  };

  useEffect(() => {
    fetchLeads();
    fetchSegments();
  }, []);

  // Updated handleUpload function
  const handleUpload = (file) => {
    setIsUploading(true);
    setUploadProgress(0);

    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        try {
          // Filter out empty rows first
          const validRows = results.data.filter(row => 
            Object.values(row).some(value => value && value.trim() !== '')
          );
          
          const totalRecords = validRows.length;
          let successfullyImported = 0;
          let failedDetails = [];
          
          // Get all existing emails in one query
          const existingLeadsSnapshot = await getDocs(
            collection(db, "marketingLeads")
          );
          const existingEmails = new Set(
            existingLeadsSnapshot.docs.map((doc) => doc.data().email)
          );

          // Create batches (Firestore limit is 500 operations per batch)
          const batchSize = 450;
          const batches = [];
          let currentBatch = writeBatch(db);
          let operationCount = 0;

          for (let i = 0; i < validRows.length; i++) {
            const lead = validRows[i];
            
            // Update progress
            setUploadProgress(Math.round((i / totalRecords) * 100));

            // Skip if email exists
            if (existingEmails.has(lead.email)) {
              failedDetails.push({
                email: lead.email,
                reason: "Duplicate email",
              });
              continue;
            }

            // Clean and prepare the lead document
            const cleanedLead = {
              firstName: lead.firstName || '',
              lastName: lead.lastName || '',
              company: lead.company || '',
              email: lead.email,
              phone: lead.phone || '',
              address1: lead.address1 || '',
              address2: lead.address2 || '',
              city: lead.city || '',
              state: lead.state || '',
              zip: lead.zip || '',
              segment: lead.segment ? [lead.segment] : ['leads'],
              subscribed: lead.subscribed?.toLowerCase() === 'false' ? false : true,
              totalCampaignSent: 0,
              dateAdded: new Date(),
              isDeleted: false
            };

            // Add to batch
            const docRef = doc(collection(db, "marketingLeads"));
            currentBatch.set(docRef, cleanedLead);
            operationCount++;
            successfullyImported++;

            // If batch is full, add it to batches array and create new batch
            if (operationCount === batchSize) {
              batches.push(currentBatch);
              currentBatch = writeBatch(db);
              operationCount = 0;
            }
          }

          // Add the last batch if it has any operations
          if (operationCount > 0) {
            batches.push(currentBatch);
          }

          // Commit all batches sequentially
          for (let i = 0; i < batches.length; i++) {
            await batches[i].commit();
            setUploadProgress(
              Math.round(((i + 1) / batches.length) * 100)
            );
          }

          // Display the summary
          notification.open({
            message: "Import Summary",
            description: (
              <div>
                <p>{`${totalRecords} records found in the file.`}</p>
                <p>{`${successfullyImported} records imported successfully, ${failedDetails.length} records failed.`}</p>
                {failedDetails.length > 0 && (
                  <>
                    <p className="error">Failed Records:</p>
                    <ul>
                      {failedDetails.map((detail, index) => (
                        <li key={index} className="error">
                          {detail.email}: {detail.reason}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            ),
            duration: 0,
          });

          fetchLeads();
        } catch (error) {
          notification.error({
            message: "Import Error",
            description: error.message,
          });
        } finally {
          setIsUploading(false);
          setUploadProgress(0);
        }
      },
      error: (error) => {
        notification.error({
          message: "CSV Parsing Error",
          description: error.message,
        });
        setIsUploading(false);
        setUploadProgress(0);
      },
    });
    return false;
  };

  // Download template CSV for uploading leads
  const downloadTemplate = () => {
    const csvContent =
      "firstName,lastName,company,email,phone,address1,address2,city,state,zip,segment,subscribed,dateAdded,lastEmailSent,unsubscribedDate\n" +
      "John,Doe,company1,john.doe@example.com,1234567890,123 Main St,,Anytown,CA,90210,leads,true,2024-01-01,,\n" +
      "Jane,Smith,company2,jane.smith@example.com,0987654321,456 Elm St,,Othertown,NY,10001,leads,true,2024-01-01,,";
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "lead_template.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Add a new lead manually
  const addLead = () => {
    setEditingLead(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  // Edit a lead
  const editLead = (lead) => {
    setEditingLead(lead);
    form.setFieldsValue(lead);
    setIsModalVisible(true);
  };

  // Remove undefined fields and save lead (both add and edit cases)
  const saveLead = async (values) => {
    const sanitizedValues = Object.fromEntries(
      Object.entries(values).map(([k, v]) => [k, v ?? null])
    );

    // Check if lead with the same email already exists
    const existingLeadsSnapshot = await getDocs(
      collection(db, "marketingLeads")
    );
    const existingLead = existingLeadsSnapshot.docs.find(
      (doc) => doc.data().email === sanitizedValues.email
    );

    if (existingLead) {
      message.error(`Lead already exists with email ${sanitizedValues.email}`);
      return; // Stop execution if email already exists
    }

    // Proceed with saving if email does not exist
    if (editingLead) {
      await setDoc(doc(db, "marketingLeads", editingLead.id), {
        ...sanitizedValues,
        lastEmailSent: editingLead.lastEmailSent || null,
        lastEditedDate: new Date(),
      });
    } else {
      await setDoc(doc(collection(db, "marketingLeads")), {
        ...sanitizedValues,
        isDeleted: false,
        totalCampaignSent: 0,
        dateAdded: new Date(),
      });
    }

    setIsModalVisible(false);
    fetchLeads();
    message.success("Lead saved successfully.");
  };

  // Delete a lead (sets isDeleted=true)
  const deleteLead = async (leadId) => {
    await setDoc(
      doc(db, "marketingLeads", leadId),
      { isDeleted: true },
      { merge: true }
    );
    fetchLeads();
    message.success("Lead deleted.");
  };

  // Unsubscribe a lead (sets subscribed=false, unsubscribedDate = new Date)
  const unsubscribeLead = async (leadId) => {
    await setDoc(
      doc(db, "marketingLeads", leadId),
      { subscribed: false, unsubscribedDate: new Date() },
      { merge: true }
    );
    fetchLeads();
    message.success("Lead unsubscribed.");
  };

  const loadMoreLeads = () => {
    fetchLeads(lastVisible);
  };

  // Handle bulk segment update (multi-select)
  const bulkUpdateSegment = async (newSegments) => {
    const batch = selectedLeads.map(async (leadId) => {
      const leadRef = doc(db, "marketingLeads", leadId);
      await setDoc(leadRef, { segment: newSegments }, { merge: true });
    });
    await Promise.all(batch);
    setSelectedLeads([]);
    fetchLeads();
    message.success("Leads updated successfully.");
  };

  // Filter leads by segment or subscription status
  const applyFilters = () => {
    let filtered = leads;
    if (filters.segment) {
      filtered = filtered.filter((lead) =>
        lead.segment.includes(filters.segment)
      );
    }
    if (filters.subscribed !== null) {
      filtered = filtered.filter(
        (lead) => lead.subscribed === filters.subscribed
      );
    }
    fetchLeads()
  };

  // Table columns
  const columns = [
    {
      title: (
        <Checkbox
          onChange={(e) =>
            setSelectedLeads(
              e.target.checked ? leads.map((lead) => lead.id) : []
            )
          }
        />
      ),
      render: (_, lead) => (
        <Checkbox
          checked={selectedLeads.includes(lead.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedLeads([...selectedLeads, lead.id]);
            } else {
              setSelectedLeads(selectedLeads.filter((id) => id !== lead.id));
            }
          }}
        />
      ),
    },
    {
      title: "Actions",
      render: (_, lead) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              type="dashed"
              shape="circle"
              onClick={() => editLead(lead)}
            />
          </Tooltip>

          <Popconfirm
            title="Are you sure? You can't re-subscribe them again."
            onConfirm={() => unsubscribeLead(lead.id)}
            okText="Yes"
            cancelText="No"
            disabled={!lead.subscribed}
          >
            <Tooltip title="Unsubscribe">
              <Button
                icon={<StopOutlined />}
                type="default"
                shape="circle"
                disabled={!lead.subscribed}
              />
            </Tooltip>
          </Popconfirm>

          <Popconfirm
            title="Are you sure you want to delete this lead?"
            onConfirm={() => deleteLead(lead.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button
                icon={<DeleteOutlined />}
                danger
                type="default"
                shape="circle"
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Segment",
      dataIndex: "segment", // this should be an array
      key: "segment",
      render: (segments) => {
        // Check if `segments` is an array, and join if true
        if (Array.isArray(segments)) {
          return segments.join(", ");
        } else if (segments) {
          return segments;
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Subscribed",
      dataIndex: "subscribed",
      key: "subscribed",
      render: (subscribed) =>
        subscribed ? (
          <Tag color="green">Subscribed</Tag>
        ) : (
          <Tag color="red">Unsubscribed</Tag>
        ),
    },
    { title: "First Name", dataIndex: "firstName", key: "firstName" },
    { title: "Last Name", dataIndex: "lastName", key: "lastName" },
    { title: "Company", dataIndex: "company", key: "company" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Address 1", dataIndex: "address1", key: "address1" },
    { title: "Address 2", dataIndex: "address2", key: "address2" },
    { title: "City", dataIndex: "city", key: "city" },
    { title: "State", dataIndex: "state", key: "state" },
    { title: "Zip", dataIndex: "zip", key: "zip" },
    {
      title: "Date Added",
      dataIndex: "dateAdded",
      key: "dateAdded",
      sorter: (a, b) => new Date(a.dateAdded) - new Date(b.dateAdded),
      render: (date) => formatDate(date),
    },
    {
      title: "Last Email Sent",
      dataIndex: "lastEmailSent",
      key: "lastEmailSent",
      render: (date) => (date ? new Date(date).toLocaleDateString() : "N/A"),
    },
    {
      title: "Unsubscribed Date",
      dataIndex: "unsubscribedDate",
      key: "unsubscribedDate",
      sorter: (a, b) => new Date(a.dateAdded) - new Date(b.dateAdded),
      render: (date) => formatDate(date),
    },
  ];

  const unsubscribeByEmail = async () => {
    const leadsSnapshot = await getDocs(
      query(collection(db, "marketingLeads"), where("email", "==", emailToUnsubscribe))
    );

    if (!leadsSnapshot.empty) {
      const leadDoc = leadsSnapshot.docs[0]; // Get the first matching document
      await setDoc(
        leadDoc.ref,
        { subscribed: false, unsubscribedDate: new Date() },
        { merge: true }
      );
      message.success(`Lead with email ${emailToUnsubscribe} unsubscribed.`);
      setEmailToUnsubscribe(""); // Clear the input field
      fetchLeads(); // Refresh leads list
    } else {
      message.error(`No lead found with email ${emailToUnsubscribe}`);
    }
  };

  const exportAllLeads = async () => {
    try {
      const leadsCollection = collection(db, `marketingLeads`);
      const leadsSnapshot = await getDocs(leadsCollection);

      const dataToExport = leadsSnapshot.docs.map((doc) => {
        const lead = doc.data();
        const filteredLead = {
          firstName: lead.firstName || '',
          lastName: lead.lastName || '',
          company: lead.company || '',
          email: lead.email || '',
          phone: lead.phone || '',
          address1: lead.address1 || '',
          address2: lead.address2 || '',
          city: lead.city || '',
          state: lead.state || '',
          zip: lead.zip || '',
          dateAdded: lead.dateAdded?.seconds
            ? `${new Date(lead.dateAdded.seconds * 1000).getMonth() + 1}/${new Date(lead.dateAdded.seconds * 1000).getDate()}/${new Date(lead.dateAdded.seconds * 1000).getFullYear()}`
            : '',
          lastEmailSent: lead.lastEmailSent?.seconds
            ? `${new Date(lead.lastEmailSent.seconds * 1000).getMonth() + 1}/${new Date(lead.lastEmailSent.seconds * 1000).getDate()}/${new Date(lead.lastEmailSent.seconds * 1000).getFullYear()}`
            : '',
          segment: Array.isArray(lead.segment) ? lead.segment.join(', ') : '',
          subscribed: lead.subscribed || false,
          unsubscribedDate: lead.unsubscribedDate?.seconds
            ? `${new Date(lead.unsubscribedDate.seconds * 1000).getMonth() + 1}/${new Date(lead.unsubscribedDate.seconds * 1000).getDate()}/${new Date(lead.unsubscribedDate.seconds * 1000).getFullYear()}`
            : '',
          totalCampaignSent: lead.totalCampaignSent || 0,
          isDeleted: lead.isDeleted || false
        };
        return filteredLead;
      });

      // Create worksheet and convert to CSV
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const csvData = XLSX.utils.sheet_to_csv(worksheet);

      // Create and trigger download
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", "all_leads.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      notification.error({
        message: "Error exporting leads",
        description: error.message
      });
    }
  };

  const handleMassDelete = async () => {
    try {
      // Convert textarea input to array, trim spaces and filter empty strings
      const emailArray = emailsToDelete
        .split(',')
        .map(email => email.trim())
        .filter(email => email !== '');

      if (emailArray.length === 0) {
        notification.error({
          message: 'No valid emails provided'
        });
        return;
      }

      await apiCall({
        url: '/v1/leads/massDelete',
        method: 'DELETE',
        body: { emails: emailArray }
      });

      notification.success({
        message: 'Leads deleted successfully'
      });
      setIsMassDeleteModalVisible(false);
      setEmailsToDelete('');
      fetchLeads(); // Refresh the leads list
    } catch (error) {
      notification.error({
        message: 'Error deleting leads',
        description: error.message
      });
    }
  };

  return (
    <div>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        style={{ marginBottom: 16 }}
      >
        <Col>
          <Button onClick={addLead} className="button-blue">
            Add Lead
          </Button>
        </Col>
        <Col>
          <Row gutter={[8, 8]}>
            <Col>
              <Button onClick={downloadTemplate}>Download Template</Button>
            </Col>
            <Col>
              <Upload beforeUpload={handleUpload} fileList={[]}>
                <Button disabled={isUploading}>
                  {isUploading ? 'Uploading...' : 'Upload Leads'}
                </Button>
              </Upload>
              {isUploading && (
                <Progress 
                  percent={uploadProgress} 
                  size="small" 
                  style={{ marginTop: 8 }}
                />
              )}
            </Col>
            <Col>
              <Button
                onClick={() => setIsMassDeleteModalVisible(true)}
                className="desktop-only button-red"
              >
                Mass Delete Leads
              </Button>
            </Col>
            <Col>
              <Button onClick={exportAllLeads} className="desktop-only button-gray">
                Export All Leads
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {selectedLeads.length > 0 && (
        <Col>
          <Select
            mode="multiple"
            placeholder="Bulk Update Segment"
            onChange={(value) => bulkUpdateSegment(value)}
            style={{ width: 200 }}
          >
            {segments.map((segment) => (
              <Option key={segment} value={segment}>
                {segment}
              </Option>
            ))}
          </Select>
        </Col>
      )}
      <Row gutter={[16, 16]} justify="start" style={{ marginBottom: 16 }}>
        <Col>
          <Select
            mode="multiple"
            placeholder="Filter by Segment"
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, segment: value }))
            }
            allowClear
            style={{ width: 200 }}
          >
            {segments.map((segment) => (
              <Option key={segment} value={segment}>
                {segment}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            placeholder="Filter by Subscription"
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, subscribed: value }))
            }
            allowClear
            style={{ width: 200 }}
          >
            <Option value={true}>Subscribed</Option>
            <Option value={false}>Unsubscribed</Option>
          </Select>
        </Col>
        <Col>
          <Input
            placeholder="# of Records"
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, count: e.target.value }))
            }
            defaultValue={50}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Button onClick={applyFilters}>Apply</Button>
        </Col>
        <Col>
          <Input
            placeholder="Email to Unsubscribe"
            value={emailToUnsubscribe}
            onChange={(e) => setEmailToUnsubscribe(e.target.value.trim())}
            style={{ width: 200 }}
          />
          <Button onClick={unsubscribeByEmail} className="button-red" type="danger" style={{ marginLeft: 8 }}>
            Unsubscribe
          </Button>
        </Col>
      </Row>


      <Table columns={columns} dataSource={filteredLeads} rowKey="id" />
      <Button onClick={loadMoreLeads}>Load More Leads</Button>

      <Modal
        title={editingLead ? "Edit Lead" : "Add Lead"}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => form.submit()}
        style={{ top: 20 }}
      >
        <Form
          form={form}
          onFinish={saveLead}
          labelCol={{ span: 6 }} // Ensures alignment of labels
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Company" name="company">
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Phone" name="phone">
            <Input />
          </Form.Item>
          <Form.Item label="Address 1" name="address1">
            <Input />
          </Form.Item>
          <Form.Item label="Address 2" name="address2">
            <Input />
          </Form.Item>
          <Form.Item label="City" name="city">
            <Input />
          </Form.Item>
          <Form.Item label="State" name="state">
            <Input />
          </Form.Item>
          <Form.Item label="Zip" name="zip">
            <Input />
          </Form.Item>
          <Form.Item
            label="Segment"
            name="segment"
            rules={[{ required: true }]}
          >
            <Select mode="multiple">
              {segments.map((segment) => (
                <Option key={segment} value={segment}>
                  {segment}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Subscribed"
            name="subscribed"
            rules={[{ required: true }]}
          >
            <Select disabled={editingLead && !editingLead?.subscribed}>
              <Option value={true}>Subscribed</Option>
              <Option value={false}>Unsubscribed</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Mass Delete Leads"
        open={isMassDeleteModalVisible}
        onCancel={() => {
          setIsMassDeleteModalVisible(false);
          setEmailsToDelete('');
        }}
        onOk={handleMassDelete}
        okText="Delete Leads"
        okButtonProps={{ danger: true }}
      >
        <p>Enter email addresses separated by commas to delete multiple leads at once.</p>
        <Input.TextArea
          value={emailsToDelete}
          onChange={(e) => setEmailsToDelete(e.target.value)}
          placeholder="email1@example.com, email2@example.com, email3@example.com"
          rows={4}
        />
      </Modal>
    </div>
  );
};

export default Leads;
