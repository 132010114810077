import {
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Col,
  Row,
  Alert,
  notification,
  Typography,
  Card,
  Divider,
  Space,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../../../firebase";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
// import { defaultTemplate } from "../../../shared/checkTemplates/default";
import { addPayee } from "../../../shared/checkTemplates/demoData/demoPayee";
import { addCheck } from "../../../shared/checkTemplates/demoData/demoCheck";
import { updateProfile } from "firebase/auth";
import leadUpsertService from "../../../services/leadsServices";
import axios from "axios";
import apiCall from "../../../utils/functions/apiCall";

const OnboardingForm = ({ nextStep, user }) => {
  const { Option } = Select;
  const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT === "dev" ? true : false;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    displayName: user?.displayName || "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: user?.email || "",
  });

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    //warm up serverless functions
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/warmup`);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const functions = getFunctions();
  const setCustomClaims = async (
    uid,
    clientId,
    clientName,
    role,
    flSalesTax = false
  ) => {
    const setCustomClaimsFunction = httpsCallable(functions, "setCustomClaims");
    try {
      const result = await setCustomClaimsFunction({
        uid,
        clientId,
        clientName,
        role,
        flSalesTax,
      });
      if (DEBUG_MODE) console.log(result.data.message);
    } catch (error) {
      console.error("Error setting custom claims:", error);
    }
  };

  const generateSecret = async (clientId) => {
    const generateSecretFunction = httpsCallable(functions, "generateSecret");
    try {
      const result = await generateSecretFunction({ clientId });
      if (DEBUG_MODE) console.log(result.data.message);
    } catch (e) {
      console.error("Error generating secret:", e.message);
    }
  };

  const handleSubmit = async (values) => {
    if (!values.displayName.includes(" ")) {
      alert("Please enter your full name.");
      return;
    }
    setLoading(true);

    //track conversion without opening a new tab
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = 'https://simple-checks.com/welcome';
    document.body.appendChild(iframe);

    try {
      const isFlorida = values.state === "Florida"; // Check if the state is Florida

      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        ...values,
        isAdmin: true,
        role: "admin",
        isDeleted: false,
        clientId: "",
        dateAdded: serverTimestamp(),
        dateModified: serverTimestamp(),
      });

      const clientRef = await addDoc(collection(db, "clients"), {
        clientName: values.companyName || values.displayName,
        hasAccess: true,
        disabled: false,
        subscription: "trial",
        trialExpiresOn: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), //7 days trial
        //trialExpiresOn: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), //30 days trial
        verifications: {
          email: false,
          sms: false,
          bank: false,
          preferences: false,
        },
        users: [
          {
            userId: user.uid,
            name: values.displayName,
            email: values.email,
            role: "admin",
          },
        ],
        userIds: [user.uid],
        dateAdded: serverTimestamp(),
        dateModified: serverTimestamp(),
      });

      // await addDoc(collection(db, `clients/${clientRef.id}/templates`), {
      //   ...defaultTemplate,
      // });
      await updateDoc(userRef, { clientId: clientRef.id });

      await setCustomClaims(
        user.uid,
        clientRef.id,
        values.companyName || values.displayName,
        "admin",
        isFlorida
      );

      await updateProfile(user, {
        name: values.displayName,
      });

      //async stuff
      await generateSecret(clientRef.id);


      //add demo records:
      const newPayeeId = await addPayee(clientRef.id);
      const newCheckId = await addCheck(user, clientRef.id, newPayeeId);

      //update marketing lead status
      try {
        leadUpsertService(user, {
          email: values.email,
          firstName: values.displayName.split(" ")[0],
          lastName: values.displayName.split(" ")[1],
          company: values.companyName,
          phone: values.phone,
          address1: values.address,
          city: values.city,
          state: values.state,
          zip: values.zip,
          subscribed: true,
          addSegment: ["clients"],
          removeSegment: ["leads"],
        });
      } catch (error) {
        console.log(error.message);
      }

      //send welcome email
      try {
        const response = await apiCall({
          url: `/v1/users/${user.uid}/send-email`,
          method: "POST",
          body: { purpose: "welcome" },
        })
        console.log("Email sent successfully:", response.data);
      } catch (error) {
        console.error(
          "Error sending welcome email:",
          error.response ? error.response.data : error.message
        );
      }



      if (nextStep) {
        nextStep();
      } else {
        window.location.href = process.env.REACT_APP_STARTING_PAGE;
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating user data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="onboarding-container" style={{ maxWidth: '800px', margin: '0 auto' }}>



      <Form
        name="user_form"
        initialValues={formData}
        onFinish={handleSubmit}
        onValuesChange={(changedValues) =>
          handleChange({
            target: {
              name: Object.keys(changedValues)[0],
              value: Object.values(changedValues)[0],
            },
          })
        }
        layout="vertical"
        requiredMark={false}
        size="large"
      >
        <Divider orientation="left">
          <Typography.Text strong>Account Information</Typography.Text>
        </Divider>

        <Row gutter={[24, 16]}>
          <Col xs={24} md={12}>
            <Form.Item
              name="email"
              label="Email Address"
            >
              <Input placeholder="Email" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="displayName"
              label="Full Name"
              rules={[{ required: true, message: "Full name is required" }]}
            >
              <Input placeholder="Enter your full name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="companyName"
              label="Company Name"
            >
              <Input placeholder="Enter your company name (Optional)" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[
                { required: true, message: "Please enter your phone number" },
              ]}
            >
              <Input placeholder="(123) 456-7890" />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" style={{ marginTop: '16px' }}>
          <Typography.Text strong>Address Information</Typography.Text>
        </Divider>

        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <Form.Item
              name="address"
              label="Street Address"
              rules={[{ required: true, message: "Please enter your address" }]}
            >
              <Input placeholder="123 Main St" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: "Please enter your city" }]}
            >
              <Input placeholder="City" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: "Please select your state" }]}
            >
              <Select
                placeholder="Select a state"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {states.map((state) => (
                  <Option key={state} value={state}>
                    {state}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="zip"
              label="ZIP Code"
              rules={[{ required: true, message: "Please enter your ZIP Code" }]}
            >
              <Input placeholder="12345" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="acceptTermsConditions"
          valuePropName="checked"
          style={{ marginTop: '16px' }}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error(
                      "You must agree to terms and conditions to proceed"
                    )
                  ),
            },
          ]}
        >
          <Checkbox>
            I certify that I am authorized to print checks for this entity and
            I agree with{" "}
            <Link
              to="https://simple-checks.com/terms-of-service/"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of service
            </Link>{" "}
            and{" "}
            <Link
              to="https://simple-checks.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </Link>
            .
          </Checkbox>
        </Form.Item>

        <Form.Item style={{ marginTop: '24px' }}>
          <Button
            className="button-blue"
            htmlType="submit"
            block
            loading={loading}
            size="large"
            style={{
              height: '48px',
              borderRadius: '8px',
              fontWeight: 'bold',
              fontSize: '16px'
            }}
          >
            {loading ? 'Creating Your Account...' : 'Save and Continue'}
          </Button>

          {loading && (
            <div style={{ textAlign: 'center', marginTop: '24px' }}>
              <Space direction="vertical" align="center">
                <Spin size="large" />
                <Typography.Text type="secondary">
                  Setting up your account. This may take a few moments...
                </Typography.Text>
              </Space>
            </div>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default OnboardingForm;
