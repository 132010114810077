import React, { useEffect } from 'react'
import Pricing from '../../Pricing'
import { Row, Col, Typography, Button, Alert } from 'antd'
import { useAuth, setUser } from '../../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

function TrialSelection({ nextStep }) {
  const { user, setUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user?.clientData?.subscription !== 'trial') {
      handleComplete()
    }
  }, [user])

  function handleComplete() {
    navigate(process.env.REACT_APP_STARTING_PAGE || "/");
  }

  const forwardTo = process.env.REACT_APP_STARTING_PAGE

  return (
    <>
      <Row>

        <Col style={{ textAlign: "center", marginLeft: "-90px", marginRight: "auto" }}  >
          <Pricing forwardTo={forwardTo} cta={true} trialMessage={true} />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: "center", marginTop: "10px" }}>
          <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
            You can cancel anytime during the trial period without being charged. Once the trial ends, your subscription will be billed. If you cancel after being charged, you will retain access until the end of the current billing cycle, but no refunds will be issued.
          </Typography.Text>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} style={{ textAlign: "right" }}>
          <Button
            className="button-blue"
            onClick={handleComplete}
            disabled={user?.clientData?.subscription === 'trial' ? true : false}
          >
            Close
          </Button>

        </Col>
      </Row>
    </>
  )
}

export default TrialSelection