import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

import {
  BankOutlined,
  UserOutlined,
  IdcardOutlined,
  MailOutlined,
  CheckSquareOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { Modal, Steps, Card, Typography, Spin, Row, Col } from "antd";
import OnboardingForm from "./NewUserJourney/OnboardingForm"; // Import the form componen
import EmailSmsVerification from "./NewUserJourney/EmailSmsVerification";
import BankingVerification from "../Settings/BankAccountsTab/BankingVerification";
import Welcome from "./NewUserJourney/Welcome";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import TrialSelection from "./NewUserJourney/TrialSelection";
const { Title, Paragraph } = Typography;

const Onboarding = () => {
  const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT === "dev" ? true : false;
  const { user } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const { urlParameter } = useParams();
  const [component, setComponent] = useState(null);

  useEffect(() => {
    if (user) {
      setCurrentStep(0);

      //get them out if they are all set
      if (user.acceptTermsConditions && user.clientData?.verifications?.email && user.clientData?.verifications?.sms && user.clientData?.subscription !== 'trial') {
        console.log('navigating to starting page')
        navigate(process.env.REACT_APP_STARTING_PAGE);
      }

      //if they have accepted the terms and conditions, but they don't have email or sms, then we need to show the email and sms verification
      if (user.acceptTermsConditions && (!user.clientData?.verifications?.email || !user.clientData?.verifications?.sms)) {
        setCurrentStep(1);
      }

      //if they have email and sms, then we need to show the pricing page
      if (user.clientData?.verifications?.email && user.clientData?.verifications?.sms) {
        setCurrentStep(2);
      }


    }
  }, [user]);

  function nextStep() {
    if (DEBUG_MODE) console.log('moving to next step')
    setCurrentStep(prevStep => prevStep + 1);
  }

  function previousStep() {
    setCurrentStep(prevStep => prevStep - 1);
  }


  const steps = [
    {
      title: "Onboarding",
      content: <OnboardingForm nextStep={nextStep} user={user} />,
      icon: <UserOutlined style={{ marginTop: 5 }} />,
    },
    {
      title: "Verification",
      content: (
        <EmailSmsVerification nextStep={nextStep} previousStep={previousStep} />
      ),
      icon: <><MailOutlined style={{ marginTop: 5 }} /> <MobileOutlined style={{ marginTop: 5 }} /></>,
    },
    {
      title: "Trial",
      content: <TrialSelection nextStep={nextStep} previousStep={previousStep} />,
      icon: <BankOutlined style={{ marginTop: 5 }} />,
    },
    // {
    //   title: "Welcome",
    //   icon: <CheckSquareOutlined style={{ marginTop: 5 }} />,
    //   content: <Welcome />,

    // },
  ];

  const handleClose = () => {
    navigate(-1); // This will navigate back to the previous page
    setModalOpen(false)
  };

  return (
    <>
      <Modal
        open={true}
        width="800"
        maskClosable={false}
        footer={null}
        closable={false}
        onCancel={handleClose}
      >
        {/* <Title>Getting Started</Title> */}
        {/* <Paragraph>
          Let's gather some quick information to get you going:
        </Paragraph> */}

        <Steps current={currentStep} style={{ marginTop: 20 }}>
          {steps.map((item, index) => (
            <Steps.Step key={index} title={item.title} icon={item.icon} />
          ))}
        </Steps>
        {/* <div style={{ textAlign: "center" }}>
          <Title level={2}>{steps[currentStep].title}</Title>
        </div> */}
        <div style={{ marginTop: 50 }}>{steps[currentStep].content}</div>
        {component}

      </Modal>
    </>
  );
};

export default Onboarding;
