//react imports
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//routes import
import Help from "./routes/Help";
import Landing from "./routes/Landing";
import Settings from "./routes/Settings";
import Login from "./routes/Login";
import Logout from "./routes/Login/Logout";
import Register from "./routes/Login/Register";
import ForgotPassword from "./routes/Login/ForgotPassword";
import ResetPassword from "./routes/Login/ResetPassword";
import PaymentsPage from "./routes/Payments";
import Payees from "./routes/Payees";
import Clients from "./routes/Clients";
import ClientDetails from "./routes/Clients/ClientDetails";
import EditPayee from "./routes/Payees/Edit";
import NotFound from "./routes/Errors/404";
import Onboarding from "./routes/Onboarding";
import InvitationOnboarding from "./routes/Onboarding/InvitationOnboarding";
import Pricing from "./routes/Pricing";
import VerifyCheck from "./routes/Payments/Components/VerifyCheck";
import ImportChecks from "./routes/ImportCsv";
import ECheckPrint from "./routes/Payments/eChecks/eCheckPrint";
import MarketingHub from "./routes/MarketingHub";
import Fulfillment from "./routes/Fulfillment";
import FulfillOrders from "./routes/FulfillOrders";
import OrderDetails from './routes/Fulfillment/OrderDetails'
import EmailModule from "./routes/EmailModule";

//import providers
import { AuthProvider } from "./contexts/AuthContext";

//import utils
import ProtectedRoute from "./utils/ProtectedRoute";
import ProtectedRouteNoLayout from "./utils/ProtectedRouteNoLayout";
import ProtectedRouteEmployeeOnly from "./utils/ProtectedRouteEmployeeOnly";
import AppThemeProvider from "./contexts/AppThemeProvider";
import AppContextProvider from "./contexts/AppContextProvider";
import "./shared/styles/index.css";

import CheckTemplate from "./routes/CheckTemplates";
import IntegrationImport from "./routes/ImportIntegrations";
import VerifyIndentification from "./routes/Settings/Identity/VerifyIndentificationPage";
import PricingSubscriptionPage from "./routes/Pricing/PricingSubscriptionPage";

const App = () => {
  return (
    <AppContextProvider>
      <AppThemeProvider>
        <Router>
          <AuthProvider>
            <Routes>
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/verify-check/:checkInfo"
                element={<VerifyCheck />}
              />
              <Route
                path="/e-check/print/:checkInfo"
                element={<ECheckPrint />}
              />
              <Route path="/" element={<ProtectedRoute element={Landing} />} />
              <Route
                path="/templates"
                element={<ProtectedRoute element={CheckTemplate} />}
              />
              <Route path="/help" element={<ProtectedRoute element={Help} />} />
              <Route
                path="/payees"
                element={<ProtectedRoute element={Payees} />}
              />
              <Route
                path="/fulfillment"
                element={<ProtectedRoute element={Fulfillment} />}
              />
              <Route
                path="/invitation-onboarding"
                element={
                  <ProtectedRouteNoLayout element={InvitationOnboarding} />
                }
              />
              <Route
                path="/onboarding/:urlParameter"
                element={<ProtectedRoute element={Onboarding} />}
              />
              <Route
                path="/onboarding"
                element={<ProtectedRoute element={Onboarding} />}
              />
              <Route
                path="/subscription"
                element={<ProtectedRouteNoLayout element={PricingSubscriptionPage} />}
              />
              <Route
                path="/verify-identification"
                element={<ProtectedRouteNoLayout element={VerifyIndentification} />}
              />
              <Route
                path="/payees/:payeeId"
                element={<ProtectedRoute element={Payees} />}
              />
              <Route
                path="/fulfillment/:fulfillmentId"
                element={<ProtectedRoute element={OrderDetails} />}
              />
              <Route
                path="/settings/:urlParameter"
                element={<ProtectedRoute element={Settings} />}
              />
              <Route
                path="/settings"
                element={<ProtectedRoute element={Settings} />}
              />
              <Route
                path="/payments"
                element={<ProtectedRoute element={PaymentsPage} />}
              />
              <Route
                path="/payments/:paymentId"
                element={<ProtectedRoute element={PaymentsPage} />}
              />
              <Route
                path="/file-import"
                element={<ProtectedRoute element={ImportChecks} />}
              />
              <Route
                path="/integration-import"
                element={<ProtectedRoute element={IntegrationImport} />}
              />
              {/* EMPLOYEE ONLY ROUTES */}
              <Route
                path="/clients/:clientId"
                element={<ProtectedRouteEmployeeOnly element={ClientDetails} />}
              />
              <Route
                path="/clients"
                element={<ProtectedRouteEmployeeOnly element={Clients} />}
              />
              <Route
                path="/compose-email"
                element={<ProtectedRouteEmployeeOnly element={EmailModule} />}
              />
              <Route
                path="/marketing-hub"
                element={<ProtectedRouteEmployeeOnly element={MarketingHub} />}
              />
              <Route
                path="/fulfill-orders"
                element={<ProtectedRouteEmployeeOnly element={FulfillOrders} />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthProvider>
        </Router>
      </AppThemeProvider>
    </AppContextProvider>
  );
};
export default App;
