import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db, functions } from "../../../firebase.js";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import {
  Spin,
  Layout,
  Card,
  Typography,
  Divider,
  Button,
  Input,
  notification,
  Checkbox,
  Row,
  Modal,
  Alert,
  Image,
  Radio,
  Tag,
} from "antd";
import {
  LockOutlined,
  CloudDownloadOutlined,
  CheckSquareOutlined,
  BulbOutlined,
  PrinterOutlined,
  ExclamationOutlined,
  ExclamationCircleFilled,
  PaperClipOutlined,
} from "@ant-design/icons";
import { httpsCallable } from "firebase/functions";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import { ref, getDownloadURL } from "firebase/storage";
import { renderCheck } from "./renderSingleCheck"; // Assuming this is your function
import { blankPaper } from "../../../shared/checkTemplates/blankPaperTemplate"; // Assuming this is your template
import { blankPaperEndorsementBack } from "../../../shared/checkTemplates/blankPaperTemplateEndorsementBack.js"; // Assuming this is your template
import axios from "axios";
import { ExclamationCircleOutlined, MailOutlined } from "@ant-design/icons";
import { generateQRCodeDataURL } from "../../../utils/functions/generateQRCode.js";
import { unmaskData } from "../../../utils/functions/encryption.js";
import { template } from "lodash";
import getFirebaseStorageUrl from "../../../utils/functions/getFirebaseStorageUrl";
import { storage } from "../../../firebase.js";

const { TextArea } = Input;

// The CheckPreviewModal component
const CheckPreviewModal = ({
  check,
  account,
  template,
  qrCodeUrl,
  visible,
  onClose,
}) => {
  return (
    <Modal
      title="Check Preview"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width="70%"
    >
      <PDFViewer style={{ width: "100%", height: "80vh" }}>
        {renderCheck(check, account, template, qrCodeUrl)}
      </PDFViewer>
    </Modal>
  );
};

export default function ECheckPrint() {
  const { checkInfo } = useParams();
  const [clientId, checkId] = checkInfo.split(":");

  const [checkData, setCheckData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false); // move to true to skip otp
  const [otpVerified, setOtpVerified] = useState(false); //move to true to skip otp
  const [miniLoading, setMiniLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null); // State to hold data for modal
  const [templateSelected, setTemplateSelected] = useState(false); // Default to `blankPaper`
  const [currentStep, setCurrentStep] = useState(1);

  const { Content } = Layout;

  useEffect(() => {
    const fetchCheckData = async () => {
      try {
        const checkDocRef = doc(db, `metaChecks/${checkId}`);
        const docSnap = await getDoc(checkDocRef);

        if (docSnap.exists()) {
          const checkData = docSnap.data();

          await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/v1/webhooks/e-checks/${checkData?.eCheckMeta?.sentId}/opened`
          );
          // console.log(checkData)
          if (checkData.isDeleted || checkData.status !== "emailed") {
            setCheckData(null);
            return;
          }

          const now = Timestamp.now();
          if (
            checkData.eCheckMeta &&
            checkData.eCheckMeta.linkExpirationDate &&
            checkData.eCheckMeta.linkExpirationDate.toMillis() < now.toMillis()
          ) {
            setCheckData(null);
            return;
          }

          setCheckData(checkData);
          // Set the email state with the sentTo value from eCheckMeta
          if (checkData.eCheckMeta?.sentTo) {
            setEmail(checkData.eCheckMeta.sentTo);
          }
        } else {
          setCheckData(null);
        }
      } catch (e) {
        console.error("Error fetching document:", e.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCheckData();
  }, [clientId, checkId]);

  const handleAgreement = (e) => {
    setAgreed(e.target.checked);
  };

  const handleNextStep = () => {
    if (!agreed) {
      notification.error({
        message: "You must agree to the terms before continuing",
      });
      return;
    }
    setCurrentStep(2);
  };

  // Function to handle print check and trigger the preview
  const handlePrintCheck = async () => {
    if (!agreed) {
      notification.error({
        message: "You must agree to the terms of printing this check",
      });
      return;
    }
    if (!templateSelected) {
      notification.error({
        message:
          "You must select which check template to use to print your check",
      });
      return;
    }

    setMiniLoading(true);
    try {
      const eCheckDataFunction = httpsCallable(functions, "eCheckData");
      const response = await eCheckDataFunction({
        clientId: checkData.clientId,
        checkId: checkData.id,
      });
      // Unmask (decode) the response data
      // console.log(response.data.data)

      const unmaskedData = await unmaskData(response.data.data);
      // console.log(unmaskData)
      // Parse the decoded response data
      const decryptedResponse = await JSON.parse(unmaskedData);

      // console.log('decrypted response', decryptedResponse)
      const { checkData: fetchedCheckData, decryptedAccount } =
        decryptedResponse;

      // Generate QR code
      const qrCodeValue = `${process.env.REACT_APP_BASE_URL}/verify-check/${checkData.clientId}:${checkData.id}`;
      const qrCodeDataURL = await generateQRCodeDataURL(qrCodeValue);

      // Call the preview modal function
      handleDownloadPDF(
        fetchedCheckData,
        decryptedAccount,
        templateSelected === "blankPaper"
          ? blankPaper
          : blankPaperEndorsementBack,
        qrCodeDataURL
      );

      // Mark check as downloaded
      await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/webhooks/e-checks/${fetchedCheckData?.eCheckMeta?.sentId}/downloaded`
      );
    } catch (error) {
      console.error("Error handling check print:", error.message);
      notification.error({
        message: "An error occurred while processing the check.",
        description: error.message,
      });
    } finally {
      setMiniLoading(false);
    }
  };

  const handlePrintPDF = async (check, account, template, qrCodeUrl) => {
    const blob = await pdf(
      renderCheck(check, account, template, qrCodeUrl)
    ).toBlob();
    const url = URL.createObjectURL(blob);
    const printWindow = window.open(url);

    printWindow.addEventListener("load", () => {
      printWindow.focus();
      printWindow.print();
    });
  };

  const handleDownloadPDF = async (check, account, template, qrCodeUrl) => {
    const blob = await pdf(
      renderCheck(check, account, template, qrCodeUrl)
    ).toBlob(); // Generate the PDF as a Blob
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "check.pdf"; // Set the filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the DOM
  };

  // Function to open the preview modal with dynamic data
  const handlePreviewPDF = (check, account, template, qrCodeUrl) => {
    setModalData({ check, account, template, qrCodeUrl }); // Set data for modal
    setIsModalVisible(true); // Show the modal
  };

  const handleSendOtp = async () => {
    setMiniLoading(true);
    try {
      // Fetch the check data again to get the email from Firestore
      const checkDocRef = doc(db, `metaChecks/${checkId}`);
      const docSnap = await getDoc(checkDocRef);

      if (docSnap.exists()) {
        const checkData = docSnap.data();

        // Check if the entered email matches the email in the metaChecks document
        // console.log(checkData);
        if (checkData.eCheckMeta && checkData.eCheckMeta.sentTo !== email) {
          notification.warning({
            message: "Email Mismatch",
            duration: 0,
            description:
              "The email entered does not match the email the check was sent to. Please use the same email you received the check",
          });
          return;
        }

        // If email matches, proceed with sending OTP
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/auth/send-otp`,
          { email }
        );
        if (response.status === 200) {
          notification.success({
            message: "Verification code sent successfully",
          });
          setOtpSent(true);
        }
      } else {
        notification.error({
          message: "Error",
          description: "No such document found.",
        });
        console.error("No such document found.");
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to send verification code",
      });
      console.error("Failed to send verification code: " + error.message);
    } finally {
      setMiniLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setMiniLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/auth/verify-otp`,
        { email, otp }
      );
      if (response.status === 200) {
        notification.success({
          message: "OTP verified successfully",
        });
        // console.log("OTP verified successfully");
        setOtpVerified(true);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to verify code",
      });
      console.error("Failed to verify OTP: " + error.message);
    } finally {
      setMiniLoading(false);
    }
  };

  const handleClosePreview = () => {
    setIsModalVisible(false);
    setModalData(null); // Clear modal data
  };

  const handleDownloadAttachment = async (attachment) => {
    try {
      setMiniLoading(true);
      const url = await getFirebaseStorageUrl(attachment.path);
      
      // Create temporary link and trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = attachment.originalName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      //TODO: increment download count

    } catch (error) {
      notification.error({
        message: 'Error downloading attachment',
        description: error.message
      });
    } finally {
      setMiniLoading(false);
    }
  };

  const renderCenterContent = () => {
    if (!otpVerified) {
      return otpSent ? (
        <>
          <Input
            placeholder="Enter One Time Password"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            style={{ marginBottom: "20px", width: "300px" }}
          />
          <Button
            onClick={handleVerifyOtp}
            className="button-yellow"
            disabled={miniLoading}
            style={{ marginLeft: 20 }}
          >
            {miniLoading ? <Spin size="small" /> : <CheckSquareOutlined />}{" "}
            Verify Code
          </Button>
        </>
      ) : (
        <>
          
          <Input
            placeholder="Enter the email you received the check"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: "20px", width: "300px" }}
          />

          <Button
            onClick={handleSendOtp}
            className="button-green"
            disabled={miniLoading}
            style={{ marginLeft: 20 }}
          >
            {miniLoading ? <Spin size="small" /> : <MailOutlined />} Send
            Verification Code
          </Button>
        </>
      );
    }

    // If OTP is verified, show the steps
    if (currentStep === 1) {
      return (
        <>
          <p>
            <strong>Check Number:</strong> {checkData?.checkNumber}
          </p>
          <p>
            <strong>Payable To:</strong> {checkData?.payableTo}
          </p>
          <p>
            <strong>Amount:</strong> {"$" + checkData?.amount}
          </p>

          <TextArea
            rows={10}
            value={`Terms and Conditions:
  - Print quality can affect check acceptance. The recommendation is color printer with high printing quality
  - You acknowledge that the sender of this check is known to you and valid.
  - You confirm that the purpose of the check is lawful and not for any illicit activities.
  - Simple Checks is not responsible for the misuse or unauthorized use of this check.
  - You are responsible for verifying the accuracy of all check details.
  - This check is valid only for the specified payee and amount.
  - Some banks have policies that does not accept emailed check. If that is your bank's case, please reach out to payer and request a check to be mailed via postal service
`}
            readOnly
            style={{ width: "100%", marginTop: "15px" }}
          />
          <Typography.Paragraph style={{ marginTop: "20px" }}>
            <Alert
              type="warning"
              showIcon={true}
              style={{ textAlign: "left" }}
              icon={<ExclamationCircleFilled />}
              message={
                <>
                  Be aware of the latest check fraud trends by checking our{" "}
                  <a 
                    href="https://simple-checks.com/blog/most-common-check-fraud-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    fraud info
                  </a> page.
                </>
              }
            />
          </Typography.Paragraph>
          <p>
            <Checkbox onChange={handleAgreement} style={{ marginTop: "15px" }}>
              I recognize the payee and this payment is valid. I accept the terms above. <span className="error">*</span>
            </Checkbox>
          </p>

          <Button
            className="button-green"
            onClick={handleNextStep}
            size="large"
            style={{ marginTop: "20px" }}
            block
          >
            Agree and Continue
          </Button>
        </>
      );
    }

    // Step 2 content (template selection and download)
    return (
      <>
        <Divider>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Select Check Template <span className="error">*</span>
          </Typography.Title>
        </Divider>
        
        <Typography.Paragraph className="info" style={{ fontSize: '16px', marginBottom: '20px' }}>
          Print on any white paper with your printer at home or office:
        </Typography.Paragraph>
        
        <Row gutter={32}>
          <div 
            onClick={() => setTemplateSelected("blankPaper")}
            style={{ 
              flex: 1, 
              padding: '20px', 
              border: '1px solid #f0f0f0',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
              backgroundColor: templateSelected === "blankPaper" ? '#f5f5f5' : 'white',
              transition: 'all 0.3s ease',
              cursor: 'pointer',
              margin: '0 8px'
            }}
          >
            <div style={{ marginBottom: '15px' }}>
              <Typography.Title level={5} style={{ margin: '0 0 5px 0' }}>
                For Mobile App Deposit Only
              </Typography.Title>
              <div style={{ color: '#666' }}>
                Great for under $5000 - single page
                <Tag className="bg-gray-light gray" style={{ marginLeft: '8px' }}>Easier</Tag>
              </div>
            </div>
            <Image
              width={160}
              className="shadow-square"
              src="/images/checkTemplates/blankSinglePageFinal.jpg"
              style={{ 
                marginTop: '15px',
                objectFit: 'cover',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}
            />
          </div>

          <div 
            onClick={() => setTemplateSelected("blankPaperTwoSided")}
            style={{ 
              flex: 1, 
              padding: '20px',
              border: '1px solid #f0f0f0',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
              backgroundColor: templateSelected === "blankPaperTwoSided" ? '#f5f5f5' : 'white',
              transition: 'all 0.3s ease',
              cursor: 'pointer',
              margin: '0 8px'
            }}
          >
            <div style={{ marginBottom: '15px' }}>
              <Typography.Title level={5} style={{ margin: '0 0 5px 0' }}>
                For ATM/Teller/Mobile Deposit
              </Typography.Title>
              <div style={{ color: '#666' }}>
                Includes endorsement on the back
              </div>
            </div>
            <Image
              width={200}
              className="shadow-square"
              src="/images/checkTemplates/blankTwoPageFinal.jpg"
              style={{ 
                marginTop: '30px',
                objectFit: 'cover',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}
            />
          </div>
        </Row>

        {templateSelected === "blankPaperTwoSided" && (
          <Alert
            showIcon={true}
            style={{ 
              textAlign: "left", 
              marginTop: '20px',
              borderRadius: '6px'
            }}
            icon={<BulbOutlined />}
            type="success"
            message={
              <>
                To print the endorsement on the back,{" "}
                <strong>use printer two sided printing feature.</strong> If
                unavailable, manually flip the page to print endorsement on the
                back of the check.
              </>
            }
          />
        )}

        <Alert
          type="warning"
          icon={<ExclamationCircleFilled />}
          showIcon={true}
          style={{ 
            textAlign: "left", 
            marginTop: '20px',
            borderRadius: '6px'
          }}
          message={
            "If having issues printing or depositing, please request payer to send a check via mail"
          }
        />

        <Button
          className="button-green"
          onClick={handlePrintCheck}
          disabled={miniLoading}
          style={{ 
            marginTop: "25px", 
            height: 70, 
            fontSize: 25,
            borderRadius: '6px'
          }}
          block
        >
          {miniLoading ? <Spin size="small" /> : <CloudDownloadOutlined />}
          {" "}Download Check
        </Button>

        {checkData?.attachments && checkData.attachments.length > 0 && (
          <>
            <Divider>
              <Typography.Title level={4} style={{ margin: 0 }}>
                Attachments
              </Typography.Title>
            </Divider>
            
            <div style={{ marginTop: '20px' }}>
              {checkData.attachments.map((attachment, index) => (
                <div 
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px',
                    borderRadius: '6px',
                    backgroundColor: '#f5f5f5',
                    marginBottom: '8px'
                  }}
                >
                  <PaperClipOutlined style={{ marginRight: '8px' }} />
                  <div style={{ flex: 1 }}>
                    <div>{attachment.originalName}</div>
                    <div style={{ fontSize: '12px', color: '#666' }}>
                      {attachment.fileExtension.toUpperCase()}
                    </div>
                  </div>
                  <Button 
                    onClick={() => handleDownloadAttachment(attachment)}
                    icon={<CloudDownloadOutlined />}
                  >
                    Download
                  </Button>
                </div>
              ))}
            </div>
          </>
        )}

        <Divider />
        <p>
          Watch this video showing how easy it is to print and deposit an
          e-check
        </p>
        <div className="responsive-video-container">
          <iframe
            className="shadow"
            src="https://player.vimeo.com/video/1033953632?h=318346fb1d&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            width="3840"
            height="2160"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            title="Priting and Depositing Checks"
            frameBorder="0"
          ></iframe>
          {/* <iframe src="https://player.vimeo.com/video/1033953632?h=318346fb1d&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="3840" height="2160" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Priting and Depositing Checks"></iframe> */}
        </div>

        <p>
          If you prefer, follow the instructions in this article{" "}
          <a
            href="https://simple-checks.com/docs/print-deposit-checks"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>here</strong>
          </a>
          .
        </p>
      </>
    );
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "30vh" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Layout style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <Content
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px 10px", // Responsive padding for mobile devices
          }}
        >
          <div
            style={{
              textAlign: "center",
              width: "100%", // Full width for responsiveness
              maxWidth: "700px", // Limits width for larger screens
              padding: "0 16px", // Ensures no side cutoff on mobile screens
              boxSizing: "border-box", // Includes padding in width calculation
            }}
          >
            {/* Logo Image */}
            <div
              className="bg-blue shadow"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // width: "100%",
                textAlign: "center",
                marginBottom: "40px",
                borderRadius: 5,
                paddingTop: "10",
                paddingBottom: "10",
              }}
            >
              <Image
                src="/images/logo/logo.svg"
                alt="Logo"
                width={300}
                preview={false}
              />
            </div>
            {/* Card Component */}
            <Card
              type="inner"
              title="Print e-Check"
              style={{
                width: "100%", // Full width within container for responsiveness
                // maxWidth: "500px", // Maximum width for larger screens
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                margin: "0 auto", // Centers card in its container
              }}
            >
              {checkData !== null ? (
                <>
                  <Typography.Title level={3}>
                    <span className="success">
                      <LockOutlined /> {"  "} Authentic Check
                    </span>
                  </Typography.Title>

                  {/* <p>
                    Verify your email in order to download and print your check.
                  </p> */}

                  <Divider />

                  {/* Render the central content dynamically */}
                  {renderCenterContent()}

                  <Divider />

                  <Typography.Title level={5}>
                    Print and email your own checks with{" "}
                    <a href="https://simple-checks.com">simple-checks.com</a>
                  </Typography.Title>
                </>
              ) : (
                <>
                  <h2 className="danger">
                    <span className="warning">
                      <ExclamationCircleOutlined /> CHECK NOT FOUND OR LINK HAS
                      EXPIRED
                    </span>
                  </h2>
                  <Typography.Paragraph>
                    If you believe this is a mistake, please contact the sender
                    or click{" "}
                    <a href="https://simple-checks.com/contact">here</a> to
                    contact support.
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    <Alert
                      type="warning"
                      showIcon={true}
                      style={{ textAlign: "left" }}
                      icon={<ExclamationCircleFilled />}
                      message={
                        <>
                          Be aware of the latest check fraud trends by checking our{" "}
                          <a 
                            href="https://simple-checks.com/blog/most-common-check-fraud-us"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            fraud info
                          </a> page.
                        </>
                      }
                    />
                  </Typography.Paragraph>
                </>
              )}
            </Card>
          </div>
        </Content>
      </Layout>

      <div>
        {isModalVisible && (
          <CheckPreviewModal
            check={modalData?.check}
            account={modalData?.account}
            template={modalData?.template}
            qrCodeUrl={modalData?.qrCodeUrl}
            visible={isModalVisible}
            onClose={handleClosePreview}
          />
        )}
      </div>
    </>
  );
}
