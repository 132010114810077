import React, { useEffect, useState } from "react";
import PackageOne from "./PackageOne";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Card, Modal, Row, Col, notification } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../../contexts/AuthContext";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import CancelSubscriptionButton from "./CancelSubscription";
import UpdatePaymentInformation from "./UpdatePayment";
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const Pricing = ({ forwardTo, cta, trialMessage }) => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [subId, setSubId] = useState(false);

  const { user, reloadUser } = useAuth();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const subscriptionDoc = await getDoc(doc(db, 'systemSettings', 'subscriptions'));
        
        if (subscriptionDoc.exists()) {
          const plansData = subscriptionDoc.data().plans;
          // Filter out disabled plans
          const activePlans = plansData.filter(plan => !plan.disabled);
          setPlans(activePlans);
        } else {
          console.error("No subscription document found");
          setPlans([]);
        }
      } catch (error) {
        console.error("Error fetching plans from Firestore:", error);
        setPlans([]);
      }
    };

    fetchPlans();
    if (user?.clientData?.subscription?.stripeSubscriptionId) {
      setSubId(user.clientData.subscription.stripeSubscriptionId);
    }

    
  }, []);

  useEffect(() => {
   if(user?.clientData?.subscription?.status === 'canceled'){
    setIsCanceled(true);
   }
  }, [user]);



  const handleSubscribeClick = (plan) => {
    setSelectedPlan(plan);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedPlan(null);
  };

  const appearance = {
    theme: "stripe", // Other options: 'flat', 'night', 'none'
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#333",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
    },
    rules: {
      ".Label": {
        color: "#6b7c93",
      },
    },
  };

  const options = {
    appearance,
  };

  const handleSuccess = () => {
    setIsCanceled(true);
    // Refresh or update the UI as needed
    reloadUser();
    // window.location.href = 'subscription?subscriptionCanceled=true';
  };

  return (
    <>
     {/* {subCanceled && (
        notification.success({
          message: "Subscription canceled successfully. You will have access to the system until the end of your billing cycle.",
        })
    )} */}
      <Elements stripe={stripePromise} options={options}>
        
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      {user?.clientData?.subscription?.stripeSubscriptionId ? (
          <Row style={{marginTop: 30}}>
            <Col span={20} style={{ align: "right", textAlign: "right" }}>
              <UpdatePaymentInformation user={user} />
            </Col>
            <Col span={4} style={{ align: "right", textAlign: "right" }}>
              <div>
                {!isCanceled && subId ? (
                  <CancelSubscriptionButton
                    subscriptionId={subId}
                    onSuccess={handleSuccess}
                    accessToken={user.accessToken}
                  />
                ) : (
                  <p style={{ 
                    color: '#52c41a',
                    fontSize: '14px',
                    margin: '8px 0'
                  }}>
                    Subscription has been canceled
                  </p>
                )}
              </div>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col span={24} style={{marginLeft: 75}}>
            <PackageOne
              plans={plans}
              onSubscribeClick={handleSubscribeClick}
              cta={cta}
              trialMessage={trialMessage}
              user={user}
              isCanceled={isCanceled}
            />
          </Col>
        </Row>
       
        </div>
        <Modal
          style={{ padding: "50px" }}
          title="Complete or Update Subscription"
          open={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          {selectedPlan && (
            <StripeCheckoutForm
              plan={selectedPlan}
              user={user}
              forwardTo={forwardTo}
    
              onSuccess={handleModalCancel}
            />
          )}
        </Modal>
      </Elements>
    </>
  );
};

export default Pricing;
