import React, { useEffect, useState } from "react";
import IntegrationTiles from "./Components/IntegrationTiles";
import { Alert, Divider, notification } from "antd";
import { CloudDownloadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";

const IntegrationTab = ({ setActiveKey }) => {
  const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT;
  const { user } = useAuth();
  const navigate = useNavigate();
  const [disconnectedMessage, setDisconnectedMessage] = useState({
    message: "",
    description: "",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const disconnected = queryParams.get("disconnected");
  const integration = queryParams.get("integration");

  useEffect(() => {
    if (disconnected && integration) {
      if (integration === "quickbooks") {
        setDisconnectedMessage({
          message: "QuickBooks Disconnected",
          description:
            "Your QuickBooks integration has been disconnected. You will no longer be able to import payee and bills from QuickBooks into simple-checks.com. To reconnect use the connect to QuickBooks button below.",
        });
      }
    }
  }, [disconnected, integration]); // Effect hook to run when query params change

  useEffect(() => {
    if (user && user?.role !== "admin" && user.role !== "employee") {
      notification.error({
        message: "You are not authorized to access this page.",
      });
      navigate("/settings");
    }
  }, [user, navigate]);

  // State to hold the integration list
  const [integrationList, setIntegrationList] = useState([
    {
      name: "quickbooks",
      title: "QuickBooks Online",
      description: (
        <>
          Sync data From QuickBooks Online to Simple Checks.{" "}
          <strong>
            You have control over how much data flows between the two systems.
          </strong>
          <br />
          <Divider style={{ margin: 10 }} />
          <CloudDownloadOutlined className="info" /> Connect to sync your
          QuickBooks Vendors as Payees. <br />
          <CloudDownloadOutlined className="info" /> Connect to sync your
          QuickBooks Bills as Payments.
        </>
      ),
      image: "/images/logo/partners/qb-logo.svg",
      connected: user?.clientData?.integrations?.includes("quickbooks"),
      enabled: true,
      disabledButtonMessage: "Coming Soon",
      connectUrl: `${apiBaseUrl}/v1/integrations/quickbooks/authUri`,
      disconnectUrl: `${apiBaseUrl}/v1/integrations/quickbooks/unauthorize`,
      buttonImage: "/images/buttons/quickbooks/C2QB_green_btn_med_default.png",
      buttonImageHover:
        "/images/buttons/quickbooks/C2QB_green_btn_med_hover.png",
    },
    {
      name: "xero",
      title: "Xero",
      description: (
        <>
          Sync data between simple-checks and Xero.{" "}
          <strong>
            You have control over how much data flows between the two systems.
          </strong>
        </>
      ),
      image: "/images/logo/partners/xero-logo.svg",
      connected: false,
      enabled: false,
      disabledButtonMessage: "Coming Soon",
      connectUrl: "/v1/integrations/xero/connect",
    },
    {
      name: "adp",
      title: "ADP",
      description: (
        <>
          Sync data between simple-checks and ADP.{" "}
          <strong>
            You have control over how much data flows between the two systems.
          </strong>
        </>
      ),
      image: "/images/logo/partners/adp-logo.svg",
      connected: false,
      enabled: false,
      disabledButtonMessage: "Coming Soon",
      connectUrl: "/v1/integrations/adp/connect",
    },
  ]);

  return (
    <>
      {/* {disconnectedMessage.message && (
        <Alert
          showIcon
          type="info"
          message={disconnectedMessage.message}
          description={disconnectedMessage.description}
        />
      )} */}

      <div style={{ padding: "20px" }}>
        <IntegrationTiles
          integrations={integrationList}
          setActiveKey={setActiveKey}
        />
      </div>
    </>
  );
};

export default IntegrationTab;
