import { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Modal,
  DatePicker,
  message,
  Input,
  Row,
  Col,
  Menu,
  Dropdown,
  Typography,
  Tooltip,
} from "antd"; // Import Input for search
import {
  doc,
  updateDoc,
  collection,
  deleteDoc,
  getDocs,
  query,
  orderBy,
  where,
} from "firebase/firestore";
import { db } from "../../firebase"; // Assuming you have initialized Firebase in this file
import { useAuth } from "../../contexts/AuthContext";
import {
  CheckCircleOutlined,
  MobileOutlined,
  MailOutlined,
  BankOutlined,
  DownOutlined,
  IdcardOutlined,
  MobileFilled,
  PhoneOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { formatDate } from "../../utils/functions/dates";
import { Link } from "react-router-dom";
import callApi from "../../utils/functions/apiCall";
import { getAuth, deleteUser } from "firebase/auth";


const { Search } = Input;

function Clients() {
  const { user } = useAuth();
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]); // To handle filtered data
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingClient, setEditingClient] = useState(null);
  const [newTrialDate, setNewTrialDate] = useState(null);

  // Fetch clients data from Firestore
  const fetchClients = async () => {
    setLoading(true);
    try {
      const clientsQuery = query(
        collection(db, "clients"),
        orderBy("dateAdded", "desc")
      );
      const querySnapshot = await getDocs(clientsQuery);
      const clientsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort by dateAdded (desc) and then lastLoginDate (desc)
      const sortedClientsData = clientsData.sort((a, b) => {
        // First compare by dateAdded
        const dateAddedDiff = b.dateAdded - a.dateAdded;
        if (dateAddedDiff !== 0) return dateAddedDiff;

        // If dateAdded is equal, compare by lastLoginDate
        // Handle cases where lastLoginDate might be undefined
        if (!a.lastLoginDate) return 1;
        if (!b.lastLoginDate) return -1;
        return b.lastLoginDate - a.lastLoginDate;
      });

      setClients(sortedClientsData);
      setFilteredClients(sortedClientsData);
    } catch (error) {
      message.error("Failed to fetch clients.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.role === "employee") {
      fetchClients();
    }
  }, [user]);

  // Search by Client Name or Admin Email
  const handleSearch = (value) => {
    const filteredData = clients.filter((client) => {
      const adminEmail =
        client.users && client.users[0] ? client.users[0].email : "";
      return (
        client?.clientName?.toLowerCase().includes(value.toLowerCase()) ||
        adminEmail.toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredClients(filteredData); // Update filtered clients
  };

  // Handle table changes (filters, sorting)
  const handleTableChange = (pagination, filters, sorter) => {
    let filteredData = [...clients];

    // Apply filters for "Has Access"
    if (filters.disabled) {
      filteredData = filteredData.filter((client) =>
        filters.disabled.includes(client.disabled)
      );
    }

    // Apply filters for "Subscription"
    if (filters.subscription) {
      filteredData = filteredData.filter((client) => {
        const subscriptionValue =
          client.subscription?.productName || client.subscription || "N/A";
        return filters.subscription.includes(subscriptionValue);
      });
    }

    // Apply filters for "Verifications"
    if (filters.verifications) {
      filteredData = filteredData.filter((client) => {
        const { identity, bank } = client.verifications || {};
        const verifications = { identity, bank };
        return filters.verifications?.some((filter) => verifications[filter]);
      });
    }

    setFilteredClients(filteredData);
  };

  const refreshCounts = async () => {
    try {
      setLoading(true);

      const clientDocs = await getDocs(
        query(
          collection(db, "clients"),
          where("verifications.bank", "==", true)
        )
      );

      const updatedClients = await Promise.all(
        clientDocs.docs.map(async (clientDoc) => {
          const clientId = clientDoc.id;

          // Fetch accounts count, excluding isDeleted === true
          const accountsSnapshot = await getDocs(
            collection(db, "clients", clientId, "accounts")
          );
          const accountsCount = accountsSnapshot.docs.filter(
            (doc) => doc.data().isDeleted !== true
          ).length;

          // Count accounts with status === "pre-approved" and isDeleted !== true
          const preApprovedAccountsCount = accountsSnapshot.docs.filter(
            (doc) =>
              doc.data().status === "pre-approved" &&
              doc.data().isDeleted !== true
          ).length;

          // Fetch checks count, excluding isDeleted === true
          const checksSnapshot = await getDocs(
            collection(db, "clients", clientId, "checks")
          );
          const checksCount = checksSnapshot.docs.filter(
            (doc) => doc.data().isDeleted !== true
          ).length;

          // Fetch user count in /users collection, excluding isDeleted === true
          const userSnapshot = await getDocs(
            query(collection(db, "users"), where("clientId", "==", clientId))
          );
          const userCount = userSnapshot.docs.filter(
            (doc) => doc.data().isDeleted !== true
          ).length;

          // Update the client in Firestore
          await updateDoc(clientDoc.ref, {
            accountsCount,
            // accountsPendingReviewCount: preApprovedAccountsCount,
            checksCount,
            userCount,
          });

          return {
            id: clientId,
            ...clientDoc.data(),
            accountsCount,
            // accountsPendingReviewCount: preApprovedAccountsCount,
            checksCount,
            userCount,
          };
        })
      );

      setClients(updatedClients);
      setFilteredClients(updatedClients);
      message.success("Client counts refreshed successfully.");
    } catch (error) {
      message.error("Failed to refresh client counts.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Update client access (enable/disable)
  const toggleAccess = async (id, currentAccess) => {
    try {
      const clientRef = doc(db, "clients", id);
      await updateDoc(clientRef, { disabled: !currentAccess });

      const updatedClients = clients.map((client) =>
        client.id === id ? { ...client, disabled: !currentAccess } : client
      );

      // Update both clients and filteredClients to reflect the change
      setClients(updatedClients);
      setFilteredClients(updatedClients);

      message.success("Access updated successfully.");
    } catch (error) {
      message.error("Failed to update access.");
      console.error(error);
    }
  };

  // Handle modal to update trial expiration date
  const showModal = (client) => {
    setEditingClient(client);
    setNewTrialDate(moment(client.trialExpiresOn));
    setIsModalOpen(true);
  };

  const handleDateChange = (date) => {
    setNewTrialDate(date);
  };

  const handleOk = async () => {
    if (!newTrialDate || !editingClient) return;
    try {
      const clientRef = doc(db, "clients", editingClient.id);
      await updateDoc(clientRef, {
        trialExpiresOn: newTrialDate.toDate(),
      });
      const updatedClients = clients.map((client) =>
        client.id === editingClient.id
          ? { ...client, trialExpiresOn: newTrialDate.toDate() }
          : client
      );
      setClients(updatedClients);
      setFilteredClients(updatedClients);
      message.success("Trial expiration date updated.");
      fetchClients();
    } catch (error) {
      message.error("Failed to update trial expiration date.");
      console.error(error);
    } finally {
      setIsModalOpen(false);
      setEditingClient(null);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Verification column rendering logic
  const renderVerificationIcons = (record) => {
    const { identity, bank, identityMeta, sms, email } = record.verifications;
    // const allVerified = identity && bank;

    // if (allVerified) {
    //   return <CheckCircleOutlined className="success" />;
    // }

    return (
      <>
      {sms === true ?  <MobileOutlined className="success" style={{ paddingLeft: 10 }} /> :  <MobileOutlined className="error" style={{ paddingLeft: 10 }} />}
      {email === true ?  <MailOutlined className="success" style={{ paddingLeft: 10 }} /> :  <MailOutlined className="error" style={{ paddingLeft: 10 }} />}


        {bank ? (
          <BankOutlined className="success" style={{ paddingLeft: 10 }} />
        ) : record.verifications.bankStatus === 'started' && !bank ? (
          <Tooltip title="Banking verification started but not completed">
            <BankOutlined className="warning" style={{ paddingLeft: 10 }} />
          </Tooltip>
        ) : (
          <BankOutlined className="error" style={{ paddingLeft: 10 }} />
        )}
        {identity === true && (
          <IdcardOutlined className="success" />
        )}
      </>
    );
  };

  const rowClassName = (record) => {
    // If 'markedForDeletion' is true, apply the 'highlight' class
    return record.markedForDeletion ? "bg-error-light" : "";
  };

  const markForDeletion = async (record) => {
    const clientRef = doc(db, "clients", record.id);
    await updateDoc(clientRef, {
      markedForDeletion: true, markedForDeletionDate: new Date(), markedForDeletionUser: user.email
    });
    await callApi({
      url: "/v1/mailer",
      method: "POST",
      body: {
        email: record.users[0].email,
        template: "marked-for-account-deletion",
      }
    });
    message.success("Client marked for deletion.");
    fetchClients();
  };

  const forceIdentityVerification = async (record) => {
    if (record?.verifications?.identity === true) {
      message.error("Client already has identity verification.");
      return;
    }
    const clientRef = doc(db, "clients", record.id);
    await updateDoc(clientRef, {
      idVerificationRequired: true,

    });
    message.success("User will be foreced to verify identity next time they login.");
  }

  const deleteClient = async (record) => {
    if (user?.role !== 'employee') return message.error("You are not authorized to delete clients.");
    if (record?.subscription !== "trial") return message.error("You can't delete client with active subscription.");

    try {
      const auth = getAuth();

      // 1. Delete users from Authentication and Firestore
      const usersRef = collection(db, "users");
      const usersQuery = query(usersRef, where("clientId", "==", record.id));
      const usersSnapshot = await getDocs(usersQuery);

      for (const userDoc of usersSnapshot.docs) {
        try {
          await deleteDoc(userDoc.ref);
          console.warn(`User ${userDoc.id} needs to be deleted from Authentication via backend`);
        } catch (error) {
          console.error(`Failed to delete user ${userDoc.id}:`, error);
        }
      }

      // 2. Handle checks and their history subcollections first
      const checksRef = collection(db, "clients", record.id, "checks");
      const checksSnapshot = await getDocs(checksRef);

      // Delete each check's history subcollection and then the check itself
      for (const checkDoc of checksSnapshot.docs) {
        // Get all history documents for this check
        const historyRef = collection(checksRef, checkDoc.id, "history");
        const historySnapshot = await getDocs(historyRef);

        // Delete all history documents
        await Promise.all(historySnapshot.docs.map(historyDoc => deleteDoc(historyDoc.ref)));

        // After history is deleted, delete the check document
        await deleteDoc(checkDoc.ref);
      }

      // 3. Delete remaining subcollections
      const subcollections = ['accounts', 'payees', 'apiKeys', 'integrations', 'keys'];
      for (const subcollectionName of subcollections) {
        const subcollectionRef = collection(db, "clients", record.id, subcollectionName);
        const subcollectionSnapshot = await getDocs(subcollectionRef);
        await Promise.all(subcollectionSnapshot.docs.map(doc => deleteDoc(doc.ref)));
      }

      // 4. Delete metaChecks
      const metaChecksRef = collection(db, "metaChecks");
      const metaChecksQuery = query(metaChecksRef, where("clientId", "==", record.id));
      const metaChecksSnapshot = await getDocs(metaChecksQuery);
      await Promise.all(metaChecksSnapshot.docs.map(doc => deleteDoc(doc.ref)));

      // 5. Delete metaAccounts
      const metaAccountRef = collection(db, "metaAccount");
      const metaAccountQuery = query(metaAccountRef, where("clientId", "==", record.id));
      const metaAccountSnapshot = await getDocs(metaAccountQuery);
      await Promise.all(metaAccountSnapshot.docs.map(doc => deleteDoc(doc.ref)));

      // 6. Delete fulfillmentOrders
      const fulfillmentOrdersRef = collection(db, "fulfillmentOrders");
      const fulfillmentOrdersQuery = query(fulfillmentOrdersRef, where("clientId", "==", record.id));
      const fulfillmentOrdersSnapshot = await getDocs(fulfillmentOrdersQuery);
      await Promise.all(fulfillmentOrdersSnapshot.docs.map(doc => deleteDoc(doc.ref)));

      // 7. Finally delete the client document itself
      await deleteDoc(doc(db, "clients", record.id));

      message.success("Client and all related data deleted successfully.");
      fetchClients();
    } catch (error) {
      console.error("Error deleting client:", error);
      message.error("Failed to delete client and related data");
    }
  };


  // Table columns definition
  const columns = [
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item
              key="1"
              disabled={!user || user.role !== "employee"}
              onClick={() => toggleAccess(record.id, record?.disabled || false)}
            >
              {record.disabled ? "Enable Access" : "Disable Access"}
            </Menu.Item>
            <Menu.Item key="2" onClick={() => showModal(record)}>
              Change Trial
            </Menu.Item>
            <Menu.Item key="3" onClick={() => forceIdentityVerification(record)}>
              Force Identity Verification
            </Menu.Item>
            {!record.markedForDeletion &&
              <Menu.Item key="4" onClick={() => markForDeletion(record)}>
                <span className="error">Mark For Deletion</span>
              </Menu.Item>
            }
            { // if marked for deletion, and it's been 15 days, show delete option
              record.markedForDeletion &&
              record.markedForDeletionDate &&
              moment().diff(moment(record.markedForDeletionDate.toDate()), 'days') >= 15 &&
              <Menu.Item key="5" onClick={() => deleteClient(record)}>
                <span className="error">Delete Client</span>
              </Menu.Item>
            }
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      render: (text, record) => (
        <Link to={`/clients/${record.id}`}>{<strong>{text}</strong>}</Link> // Navigates to /client/${client.id}
      ),
    },
    {
      title: "Verifications",
      key: "verifications",
      render: (text, record) => renderVerificationIcons(record),
      sorter: (a, b) => {
        const aCount = [a.verifications.identity, a.verifications.bank].filter(
          Boolean
        ).length;
        const bCount = [b.verifications.identity, b.verifications.bank].filter(
          Boolean
        ).length;
        return aCount - bCount;
      },
      filters: [
        { text: "Identity Verified", value: "identity" },
        { text: "Bank Verified", value: "bank" },
      ],
      onFilter: (value, record) => {
        const { identity, bank } = record.verifications || {};
        const verifications = { identity, bank };
        return verifications[value];
      },
    },
    {
      title: "Force Id Check",
      key: "idVerificationRequired",
      render: (text, record) => {
        return record.idVerificationRequired === true ? "Yes" : "No"
      },
    },
    {
      title: "Admin Email",
      key: "adminEmail",
      render: (text, record) =>
        record.users && record.users[0] ? record.users[0].email : "N/A",
    },

    {
      title: "Disabled",
      dataIndex: "disabled",
      key: "disabled",
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.disabled === value,
      render: (text, record) => (
        <span style={{ color: record?.disabled ? "red" : "" }}>
          {record.disabled ? "Yes" : "No"}
        </span>
      ),
    },
    {
      title: "Date Added",
      dataIndex: "dateAdded",
      key: "dateAdded",
      sorter: (a, b) => {
        if (!a.dateAdded) return -1;
        if (!b.dateAdded) return 1;
        return a.dateAdded - b.dateAdded;
      },
      render: (dateAdded) => formatDate(dateAdded), // Your custom format function
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
      filters: [
        ...Array.from(
          new Set(
            clients.map(
              (client) =>
                client.subscription?.productName || client.subscription || "N/A"
            )
          )
        ).map((value) => ({ text: value, value })),
      ],
      onFilter: (value, record) => {
        const subscriptionValue =
          record.subscription?.productName || record.subscription || "N/A";
        return subscriptionValue === value;
      },
      render: (text, record) => {
        // Check if 'subscription' exists and fallback to 'subscription.productName'
        return record.subscription?.productName
          ? record.subscription?.productName
          : record.subscription || "N/A";
      },
    },

    {
      title: "Trial Ends",
      dataIndex: "trialExpiresOn",
      key: "trialExpiresOn",
      sorter: (a, b) => {
        if (!a.trialExpiresOn) return -1;
        if (!b.trialExpiresOn) return 1;
        return a.trialExpiresOn - b.trialExpiresOn;
      },
      render: (trialExpiresOn) => formatDate(trialExpiresOn),
    },
    {
      title: "Acc Del Date",
      key: "deletionDate",
      render: (_, record) => {
        if (!record.markedForDeletionDate) return "";
        const deletionDate = moment(record.markedForDeletionDate.toDate()).add(15, 'days');
        const isPast = deletionDate.isBefore(moment());
        return <span className={isPast ? "error" : ""}>{formatDate(deletionDate.toDate())}</span>;
      }
    },
    {
      title: "Last Login",
      dataIndex: "lastLoginDate",
      key: "lastLoginDate",
      sorter: (a, b) => {
        if (!a.lastLoginDate) return -1;
        if (!b.lastLoginDate) return 1;
        return a.lastLoginDate - b.lastLoginDate;
      },
      render: (lastLoginDate) =>
        lastLoginDate ? formatDate(lastLoginDate) : "",
    },

    {
      title: "# Acc",
      dataIndex: "accountsCount",
      key: "accountsCount",
    },
    {
      title: "# Integrations",
      dataIndex: "accountsPendingReviewCount",
      key: "accountsPendingReviewCount",
      render: (text, record) => {
        return record?.integrations?.length || 0;
      },
    },
    {
      title: "# checks",
      dataIndex: "checksCount",
      key: "checksCount",
    },
    {
      title: "# users",
      dataIndex: "userCount",
      key: "userCount",
    },
  ];

  const payingClientsCount = filteredClients.filter(
    (client) => client.subscription?.status === "active"
  ).length;

  const bankVerifiedCount = filteredClients.filter(
    (client) => client.verifications?.bank === true
  ).length;

  return (
    <>
      <h1 className="monospace">Clients</h1>
      {/* Record count and search input */}
      <Card style={{ width: "100%" }}>
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col span={5}>
            <Typography.Paragraph>
              Record Count: {filteredClients.length}
            </Typography.Paragraph>
          </Col>
          <Col span={5}>
            <Typography.Paragraph>
              Paying Clients: {payingClientsCount}
            </Typography.Paragraph>
          </Col>
          <Col span={5}>
            <Typography.Paragraph>
              Bank Verified: {bankVerifiedCount}
            </Typography.Paragraph>
          </Col>
          <Col span={4}>
            <Button onClick={refreshCounts} className="button-yellow">
              Refresh Count
            </Button>
          </Col>
          <Col span={5}>
            <Search
              placeholder="Search by Client Name or Admin Email"
              onSearch={handleSearch}
              enterButton
              style={{ marginRight: 8 }}
            />
          </Col>
        </Row>
        <Table
          dataSource={filteredClients} // Use filtered clients for the table
          columns={columns}
          rowClassName={rowClassName}
          rowKey="id"
          loading={loading}
          pagination={false}
          onChange={handleTableChange}
          style={{ marginTop: 10 }} // Handle table changes like filtering/sorting
        />
      </Card>

      {/* Modal for updating trial expiration date */}
      <Modal
        title="Change Trial Expiration Date"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {editingClient && editingClient.trialExpiresOn && (
          <DatePicker
            style={{ width: "50%", marginTop: "50px", marginBottom: "50px" }}
            onChange={handleDateChange} // Update the new date in state
          />
        )}
      </Modal>
    </>
  );
}

export default Clients;
