import React, { useState } from "react";
import { Form, Input, Modal, Button, notification } from "antd";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase"; // Import the initialized Firestore instance
import { updateProfile } from "firebase/auth";
import apiCall from "../../../utils/functions/apiCall";

const UserDetailsTab = () => {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFinish = async (values) => {
    if (!values.displayName.includes(" ")) {
      return alert("Full name is required and must contain a space.");
    }

    // Validate phone number - remove any non-digit characters and check length
    const phoneDigits = values.phone.replace(/\D/g, '');
    if (phoneDigits.length !== 10) {
      notification.error({ message: "Phone number must be exactly 10 digits" });
      return;
    }

    setLoading(true);

    try {
      const userDocRef = doc(db, "users", user.uid);
      const clientDocRef = doc(db, "clients", user.clientId);

      // Check if phone number has changed
      if (values.phone !== user.phone) {
        // Update client document to set validations.sms to false
        await updateDoc(clientDocRef, {
          "verifications.sms": false
        });
      }

      await updateDoc(userDocRef, values);
      setUser({ ...user, ...values });
      if (user.displayName !== values.displayName) {
        await updateProfile(user, {
          name: values.displayName,
        });
      }
      notification.success({ message: "Profile updated successfully!" });

      // Hard refresh the page if phone number changed
      // if (values.phone !== user.phone) {
      //   window.location.reload();
      // }
    } catch (error) {
      console.error("Error updating profile: ", error);
      notification.error({ message: "Failed to update profile." });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = () => {
    setIsModalVisible(true);
  };

  const handleModalConfirm = async () => {
    setLoading(true); // Start loading when confirmation is triggered
    try {
      //if there's a subscription
      if (user.clientData.subscription.stripeSubscriptionId) {
        await apiCall({
          url: `/v1/payments/stripe/subscription/${user.clientData.subscription.stripeSubscriptionId}`,
          method: "DELETE",
        });
      }

      //mark for deletion
      const userDocRef = doc(db, "clients", user.clientId);
      const values = {
        hasAccess: false,
        disabled: true,
        markedForDeletion: true,
        markedForDeletionDate: new Date()
      };
      await updateDoc(userDocRef, values);
      window.location.href = "/logout";
    } catch (e) {
      console.log("error deleting account:", e.message);
      notification.error({ message: `Error deleting the account` });
    } finally {
      setLoading(false); // Set loading to false after the operation is complete
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Form layout="vertical" initialValues={user} onFinish={onFinish}>
      <Form.Item
        name="displayName"
        label="Full Name"
        rules={[{ required: true, message: "Full name is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Email">
        <Input disabled />
      </Form.Item>
      <Form.Item name="companyName" label="Company Name">
        <Input
          disabled={
            user.role !== "admin" && user.role !== "employee" ? true : false
          }
        />
      </Form.Item>
      <Form.Item name="phone" label="Phone">
        <Input />
      </Form.Item>

      {/* Wrapper for buttons */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item>
          <Button
            className="button-blue"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            Save
          </Button>
        </Form.Item>
        {(user.role === "admin" || user.role === "employee") && (
          <Form.Item>
            <Button type="dashed" danger onClick={handleDeleteClick}>
              Permanently Delete Account
            </Button>
          </Form.Item>
        )}
      </div>

      {/* Confirmation Modal */}
      <Modal
        title="Permanently Deleting Account Confirmation"
        open={isModalVisible}
        onOk={handleModalConfirm}
        onCancel={handleModalCancel}
        okText="Yes, Delete My Account Permanently"
        cancelText="Cancel"
        okButtonProps={{
          className: "button-red",
          loading: loading, // Loading state for the OK button
        }}
        cancelButtonProps={{
          loading: loading, // Loading state for the Cancel button
        }}
      >
        <p>
          Includes canceling any active subscription, immediately losing access
          to application along with all users associated with this account, and
          all data such as payments, bank information will be marked for
          deletion. This process is irreversible. Please ensure you saved all
          the necessary information for your records before proceeding.
        </p>
        <p>
          Please not that unexpired email checks sent will still be redeemable.
          Initiated direct deposit will be executed. Active fulfillment orders
          in progress will also be executed.
        </p>
      </Modal>
    </Form>
  );
};

export default UserDetailsTab;
