import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { GenerateAPIKeyModal } from "./Components/Modal";
import { collection, onSnapshot, doc, deleteDoc } from "firebase/firestore";
import { Table, Button, Row, Col, Modal, message, Space } from "antd";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";

const APIKeyTable = ({ user }) => {
  const [apiKeys, setApiKeys] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    const apiKeysRef = collection(db, `/clients/${user.clientId}/apiKeys`);
    const unsubscribe = onSnapshot(apiKeysRef, (snapshot) => {
      setApiKeys(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });
    setClientId(user.clientId);

    return () => unsubscribe();
  }, [user]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success("Copied to clipboard!");
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this API key?",
      content: "This action cannot be undone.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => deleteApiKey(id),
    });
  };

  const deleteApiKey = async (id) => {
    try {
      await deleteDoc(doc(db, `/clients/${user.clientId}/apiKeys`, id));
      message.success("API Key deleted successfully");
    } catch (error) {
      message.error("Failed to delete API Key");
    }
  };

  const handleGenerateApiKey = () => {
    if (apiKeys.length >= 1) {
      message.error("Maximum of one API key allowed. Please delete existing key to generate a new one.");
      return;
    }

    if (!user.isEnterprise) {
      Modal.confirm({
        title: "Enterprise Feature",
        content: "API access is an enterprise subscriptionfeature. Please contact our sales team to upgrade.",
        okText: "Contact Sales",
        cancelText: "Cancel",
        onOk: () => {
          window.open('https://simple-checks.com/contact', '_blank');
        }
      });
      return;
    }

    setShowModal(true);
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Client ID",
      key: "clientId",
      render: () => (
        <Space>
          {user.clientId}
          <Button
            icon={<CopyOutlined />}
            type="link"
            onClick={() => copyToClipboard(user.clientId)}
          />
        </Space>
      ),
    },
    {
      title: "API Key",
      dataIndex: "apiKey",
      key: "apiKey",
      render: (apiKey) => (
        <Space>
          {apiKey}
          <Button
            icon={<CopyOutlined />}
            type="link"
            onClick={() => copyToClipboard(apiKey)}
          />
        </Space>
      ),
    },
    {
      title: "API Secret",
      dataIndex: "apiSecret",
      key: "apiSecret",
      render: (apiSecret) => (
        <Space>
          <Button
            onClick={() =>
              Modal.info({ title: "API Secret", content: apiSecret })
            }
          >
            Show Secret
          </Button>
          <Button
            icon={<CopyOutlined />}
            type="link"
            onClick={() => copyToClipboard(apiSecret)}
          />
        </Space>
      ),
    },
    {
      title: "Payload Signature",
      dataIndex: "payloadSignature",
      key: "payloadSignature",
      render: (payloadSignature) => (
        <Space>
          <Button
            onClick={() =>
              Modal.info({ title: "Payload Signature", content: payloadSignature })
            }
          >
            Show Signature
          </Button>
          <Button
            icon={<CopyOutlined />}
            type="link"
            onClick={() => copyToClipboard(payloadSignature)}
          />
        </Space>
      ),
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
      render: (perms) => perms.join(", "),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => confirmDelete(record.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Row justify="end" style={{ marginBottom: 16 }}>
        <Col>
          <Button className="button-blue" onClick={handleGenerateApiKey}>
            Generate New API Key
          </Button>
        </Col>
      </Row>
      <Table dataSource={apiKeys} columns={columns} rowKey="id" />

      {showModal && (
        <GenerateAPIKeyModal
          clientId={user.clientId}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default APIKeyTable;
