import React, { useEffect, useState } from "react";
import { Tabs, Card } from "antd";
import UserDetailsTab from "./UserDetailsTab";
import PaymentTab from "./PaymentTab";
import SystemPreferencesTab from "./SystemPreferences";
import { SettingOutlined } from "@ant-design/icons";
import CheckTemplates from "../CheckTemplates";
import UserList from "./UserTab";
import IntegrationTab from "./IntegrationsTab";
import { useAuth } from "../../contexts/AuthContext";
import BankAccountsTab from "./BankAccountsTab";
import { useParams } from "react-router-dom";
import DigitalTransactions from "./DigitalTransactions";
import APIKeyTable from "./ApiTab";

const Settings = () => {
  const { user } = useAuth();
  const [activeKey, setActiveKey] = useState("1");
  const [isPremium, setIsPremium] = useState(false);
  const [isEnterprise, setIsEnterprise] = useState(false);
  const { urlParameter } = useParams();

  useEffect(() => {
    if (user.isPremium) setIsPremium(true);
    if (user.isEnterprise) setIsEnterprise(true);
    if (urlParameter && urlParameter === "bank-accounts") setActiveKey("5");
    if (urlParameter && urlParameter === "subscription") setActiveKey("2");
    if (urlParameter && urlParameter === "integrations") setActiveKey("6");
    if (urlParameter && urlParameter === "api") setActiveKey("7");
    if (urlParameter && urlParameter === "digital-transactions") setActiveKey("8");
    if (urlParameter && urlParameter === "system-preferences") setActiveKey("3");
    // console.clear()
  }, [user]);

  const items = [
    {
      key: "1",
      label: "User Details",
      children: <UserDetailsTab />,
    },
    {
      key: "2",
      label: "Subscription",
      children: <PaymentTab />,
      disabled:
        user.role !== "admin" && user.role !== "employee" ? true : false,
    },
    {
      key: "4",
      label: "Users",
      children: <UserList />,
      disabled: user.role !== "admin" && user.role !== "employee" ? true : false
    },
    {
      key: "5",
      label: "Bank Accounts",
      children: <BankAccountsTab />,
      disabled:
      user.role !== "admin" && user.role !== "employee" ? true : false,
    },
    // {
    //   key: "8",
    //   label: "Payment",
    //   children: <BankAccountsTab />,
    //   disabled:
    //     user.role !== "admin" && user.role !== "employee" ? true : false,
    // },
    // {
    //   key: "6",
    //   label: !isPremium ? "Check Templates (Premium)" : "Check Templates",
    //   children: <CheckTemplates />,
    //   disabled: !isPremium,
    // },
    {
      key: "3",
      label: "System Preferences",
      children: <SystemPreferencesTab />,
      // disabled: !isPremium,
      disabled:  user.role !== "admin" && user.role !== "employee" ? true : false,
    },
    {
      key: "6",
      label: "Integrations",
      children: <IntegrationTab setActiveKey={setActiveKey} />,
      disabled:
        user.role !== "admin" && user.role !== "employee" ? true : false,
    },
    // {
    //   key: "8",
    //   label: "Digital Transactions",
    //   children: <DigitalTransactions user={user} />,
    //   disabled:
    //     user.role !== "admin" && user.role !== "employee" ? true : false,
    //   // disabled: true,
    // },
    {
      key: "7",
      label: "API Access",
      children: <APIKeyTable user={user} />,
      disabled: user.role !== "admin" && user.role !== "employee" ? true : false,
    },
  ];

  return (
    <>
      <h1 className="monospace">
        <SettingOutlined /> Settings
      </h1>
      <Card>
        <Tabs
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
          items={items}
        />
      </Card>
    </>
  );
};

export default Settings;
