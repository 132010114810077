import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Space,
  message,
} from "antd";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { sendEmail } from "../../../utils/functions/mailer";
import moment from "moment";

const { Option } = Select;

const EmailTemplates = ({ user, refresh }) => {
  const [templates, setTemplates] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [currentTemplate, setCurrentTemplate] = useState(null); // For editing an existing template
  const [editorContent, setEditorContent] = useState(""); // State for Quill content
  const [isHtmlMode, setIsHtmlMode] = useState(false); // Toggle between WYSIWYG and HTML mode

  // Fetch templates
  const fetchTemplates = async () => {
    const querySnapshot = await getDocs(collection(db, "marketingTemplates"));
    const templatesData = querySnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter((template) => !template.isDeleted); // Fetch only non-deleted templates
    setTemplates(templatesData);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  // Add or edit template
  const saveTemplate = async (values) => {
    const currentTime = new Date();
    const templateData = { ...values, html: editorContent };

    if (currentTemplate) {
      // Update existing template
      await updateDoc(doc(db, "marketingTemplates", currentTemplate.id), {
        ...templateData,
        dateModified: currentTime,
      });
    } else {
      // Add new template
      await addDoc(collection(db, "marketingTemplates"), {
        ...templateData,
        dateAdded: currentTime,
        dateModified: currentTime,
      });
    }
    fetchTemplates();
    setIsModalVisible(false);
    form.resetFields();
    setEditorContent(""); // Clear editor content
    refresh();

  };

  // Delete template (set isDeleted = true)
  const deleteTemplate = async (id) => {
    await updateDoc(doc(db, "marketingTemplates", id), { isDeleted: true });
    setTemplates((prevTemplates) =>
      prevTemplates.filter((template) => template.id !== id)
    );
    message.success("Template deleted successfully.");
    refresh();
  };

  // Send test email
  const sendTestEmail = async (record) => {
    try {
      await sendEmail(user, user.email, 'test', null, record.html);
      message.success("Test email sent successfully.");
    } catch (error) {
      message.error("Failed to send test email.");
    }
  };

  // Open modal to add/edit template
  const openModal = (template = null) => {
    setCurrentTemplate(template);
    if (template) {
      form.setFieldsValue(template);
      setEditorContent(template.html); // Set Quill content when editing
    } else {
      form.resetFields();
      setEditorContent(""); // Clear editor content when adding a new template
    }
    setIsModalVisible(true);
  };

  // Custom Quill toolbar options
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "blockquote", "code-block"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["clean"], // Remove formatting button
    ],
  };

  // Template columns
  const columns = [
    { title: "Template Name", dataIndex: "name", key: "name" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Purpose", dataIndex: "purpose", key: "purpose" },
    {
      title: "Date Added",
      dataIndex: "dateAdded",
      key: "dateAdded",
      render: (date) => moment(date.toDate()).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Date Modified",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (date) => moment(date.toDate()).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Space>
          <Button onClick={() => openModal(record)}>Edit</Button>
          <Button danger onClick={() => deleteTemplate(record.id)}>
            Delete
          </Button>
          <Button onClick={() => sendTestEmail(record)}>
            Send Me Test Email
          </Button>
        </Space>
      ),
    },
  ];

  // Function to generate the HTML content for the iframe
  const generateIframeContent = () => {
    return `
      <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
          }
          .center {
            text-align: center;
            margin: 20px 0;
          }
          .button {
            background-color: #0984e3; 
            color: white; 
            padding: 12px 24px; 
            text-decoration: none; 
            font-size: 16px; 
            border-radius: 5px;
            display: inline-block;
          }
        </style>
      </head>
      <body>
        ${editorContent}
      </body>
      </html>
    `;
  };

  return (
    <div>
      {/* Add Template Button with Padding */}
      <div style={{ marginBottom: 20 }}>
        <Button className="button-blue" onClick={() => openModal()}>
          Add Template
        </Button>
      </div>

      {/* Template Table */}
      <Table columns={columns} dataSource={templates} rowKey="id" />

      {/* Add/Edit Template Modal */}
      <Modal
        title={currentTemplate ? "Edit Email Template" : "Add Email Template"}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => form.submit()}
        width={900} // Larger modal size
        style={{ top: 20, height: "80vh" }} // Set the height
      >
        <Form form={form} onFinish={saveTemplate} layout="vertical">
          {/* Template Name */}
          <Form.Item
            label="Template Name"
            name="name"
            rules={[
              { required: true, message: "Please enter a template name." },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Description */}
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please enter a description." }]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
       

          {/* Purpose Dropdown */}
          <Form.Item
            label="Purpose"
            name="purpose"
            rules={[{ required: true, message: "Please select a purpose." }]}
          >
            <Select>
              <Option value="cold lead">Lead - Cold</Option>
              <Option value="potential client">Lead - Potential Client</Option>
              <Option value="hot lead">Lead - Hot</Option>
              <Option value="client incomplete onboarding">
                Client - Incomplete Onboarding
              </Option>
              <Option value="client trial expired">
                Client - Trial Expired
              </Option>
              <Option value="client reengagement">Client - Reengagement</Option>
              <Option value="client active subscription">
                Client - Active Subscription
              </Option>
              <Option value="client canceled subscription">
                Client - Canceled Subscription
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Email Subject"
            name="subject"
            rules={[{ required: true, message: "Please enter email subject as it will be seen by recipient." }]}
          >
            <Input />
          </Form.Item>      
            <Form.Item name="html">
              <Input.TextArea
                rows={8}
                value={editorContent}
                onChange={(e) => setEditorContent(e.target.value)}
              />
            </Form.Item>
        <p>Double Brackets Tags</p>
        <p>trackingRedirectUrl</p>
          <div style={{ marginTop: "20px" }}>
            <iframe
              title="Email Preview"
              srcDoc={generateIframeContent()}
              style={{ width: "100%", height: "300", border: "1px solid #ccc", overflow:"auto" }}
            />
          </div>

          {/* Date Added and Date Modified (read-only fields) */}
          {currentTemplate && (
            <>
              <Form.Item label="Date Added">
                <Input
                  value={moment(currentTemplate.dateAdded.toDate()).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                  readOnly
                />
              </Form.Item>
              <Form.Item label="Date Modified">
                <Input
                  value={moment(currentTemplate.dateModified.toDate()).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                  readOnly
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default EmailTemplates;
