import React, { useState } from "react";
import { Modal, Button } from "antd";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { CreditCardOutlined } from "@ant-design/icons";

const UpdatePaymentInformation = ({ user }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const url = process.env.REACT_APP_API_ENDPOINT

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const paymentMethodResult = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.name,
          email: user.email,
        },
      });

      if (paymentMethodResult.error) {
        setErrorMessage(paymentMethodResult.error.message);
        setIsProcessing(false);
        return;
      }

      const paymentMethodId = paymentMethodResult.paymentMethod.id;

      // Call your backend to update the payment method
      const response = await fetch(
        `${url}/v1/payments/stripe/update-payment-method`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify({
            stripeCustomerId: user.clientData.stripeCustomerId,
            paymentMethodId,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setIsModalVisible(false);
        alert("Payment method updated successfully!");
      } else {
        setErrorMessage(data.error);
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again.");
    }

    setIsProcessing(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        lineHeight: "1.5",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#dc3545",
        iconColor: "#dc3545",
      },
    },
  };

  return (
    <>
      <Button 
        className="button-blue" 
        onClick={showModal}
        // size="large"
        icon={<CreditCardOutlined />}
      >
        Update Payment Information
      </Button>
      <Modal
        title="Update Payment Information"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={480}
        centered
      >
        <form onSubmit={handleSubmit}>
          <div style={{ padding: "20px 0" }}>
            <div style={{ marginBottom: "24px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "8px",
                  fontWeight: "500",
                  color: "#32325d"
                }}
              >
                Card Details
              </label>
              <div
                style={{
                  padding: "12px",
                  border: "1px solid #e4e4e4",
                  borderRadius: "6px",
                  backgroundColor: "#ffffff"
                }}
              >
                <CardElement options={cardElementOptions} />
              </div>
            </div>

            {errorMessage && (
              <div
                style={{
                  padding: "12px",
                  marginBottom: "16px",
                  color: "#dc3545",
                  backgroundColor: "#ffeaed",
                  borderRadius: "6px",
                  fontSize: "14px"
                }}
              >
                {errorMessage}
              </div>
            )}

            <Button
              type="primary"
              htmlType="submit"
              loading={isProcessing}
              disabled={!stripe || !elements}
              block
              size="large"
              style={{ height: "48px" }}
            >
              {isProcessing ? "Updating payment method..." : "Update Payment Method"}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UpdatePaymentInformation;
