// src/components/Register.js
import React, { useState } from "react";
import { Form, Input, Button, Typography, Card, notification } from "antd";
import { auth } from "../../../firebase";
import { useNavigate } from "react-router-dom"; // Updated import
import {
  createUserWithEmailAndPassword,
  // sendEmailVerification,
} from "firebase/auth";

const dev = process.env.REACT_APP_ENVIRONMENT === "dev" ? true : false;
const { Text } = Typography;
const DISPOSABLE_EMAIL_DOMAINS = [
  // "yopmail.com",
  "mailinator.com",
  "10minutemail.com",
  "guerrillamail.com",
  "temp-mail.org",
  "throwawaymail.com",
  "maildrop.cc",
  "trashmail.com",
  "dispostable.com",
  "getairmail.com",
  "fakeinbox.com",
  "emailondeck.com",
  "moakt.com",
  "mailcatch.com",
  "sharklasers.com",
  "spambog.com",
  "mail-temporaire.fr",
  "spamgourmet.com",
  "mytemp.email",
  "tempmailo.com",
  "mintemail.com",
  "inboxkitten.com",
  "33mail.com",
  "emailfake.com",
  "mailnesia.com",
];

const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Use useNavigate for redirection

  const onFinish = async (values) => {
    const { email, password } = values;

    //validate the email
    if (!dev && DISPOSABLE_EMAIL_DOMAINS?.some((value) => email.includes(value))) {
      notification.error({
        message: "Disposable emails are not allowed to register",
      });
      return;
    }

    try {
      // Register the user with Firebase
      await createUserWithEmailAndPassword(auth, email, password);
      notification.success({
        message: "Registration Successful! Please login to continue.",
      });

      // await sendEmailVerification(auth.currentUser);
      // Redirect to root
      navigate(process.env.REACT_APP_STARTING_PAGE || "/");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        notification.error({
          message: "Email already in use.",
        });
        return;
      }
      console.error("Registration Error:", error.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card
        title={
          <div style={{ textAlign: "center", fontSize: "24px", fontWeight: "bold", color: "#333" }}>
            Create Account
          </div>
        }
        style={{
          width: 400,
          borderRadius: 12,
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
        headStyle={{
          borderBottom: "none",
          padding: "24px 24px 0",
        }}
        bodyStyle={{
          padding: "24px",
        }}
      >
        <Text style={{ display: "block", textAlign: "center", marginBottom: "20px", color: "#666" }}>
          Please enter your details to register
        </Text>
        <Form 
          name="register" 
          onFinish={onFinish} 
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your Email" }]}
          >
            <Input 
              placeholder="Email" 
              size="large"
              style={{ borderRadius: "8px" }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please input your Password" },
              {
                min: 8,
                message: "Password must be at least 8 characters",
              },
              {
                pattern:
                  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message:
                  "Password must include at least one uppercase letter, one number, and one special character",
              },
            ]}
          >
            <Input.Password 
              placeholder="Password" 
              size="large"
              style={{ borderRadius: "8px" }}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match"));
                },
              }),
            ]}
          >
            <Input.Password 
              placeholder="Confirm Password" 
              size="large"
              style={{ borderRadius: "8px" }}
            />
          </Form.Item>

          <Form.Item style={{ marginTop: "24px" }}>
            <Button 
              htmlType="submit" 
              className="button-blue"
              loading={loading} 
              block
              style={{
                height: "46px",
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "500",
                // background: "linear-gradient(90deg, #667eea 0%, #764ba2 100%)",
                border: "none",
              }}
            >
              Register
            </Button>
          </Form.Item>
          
          <div style={{ textAlign: "center", marginTop: "16px" }}>
            <Text style={{ color: "#666" }}>
              Already have an account?{" "}
              <a 
                onClick={() => navigate("/login")}
                style={{ color: "#667eea", cursor: "pointer", fontWeight: "500" }}
              >
                Login
              </a>
            </Text>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default Register;
