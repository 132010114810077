import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Typography,
  Spin,
  Button,
  Checkbox,
  Input,
  Modal,
  Divider,
  message,
  notification,
  Upload,
  Tag,
  Select,
} from "antd";
import {
  BankOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
  MailOutlined,
  LoginOutlined,
  UploadOutlined,
  FieldBinaryOutlined,
  LeftOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../../contexts/AuthContext";
import { usePlaidLink } from "react-plaid-link";
import {
  DisplaySignature,
  SignaturePad,
} from "../../../shared/Components/Signature";
import { functions, db, storage } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import {
  collection,
  doc,
  addDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { metaAccountVerificationUpsert } from "../../../services/metadata/accountVerification";
import { sendEmail } from "../../../utils/functions/mailer";
import apiCall from "../../../utils/functions/apiCall";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";


function BankingVerification({ nextStep, previousStep, onCancel }) {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [accountInfo, setAccountInfo] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const url = process.env.REACT_APP_API_ENDPOINT;
  const [validated, setValidated] = useState(false);
  const [workflow, setWorkflow] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [done, setDone] = useState(false);
  const [test, setTest] = useState(false);
  const [stripe, setStripe] = useState(null);

  const navigate = useNavigate()

  const VERIFICATION_TYPE = {
    PLAID: "plaid",
    UPLOAD: "upload",
    MICRODEPOSITS: "microdeposits",
  };
  const STATUS = {
    DISABLED: "disabled",
    VERIFIED: "verified",
    PRE_APPROVED: "pre-approved",
    PENDING_VERIFICATION: "pending verification",
  };

  // Fetch Link Token on Component Mount
  useEffect(() => {
    setLoading(true);
    const initStripe = async () => {
      if (!stripe) {
        const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
        setStripe(stripeInstance);
      }
    };
    const fetchLinkToken = async () => {
      try {
        const response = await apiCall({
          url: "/v1/banking/create-link-token", // URL to send the POST request
          method: "POST",
          body: {
            clientId: user.clientId, // Pass userId or other necessary data
          },
        });
        setLinkToken(response?.data?.link_token);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching link token:", error);
      }
    };
    initStripe();

    fetchLinkToken(); // Auto-fetch link token when component mounts
  }, [url, user]);

  const updateAccountInfo = (updatedAccount) => {
    // Find the index of the account to be updated
    const updatedAccountIndex = accountInfo.findIndex(
      (account) => account?.account_id === updatedAccount?.account_id
    );

    if (updatedAccountIndex !== -1) {
      // Create a new array with the updated account
      const updatedAccountInfo = [
        ...accountInfo.slice(0, updatedAccountIndex),
        updatedAccount,
        ...accountInfo.slice(updatedAccountIndex + 1),
      ];

      // Update the accountInfo state with the new array
      setAccountInfo(updatedAccountInfo);
    }
  };

  const onSuccess = async (public_token) => {
    try {
      setLoading(true);  // Set loading to true

      const response = await apiCall({
        url: "/v1/banking/complete",
        method: "POST",
        body: {
          public_token: public_token,
        },
      });
      // Store processor_token from response
      setAccountInfo(response?.data.account_info);
      // Don't automatically select an account - let user choose from the list
      // setSelectedAccount(prev => ({ ...prev, processorToken }));
      console.log(accountInfo);
    } catch (error) {
      console.error("Error during Plaid verification:", error);
    } finally {
      // Add a small delay before setting loading to false
      // This ensures the loading state is visible to the user
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleAddAccount = async () => {
    // console.log(selectedAccount);
    if (workflow === "upload" && !selectedAccount?.institution_name) {
      notification.warning({
        message: "Please enter the bank name",
      });
      return;
    }

    if (
      workflow === "upload" &&
      // selectedAccount?.routing_number &&
      selectedAccount?.routing_number?.trim().length < 9
    ) {
      notification.warning({ message: "Routing number must be 9 digits" });
      return;
    }

    if (
      workflow === "upload" &&
      selectedAccount?.routing_number !==
      selectedAccount?.routing_number_validation
    ) {
      notification.warning({ message: "Routing number does not match" });
      return;
    }

    if (
      workflow === "upload" &&
      // selectedAccount?.routing_number &&
      selectedAccount?.account_number?.trim().length < 8
    ) {
      notification.warning({
        message: "US banks account number are at least 8 digits or more",
      });
      return;
    }

    if (
      workflow === "upload" &&
      // selectedAccount?.routing_number &&
      !selectedAccount?.accountHolderType
    ) {
      notification.warning({
        message: "Selecte Account Type",
      });
      return;
    }
    if (
      workflow === "upload" &&
      selectedAccount?.account_number !==
      selectedAccount?.account_number_validation
    ) {
      notification.warning({ message: "Account number does not match" });
      return;
    }

    if (!selectedAccount?.accountNickname) {
      notification.warning({
        message: "Give the account a nickname to show in the dropdowns",
      });
      return;
    }

    if (workflow === "upload" && !selectedAccount?.accountNickname) {
      notification.warning({
        message: "Please enter a nickname for the account",
      });
      return;
    }
    if (!selectedAccount?.ownerName) {
      notification.warning({
        message:
          "Please enter the name of the owner of the account as it will show on top left of the check",
      });
      return;
    }
    if (!selectedAccount?.addressLine1) {
      notification.warning({
        message:
          "Please enter the address for the owner of the account as it will show on top left of the check",
      });
      return;
    }
    if (!selectedAccount?.addressLine2) {
      notification.warning({
        message:
          "Please enter the city, state and zip for the owner of the account as it will show on top left of the check",
      });
      return;
    }
    // if (workflow === "upload" && !selectedAccount?.verificationFile) {
    //   notification.warning({
    //     message:
    //       "Please upload a picture of a void check OR a bank document showing: Account owner name, routing, account number ",
    //   });
    //   return;
    // }
    setLoading(true);
    const verificationFile = selectedAccount?.verificationFile || "";
    delete selectedAccount?.verificationFile;

    if (workflow !== VERIFICATION_TYPE.PLAID) {
      selectedAccount.mask = selectedAccount?.account_number.slice(0, -4);
    }
    // Ensure selectedAccount.signature is explicitly true or false
    const updatedAccount = {
      ...selectedAccount,
      signature: !!selectedAccount?.signature, // Ensure it's a boolean
    };

    try {
      // Encrypt data without processor token
      const encryptFunction = httpsCallable(functions, "encryptData");
      const {
        data: { encryptedData },
      } = await encryptFunction({
        plainText: JSON.stringify(updatedAccount),
      });

      let stripeAccountId = null;

      //specific logic - MOVED BEFORE FIREBASE SAVE
      if (workflow === VERIFICATION_TYPE.UPLOAD) {
        try {
          console.log('selected account', selectedAccount)
          //create a bank token
          const token = await createBankToken({
            routingNumber: selectedAccount.routing_number,
            accountNumber: selectedAccount.account_number,
            accountHolderName: selectedAccount.ownerName,
            accountHolderType: selectedAccount.accountHolderType,
          });

          if (!token) {
            notification.error({ message: "Failed to create bank token" });
            return;
          }

          if (!user.clientData.stripeCustomerId) {
            //TODO: Test if customer gets created in case they don't have one
            console.log('creating new customer')
            const response = await apiCall({
              url: `/v1/payments/stripe/customer`,
              method: 'POST',
              body: {
                email: user.email,
                name: user.clientData.companyName || user.displayName,
                clientId: user.clientId,
              }
            });
            if (!response.data.success) {
              throw new Error('Failed to create Stripe customer');
              return;
            }
            user.clientData.stripeCustomerId = response.data.customer.id;
          }

          //create a bank account
          const bankAccountResponse = await apiCall({
            url: `/v1/payments/stripe/${user.clientData.stripeCustomerId}/bank-account`,
            method: 'POST',
            body: {
              bankAccountToken: token.id,
            }
          });


          console.log('bankAccountResponse', bankAccountResponse.data.bankAccount)
          stripeAccountId = bankAccountResponse.data.bankAccount.id;


          metaAccountVerificationUpsert(user, {
            ...selectedAccount,
            verificationFile,
          });
        } catch (e) {
          console.log(e.message);
          notification.error({ message: "Error setting up bank account: " + e.message });
          setLoading(false);
          return;
        }
      }

      // Add doc with processor token at top level - MOVED AFTER SPECIFIC LOGIC
      const userDocRef = doc(db, "clients", user.clientId);
      const accountsCollectionRef = collection(userDocRef, "accounts");
      let status = STATUS.VERIFIED;
      if (workflow === VERIFICATION_TYPE.UPLOAD) {
        status = STATUS.PENDING_VERIFICATION;
      } else if (workflow === VERIFICATION_TYPE.MICRODEPOSITS) {
        status = STATUS.PENDING_VERIFICATION;
      }

      const accId = await addDoc(accountsCollectionRef, {
        encryptedAccount: encryptedData,
        createdAt: serverTimestamp(),
        isDeleted: false,
        isDisabled: status === STATUS.PENDING_VERIFICATION ? true : false,
        verificationFile: verificationFile,
        verificationType: workflow,
        ownerName: selectedAccount.ownerName,
        status,
        accountNickname: updatedAccount?.accountNickname,
        // Add the Stripe account ID if available
        ...(stripeAccountId && { stripeAccountId }),
      });

      setSelectedAccount({ ...updatedAccount, id: accId.id });
      message.success({ content: "Account added successfully" });

      // Optionally update the local state
      updateAccountInfo({ ...updatedAccount, added: true });
      // console.log(user)

      const clientRef = doc(db, "clients", user.clientId); // Reference to the client document
      await setDoc(
        clientRef,
        {
          verifications: {
            bank: true,
            bankStatus: "completed",
          },
        },
        { merge: true } // Use merge to update only verifications.sms
      );
      //update the user without refreshing
      setUser({
        ...user,
        clientData: {
          ...user.clientData,
          verifications: {
            ...user.clientData.verifications,
            bank: true,
            bankStatus: "completed",
          },
        },
      });

      //continue setting flags
      setSelectedAccount(null);
      setSignatureModalOpen(false);
      setValidated(true);
      setDone(true);
      setFileList([]);
      setWorkflow("");
      // navigate('/settings/bank-accounts')
      onCancel();
      setAccountInfo([]);
    } catch (error) {
      message.error({ content: "Failed to add account. Please try again." });
      console.error("Error in adding account:", error);
    } finally {
      setLoading(false);
    }
  };

  const createBankToken = async (bankDetails) => {
    if (!stripe) {
      notification.error({ message: "Stripe is not initialized" });
      return null;
    }

    const { token, error } = await stripe.createToken("bank_account", {
      country: "US",
      currency: "usd",
      routing_number: bankDetails.routingNumber,
      account_number: bankDetails.accountNumber,
      account_holder_name: bankDetails.accountHolderName,
      account_holder_type: bankDetails.accountHolderType,
    });

    if (error) {
      notification.error({ message: error.message });
      return null;
    }

    return token;
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onExit: () => {
      setAccountInfo([]);
      setWorkflow("");
      setSelectedAccount(null);
    },
  });

  // const handleFileUpload = async (file) => {
  //   setLoading(true);
  //   const fileName = `accountValidation_${uuidv4()}_${file.name}`;
  //   const storageRef = ref(
  //     storage,
  //     `verification/${user.clientId}/${fileName}`
  //   );
  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       const progress =
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       setFileUploadProgress(progress);
  //     },
  //     (error) => {
  //       message.error({ content: "Upload failed. Please try again." });
  //       setLoading(false);
  //     },
  //     async () => {
  //       const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
  //       message.success({ content: "File uploaded successfully." });
  //       setFileList([
  //         { name: file.name, url: downloadURL, cloudFileName: fileName },
  //       ]);

  //       setSelectedAccount({
  //         ...selectedAccount,
  //         verificationFile: fileName,
  //       });
  //       setUploadSuccess(true);
  //       setLoading(false);
  //     }
  //   );
  // };

  // const handleFileRemove = async (file) => {
  //   setLoading(true);
  //   try {
  //     // Ensure you're using the correct non-root reference by using the saved firebasePath
  //     if (!file.cloudFileName) {
  //       throw new Error("Invalid file path for deletion.");
  //     }
  //     // Recreate the reference to the file using its full path stored in firebasePath
  //     const fileRef = ref(
  //       storage,
  //       `verification/${user.clientId}/${file.cloudFileName}`
  //     );

  //     // Delete the file from Firebase Storage
  //     await deleteObject(fileRef);

  //     // Update the file list state by removing the file
  //     setFileList([]);
  //     setSelectedAccount({ ...selectedAccount, verificationFile: null });
  //     message.success({ content: `File removed successfully.` });
  //   } catch (error) {
  //     message.error({ content: `Failed to remove file` });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleEncrypt = async () => {
    try {
      const encryptFunction = httpsCallable(functions, "encryptData");
      const encrypted = await encryptFunction({
        plainText: JSON.stringify(selectedAccount),
      });

      setTest(encrypted.data.encryptedData);
    } catch (error) {
      console.error("Error encrypting data:", error);
    }
  };

  const handleDecrypt = async () => {
    const decryptFunction = httpsCallable(functions, "decryptData");
    const decrypted = await decryptFunction({
      encryptedText: test,
    });

    const rehydrated = JSON.parse(decrypted.data.plainText);
    setTest(rehydrated);
  };

  // When a user clicks "Review Required" button for an account
  const handleSelectAccount = (account) => {
    // Add the processor token to the selected account
    setSelectedAccount({
      ...account,
      // Use the processorToken from the account if available
      // or keep it undefined if not available
    });
  };

  return (
    <div className="banking-verification-container">
      <Row gutter={[24, 24]} style={{ marginTop: 30 }}>
        {(workflow === "" || (workflow === "plaid" && !selectedAccount)) && (
          <Col xs={24} sm={24}>
            <Row
              className="bg-success-light"
              style={{
                borderRadius: 12,
                padding: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 24
              }}
            >
              <BankOutlined className="success" style={{ fontSize: "4rem" }} />
            </Row>
            <Row >
              {accountInfo.length === 0 && workflow !== "plaid" && (
                <>
                  <Col xs={24} style={{ textAlign: "center" }}>
                    <div
                      style={{
                        // width: "100%",
                        padding: "24px",
                        marginBottom: 24,
                        border: "1px solid #f0f0f0",
                        borderRadius: "12px",
                        transition: "all 0.3s ease",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)"
                      }}
                      className="hover-effect"
                    >
                      <Typography.Title level={4} style={{ marginTop: 0 }}>
                        Option 1 - Instant Verification With Plaid
                      </Typography.Title>
                      <Tag color="success" style={{ padding: "4px 12px", borderRadius: "4px", marginBottom: 12 }}>
                        Instant Validation
                      </Tag>
                      <Typography.Paragraph style={{ marginTop: 16, fontSize: "16px" }}>
                        Authenticate with the bank you intend to print checks for.
                      </Typography.Paragraph>
                      <div>
                        {loading ? (
                          <Spin size="large" />
                        ) : (
                          <Button
                            className="button-blue"
                            size="medium"
                            onClick={() => {
                              setWorkflow(`plaid`);
                              open();
                            }}
                            disabled={!ready || loading}
                            style={{
                              borderRadius: "6px",
                              // height: "44px",
                              marginTop: 8
                            }}
                          >
                            <LoginOutlined /> Authenticate With Bank
                          </Button>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} style={{ textAlign: "center" }}>
                    <div
                      style={{
                        // width: "100%",
                        padding: "24px",
                        border: "1px solid #f0f0f0",
                        borderRadius: "12px",
                        transition: "all 0.3s ease",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)"
                      }}
                      className="background-lightGray hover-effect"
                    >
                      <Typography.Title level={4} style={{ marginTop: 0 }}>
                        Option 2 - Manual Entry
                      </Typography.Title>
                      <Tag color="warning" style={{ padding: "4px 12px", borderRadius: "4px", marginBottom: 12 }}>
                        1 to 2 business days
                      </Tag>
                      <Typography.Paragraph style={{ marginTop: 16, fontSize: "16px" }}>
                        Manually enter your bank information. We will make two small deposits to your account and you will need to verify the amounts in the next couple of days.
                      </Typography.Paragraph>
                      <Button
                        className="button-blue"
                        size="medium"
                        onClick={() => {
                          setAccountInfo([]);
                          setWorkflow(`upload`);
                        }}
                        style={{
                          borderRadius: "6px",
                          // height: "44px",
                          marginTop: 8
                        }}
                      >
                        <EditOutlined /> Manual Account Verification
                      </Button>
                    </div>
                  </Col>
                </>
              )}
              {accountInfo.length === 0 && workflow === "plaid" && (
                <div style={{ marginTop: -20, width: "100%" }}>
                  <Typography.Title
                    className="text-gray"
                    level={4}
                    style={{ marginBottom: 20 }}
                  >
                    Loading Accounts...
                  </Typography.Title>
                  <div style={{
                    background: "#f9f9f9",
                    borderRadius: "8px",
                    padding: "16px",
                    marginBottom: "16px"
                  }}>

                  </div>

                </div>
              )}
              {accountInfo.length > 0 && (
                <div style={{ marginTop: -20, width: "100%" }}>
                  <Typography.Title
                    className="text-gray"
                    level={4}
                    style={{ marginBottom: 20 }}
                  >
                    Available Accounts:
                  </Typography.Title>
                  <div style={{
                    background: "#f9f9f9",
                    borderRadius: "8px",
                    padding: "16px",
                    marginBottom: "16px"
                  }}>
                    {!loading ? (
                      accountInfo.map((account, index) => (
                        <React.Fragment key={index}>
                          <Row gutter={16} align="middle">
                            <Col xs={18} style={{ textAlign: "left" }}>
                              <Typography.Text style={{ fontSize: "16px" }}>
                                {(account.nickname || account.name) +
                                  " - " +
                                  account.mask}
                              </Typography.Text>
                            </Col>
                            <Col xs={6}>
                              {account.added ? (
                                <CheckCircleOutlined className="success" style={{ fontSize: "20px" }} />
                              ) : (
                                <Button
                                  className="button-yellow"
                                  style={{
                                    marginLeft: "-40px",
                                    borderRadius: "6px"
                                  }}
                                  onClick={() => handleSelectAccount(account)}
                                >
                                  <ExclamationCircleOutlined /> Review Required
                                </Button>
                              )}
                            </Col>
                          </Row>
                          {index < accountInfo.length - 1 && (
                            <Divider style={{ margin: "12px 0" }} />
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      // Loading skeleton for accounts
                      <>
                        {[1, 2].map((item) => (
                          <div key={item} style={{ marginBottom: "16px" }}>
                            <Spin size="small" style={{ marginRight: "12px" }} />
                            <div style={{
                              display: "inline-block",
                              width: "70%",
                              height: "20px",
                              background: "#f0f0f0",
                              borderRadius: "4px"
                            }}></div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  {/* <Button
                    className="button-gray"
                    style={{
                      marginTop: 16,
                      borderRadius: "6px"
                    }}
                    onClick={() => {
                      setSelectedAccount(null);
                      setWorkflow("");
                      setAccountInfo([]);
                    }}
                    disabled={loading}
                  >
                    <LeftOutlined />
                    Back
                  </Button> */}
                </div>
              )}
            </Row>

          </Col>
        )}
        {/* Card for showing selected account details */}
        <Col xs={24} sm={24}>
          {selectedAccount && workflow === `plaid` && (
            <Card
              style={{
                height: "600px",
                borderRadius: "12px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
                overflowY: "auto",
                height: "720px"

              }}
              title={<Typography.Title level={4} style={{ margin: 0 }}>Account Details</Typography.Title>}
              type="inner"
              bordered={false}
            >
              {/* <Button
                className="button-gray"
                style={{
                  marginBottom: 20,
                  borderRadius: "6px"
                }}
                onClick={() => {
                  setSelectedAccount(null);
                }}
                loading={loading}
              >
                <LeftOutlined />
                Back
              </Button> */}
              <Row gutter={[16, 24]}>
                <Col xs={24}>
                  <div style={{
                    background: "#f9f9f9",
                    padding: "16px",
                    borderRadius: "8px",
                    // marginBottom: "16px"
                  }}>
                    <Typography.Paragraph style={{ fontSize: "16px", margin: "8px 0" }}>
                      <strong>Institution Name:</strong> {selectedAccount?.institution_name}
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ fontSize: "16px", margin: "8px 0" }}>
                      <strong>Account Name:</strong> {selectedAccount?.name}
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ fontSize: "16px", margin: "8px 0" }}>
                      <strong>Routing Number:</strong> {selectedAccount?.routing_number}
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ fontSize: "16px", margin: "8px 0" }}>
                      <strong>Account Number:</strong> {selectedAccount?.account_number}
                    </Typography.Paragraph>
                  </div>
                </Col>
                <Col xs={24}>
                  <div>
                    <Typography.Text style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Nickname for Users to Identify this Account
                    </Typography.Text>
                    <Input
                      value={selectedAccount?.accountNickname}
                      placeholder="Account Nickname: BAC Checking - 1234"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          accountNickname: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                {/* <Divider style={{ margin: "8px 0 16px 0" }} /> */}
                <Col xs={24}>
                  <div style={{ marginBottom: "12px" }}>
                    <Typography.Text style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Name of Owner of the Bank Account
                    </Typography.Text>
                    <Input
                      value={selectedAccount?.ownerName}
                      placeholder="Business or Individual Name"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px", marginBottom: "12px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          ownerName: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div style={{}}>
                    <Typography.Text style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Address of the Bank Account Owner
                    </Typography.Text>
                    <Input
                      value={selectedAccount?.addressLine1}
                      placeholder="123 Main St #200 (Address Line 1)"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px", marginBottom: "12px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          addressLine1: e.target.value,
                        });
                      }}
                    />
                    <Input
                      value={selectedAccount?.addressLine2}
                      placeholder="Miami, Florida, 33076 (Address Line 2)"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          addressLine2: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Modal
                  open={signatureModalOpen}
                  onClose={() => setSignatureModalOpen(false)}
                  footer={null}
                  maskClosable={false}
                  closeIcon={null}
                >
                  <SignaturePad
                    isOpen={signatureModalOpen}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    onClose={() => setSignatureModalOpen(false)}
                    renderPad={signatureModalOpen}
                  />
                </Modal>
                <Col xs={24}>
                  <Button
                    block
                    style={{
                      height: "44px",
                      borderRadius: "6px",
                      fontSize: "16px"
                    }}
                    className="button-green"
                    onClick={handleAddAccount}
                    disabled={loading}
                    loading={loading}
                  >
                    Add Account
                  </Button>
                </Col>
              </Row>
            </Card>
          )}
          {workflow === `upload` && (
            <Card
              style={{
                height: "820px",
                overflowY: "auto",
                borderRadius: "12px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)"
              }}
              bordered={false}
            >
              <Row gutter={[16, 24]}>
                <Col xs={24}>
                  {/* <Button
                    className="button-gray"
                    style={{
                      marginBottom: 20,
                      borderRadius: "6px"
                    }}
                    onClick={() => {
                      setSelectedAccount(null);
                      setWorkflow("");
                    }}
                    loading={loading}
                  >
                    <LeftOutlined />
                    Back
                  </Button> */}
                  <div>
                    <Typography.Text style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Name of the Bank or Financial Institution
                    </Typography.Text>
                    <Input
                      value={selectedAccount?.institution_name || ""}
                      placeholder="Example: Bank of America N/A"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px", }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          institution_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col xs={24}>
                  <div>
                    <Typography.Text style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Account Type:
                    </Typography.Text>
                    <Select
                      value={selectedAccount?.accountHolderType}
                      placeholder="Select Account Holder Type"
                      required={true}
                      size="large"
                      style={{ width: "100%", borderRadius: "6px" }}
                      onChange={(value) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          accountHolderType: value,
                        });
                      }}
                    >
                      <Select.Option value="individual">Individual</Select.Option>
                      <Select.Option value="company">Company</Select.Option>
                    </Select>
                  </div>
                </Col>
                <Col xs={24}>
                  <div>
                    <Typography.Text style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Routing Number
                    </Typography.Text>
                    <Input
                      value={selectedAccount?.routing_number || ""}
                      placeholder="Routing Number"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px", marginBottom: "12px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          routing_number: e.target.value,
                        });
                      }}
                    />
                    <Input
                      value={selectedAccount?.routing_number_validation || ""}
                      placeholder="Validate Routing Number"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          routing_number_validation: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col xs={24}>
                  <div>
                    <Typography.Text style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Account Number
                    </Typography.Text>
                    <Input
                      value={selectedAccount?.account_number || ""}
                      placeholder="Account Number"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px", marginBottom: "12px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          account_number: e.target.value,
                        });
                      }}
                    />
                    <Input
                      value={selectedAccount?.account_number_validation || ""}
                      placeholder="Validate Account Number"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          account_number_validation: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col xs={24}>
                  <div>
                    <Typography.Text style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Nickname for Users to Identify this Account
                    </Typography.Text>
                    <Input
                      value={selectedAccount?.accountNickname || ""}
                      placeholder="Example: Chase Checking - 1234"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          accountNickname: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                {/* <Divider style={{ margin: "16px 0" }} > Check Top Left Printing Information </Divider> */}
                <Col xs={24}>
                  <div style={{ marginBottom: "12px" }}>
                    <Typography.Text style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Name of Owner of the Bank Account
                    </Typography.Text>
                    <Input
                      value={selectedAccount?.ownerName}
                      placeholder="Business or Account Owner Name"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px", marginBottom: "12px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          ownerName: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "12px" }}>
                    <Typography.Text style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Address of the Bank Account Owner
                    </Typography.Text>
                    <Input
                      value={selectedAccount?.addressLine1}
                      placeholder="123 Main St #200 (Address Line 1)"
                      required={true}
                      size="large"
                      style={{ borderRadius: "6px" }}
                      onChange={(e) => {
                        setSelectedAccount({
                          ...selectedAccount,
                          addressLine1: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <Input
                    value={selectedAccount?.addressLine2}
                    placeholder="Miami, Florida, 33076 (Address Line 2)"
                    required={true}
                    size="large"
                    style={{ borderRadius: "6px" }}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        addressLine2: e.target.value,
                      });
                    }}
                  />
                </Col>
                {/* <Divider style={{ margin: "16px 0" }} />
                <Col xs={24}>
                  <div style={{
                    background: "#f9f9f9",
                    padding: "16px",
                    borderRadius: "8px",
                    marginBottom: "16px"
                  }}>
                    <Typography.Text style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "8px"
                    }}>
                      Upload
                    </Typography.Text>
                    <Typography.Text style={{
                      fontSize: "14px",
                      display: "block",
                      marginBottom: "16px"
                    }}>
                      Upload a picture of a <strong>void check</strong> OR a bank
                      document showing: Account owner name, routing, account number
                    </Typography.Text>
                    <Upload
                      beforeUpload={(file) => {
                        handleFileUpload(file);
                        return false;
                      }}
                      fileList={fileList}
                      maxCount={1}
                      onRemove={(file) => {
                        handleFileRemove(file);
                        return false;
                      }}
                      disabled={loading}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        loading={loading}
                        size="large"
                        style={{ borderRadius: "6px" }}
                      >
                        Upload Document
                      </Button>
                    </Upload>
                  </div>
                </Col> */}
                {/* {fileUploadProgress > 0 && fileUploadProgress < 100 && (
                  <Col xs={24}>
                    <div style={{
                      background: "#e6f7ff",
                      padding: "12px",
                      borderRadius: "6px",
                      marginBottom: "16px"
                    }}>
                      <Typography.Text>
                        Upload Progress: {Math.round(fileUploadProgress)}%
                      </Typography.Text>
                    </div>
                  </Col>
                )} */}
                <Modal
                  open={signatureModalOpen}
                  onClose={() => setSignatureModalOpen(false)}
                  footer={null}
                  maskClosable={false}
                  closeIcon={null}
                >
                  <SignaturePad
                    isOpen={signatureModalOpen}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    onClose={() => setSignatureModalOpen(false)}
                    renderPad={signatureModalOpen}
                  />
                </Modal>
                <Col xs={24}>
                  <Button
                    block
                    style={{
                      marginTop: 24,
                      height: "44px",
                      borderRadius: "6px",
                      fontSize: "16px"
                    }}
                    className="button-green"
                    onClick={handleAddAccount}
                    loading={loading}
                  >
                    Add Account & Send Verification Deposits
                  </Button>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
        <Col xs={18}>
          <Typography.Paragraph className="success" style={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px"
          }}>
            <LockOutlined style={{ marginRight: 8 }} /> This information is secure and encrypted
          </Typography.Paragraph>
        </Col>
        <Col xs={3} style={{ textAlign: "right" }}>
          {accountInfo && (
            <Button
              type="default"
              style={{ borderRadius: "6px" }}
              loading={loading}
              onClick={() => {
                setAccountInfo([]);
                setWorkflow("");
                setSelectedAccount(null);
                setDone(false);
              }}
            >
              Start Over
            </Button>
          )}
        </Col>
        <Col xs={3} style={{ textAlign: "right" }}>
          {done && !accountInfo && (
            <Button
              className="button-blue"
              style={{ borderRadius: "6px" }}
              onClick={() => {
                setAccountInfo([]);
                setWorkflow(null);
                setSelectedAccount(null);
                setDone(false);
                onCancel();
              }}
            >
              Close
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default BankingVerification;
