// src/components/Login.js
import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Space,
  notification,
  Card,
  Divider,
} from "antd";
import { EditOutlined, GoogleOutlined, LoginOutlined } from "@ant-design/icons";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { lastLogin } from "../../services/lastLogin/lastLogin";
import axios from "axios";

const { Title } = Typography;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const LANDING_PAGE = process.env.REACT_APP_STARTING_PAGE;

  const { user, checkTokenAge } = useAuth;

  useEffect(() => {
    // onAuthStateChanged(auth, (user) => {
    //   if (user) {
    //     // notification.success({ message: "User is already logged in" });
    //     // Redirect or other actions
    //     navigate("/");
    //   }
    // });
    if (user) {
      checkTokenAge();
    }
  }, [user]);

  const onFinish = async (values) => {
  
    setLoading(true);
    //warm up serverless functions
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/warmup`);
    try {
      const result = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = result.user;
      const uid = user.uid;

      // Refresh token to get latest custom claims
      await user.getIdToken(true);
      const idTokenResult = await user.getIdTokenResult();
      const clientId = idTokenResult.claims.clientId;

      // Record last login
      await lastLogin(clientId, uid);

      window.location.href = LANDING_PAGE;
    } catch (error) {
      notification.error({
        message: "Error signing in - password or email incorrect",
      });
    } finally {
      setLoading(false);
    }
  };

  const loginWithGoogle = async () => {
    
       //warm up serverless functions
       axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/warmup`);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const uid = user.uid;
      await user.getIdToken(true);
      const idTokenResult = await user.getIdTokenResult();
      const clientId = idTokenResult.claims.clientId;

      console.log(idTokenResult)

      await lastLogin(clientId, uid);

      window.location.href = LANDING_PAGE;
    } catch (error) {
      console.error("Login with Google Error:", error.message);
    }
  };
  //todo: copy the style of the input and move to css so applies across the board
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "linear-gradient(135deg, #f5f7fa 0%, #e4ecf7 100%)",
        padding: "20px",
      }}
    >
      <Card
        style={{
          width: 450,
          borderRadius: 16,
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
          overflow: "hidden",
          border: "none",
        }}
      >
        <div
          className="bg-blue shadow"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            borderRadius: 10
          }}
        >
          <img
            src="images/logo/logo.svg"
            alt="logo"
            height="150"
            width="300px"
          />
        </div>

        <Form
          name="login"
          onFinish={onFinish}
          layout="vertical"
          size="large"
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Email is required" }]}
          >
            <Input
              placeholder="Email"
              style={{
                borderRadius: "10px",
                padding: "12px 16px",
                height: "auto",
                backgroundColor: "#f8fafc",
                border: "1px solid #e2e8f0",
                fontSize: "15px"
              }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <Input.Password
              placeholder="Password"
              style={{
                borderRadius: "10px",
                padding: "12px 16px",
                height: "auto",
                backgroundColor: "#f8fafc",
                border: "1px solid #e2e8f0",
                fontSize: "15px"
              }}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "right", marginTop: "-15px" }}>
            <Link to="/forgot-password" style={{ color: "#3b82f6", fontSize: "14px", fontWeight: "500" }}>
              Forgot Password?
            </Link>
          </Form.Item>
          <Form.Item style={{ marginTop: "20px" }}>
            <Button
              htmlType="submit"
              className="button-blue"
              loading={loading}
              block
              style={{
                height: "auto",
                padding: "12px 0",
                borderRadius: "10px",
                fontWeight: "500",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                fontSize: "15px"
              }}
            >
              <LoginOutlined /> Login With Email
            </Button>
          </Form.Item>
        </Form>

        <div style={{ position: "relative", margin: "24px 0" }}>
          <Divider plain style={{ fontSize: "14px", color: "#64748b" }}>
            or continue with
          </Divider>
        </div>

        <Space direction="vertical" style={{ width: "100%" }}>
          <Button
            icon={
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google logo"
                style={{ height: "18px", marginRight: "10px" }}
              />
            }
            onClick={loginWithGoogle}
            loading={loading}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "10px",
              padding: "12px 0",
              height: "auto",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              fontSize: "15px",
              fontWeight: "500",
              background: "#ffffff",
              color: "rgba(0,0,0,0.54)",
              border: "1px solid #dadce0"
            }}
          >
            <span>Sign in with Google</span>
          </Button>

          <div style={{ textAlign: "center", marginTop: "30px", marginBottom: "10px" }}>
            <Typography.Text style={{ color: "#64748b", fontSize: "15px" }}>
              Don't have an account?
            </Typography.Text>
            <Link to="/register">
              <Button
                style={{
                  border: "none",
                  background: "transparent",
                  boxShadow: "none",
                  color: "#3b82f6",
                  fontWeight: "600",
                  padding: "0 8px",
                  height: "auto",
                  fontSize: "15px"
                }}
              >
                Sign Up Here
              </Button>
            </Link>
          </div>
        </Space>
      </Card>
    </div>
  );
};

export default Login;
