import React from 'react';
import { Form, Select, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const StepTwo = ({setCurrentStep}) => {
  const navigate = useNavigate();

  const handleNewTemplate = () => {
    navigate('/settings/templates');
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Select Template">
        <Select>
          {/* Assume options are populated from a Firebase query */}
        </Select>
      </Form.Item>
      <Button type="link" onClick={handleNewTemplate}>
        Create New Template
      </Button>
      <Form.Item>
        <Button className="button-blue" onClick={() => setCurrentStep(2)}>Next</Button>
      </Form.Item>
    </Form>
  );
};

export default StepTwo;
