import { Button, Typography, Avatar, Layout, notification } from "antd";
import {  CreditCardOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { getInitialsFullName } from "../utils/functions/strings";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";


const { Header } = Layout;
const { Text } = Typography;


function TopHeader({ collapsed, setCollapsed, isMobile }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  // const checkTrialExpiration = async () => {
  //   if (user?.clientData?.subscription === "trial") {
  //     const expired = await isTrialExpired(
  //       user.clientId,
  //       user.clientData.trialExpiresOn.toDate()
  //     );
  //     if (expired) {
  //       navigate("/subscription");
  //     }
  //   }
  // }
  function checkUserDisabled() {
    if (user?.clientData?.disabled || user?.clientData?.hasAccess === false) {
      notification.warning({
        message:
          "You account has been disabled. Please contact support at contact@simple-checks.com",
      });
      navigate("/logout");
    }
  }

  async function checkOnboarding() {

    if(user?.role === 'admin' && (user?.clientData?.subscription === 'trial' || user?.clientData?.verifications?.email === false || user?.clientData?.verifications?.sms === false)) {
      navigate("/onboarding");
    }
    
    //this checks onboarding for invited users 
    if (user && !user.clientId) {
      // Process invitation onboarding or normal onboarding
      const invitesCollection = collection(db, "invites");
      const q = query(
        invitesCollection,
        where("email", "==", user.email)
      );

      const inviteSnap = await getDocs(q);
      if (!inviteSnap.empty) {
        navigate("/invitation-onboarding");
      } else {
        navigate("/onboarding");
      }

    }
    
  }





  async function checkMarkedForDeletion() {
    if (user?.clientData?.markedForDeletion) {
      //update client and remove marked for deletion 
      await updateDoc(doc(db, "clients", user.clientId), {
        markedForDeletion: false,
        markedForDeletionDate: null,
        markedForDeletionUser: null
      });
    }
  }

  const checkIdentityVerification = async () => {
    if (user?.clientData?.idVerificationRequired && !user?.clientData?.verifications?.identity &&
      (user?.clientData?.verifications?.identityMeta?.status !== 'completed' &&
        user?.clientData?.verifications?.identityMeta?.status !== 'processing' &&
        user?.clientData?.verifications?.identityMeta?.status !== 'pending')) {
      navigate("/verify-identification");

    }
  }

  useEffect(() => {
    // checkTrialExpiration();
    checkIdentityVerification();
    checkUserDisabled();
    checkOnboarding();
    checkMarkedForDeletion();
  }, [user]);





  return (
    <Header
      className="top-header"
      style={{
        position: "fixed",
        top: 0,
        left: isMobile ? 0 : collapsed ? "80px" : "240px",
        width: isMobile ? "100%" : `calc(100% - ${collapsed ? "80px" : "240px"})`,
        transition: "left 0.2s, width 0.2s",
        zIndex: 1000,
        padding: "0 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        // background: "linear-gradient(90deg, #001529 0%, #002140 100%)"
      }}
    >
      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{ 
          fontSize: "16px", 
          width: 48, 
          height: 48, 
          color: "white",
          border: "none",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      />
      
      <div style={{ 
        display: "flex", 
        alignItems: "center", 
        gap: "16px"
      }}>
        {user?.trialExpired && (
          <Link to="/settings/subscription">
            <Button 
              className="button-blue"
              danger 
              style={{
                borderRadius: "6px",
                display: "flex",
                alignItems: "center",
                gap: "6px",
                height: "36px"
              }}
            >
              <CreditCardOutlined /> Trial Expired
            </Button>
          </Link>
        )}
        
        <div style={{ 
          display: "flex", 
          alignItems: "center", 
          background: "rgba(255, 255, 255, 0.08)",
          padding: "6px 12px",
          borderRadius: "8px",
          gap: "12px"
        }}>
          <Text style={{ 
            color: "white", 
            fontSize: "15px",
            fontWeight: "500",
            margin: 0
          }}>
            {user && user.displayName}
          </Text>
          
          {user?.photoUrl ? (
            <Avatar 
              src={user.photoUrl} 
              size={40} 
              style={{ 
                border: "2px solid rgba(255, 255, 255, 0.2)",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)"
              }}
            />
          ) : (
            <Avatar 
              size={40} 
              style={{ 
                background: "linear-gradient(135deg, #1890ff 0%, #096dd9 100%)",
                border: "2px solid rgba(255, 255, 255, 0.2)",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div>
                {getInitialsFullName(user?.displayName || "N/A")}
              </div>
            </Avatar>
          )}
        </div>
      </div>
    </Header>
  );
}

export default TopHeader;
