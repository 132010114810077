import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  Typography,
  notification,
  Alert,
} from "antd";
import { useAuth } from "../../../contexts/AuthContext";
import {
  MailOutlined,
  MobileOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function EmailSmsVerification({ previousStep, nextStep }) {
  const { user, firebaseUserData, reloadUser, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailNotificationSent, setEmailNotificationSent] = useState(false);
  const [smsVerified, setSmsVerified] = useState(false);
  const [smsNotificationSent, setSmsNotificationSent] = useState(false);
  const [smsCodeInput, setSmsCodeInput] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: user.email,
    phone: user.phone,
  });
  const [emailForm] = Form.useForm(); // Separate form instance for email
  const [smsForm] = Form.useForm(); // Separate form instance for SMS
  const url = process.env.REACT_APP_API_ENDPOINT;
  const [emailCodeInput, setEmailCodeInput] = useState('');
  const emailCodeSentRef = useRef(false);
  const [phoneNumber, setPhoneNumber] = useState(user.phone);

  useEffect(() => {
    async function run() {
      await reloadUser();
      // Set initial verification states
      setEmailVerified(user.clientData?.verifications?.email || false);
      setSmsVerified(user.clientData?.verifications?.sms || false);

      // Only send email code if not already verified and hasn't been sent this session
      if (!user.clientData?.verifications?.email && !emailCodeSentRef.current) {
        emailCodeSentRef.current = true; // Mark as sent before making the call
        sendEmailCode();
      }
    }
    run();
   
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function sendEmailCode() {
    // Add guard clause to prevent duplicate sends
    if (loading) return;
    
    setLoading(true);
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/v1/auth/send-otp',
      {
        email: formData.email
      },
      {
        headers: {
          'Authorization': `Bearer ${user.accessToken}`
        }
      }
    )
      .then(async () => {
        await setEmailNotificationSent(true);
        notification.success({
          message: "Verification code sent successfully to your email"
        });
      })
      .catch((error) => {
        emailCodeSentRef.current = false; // Reset on error so user can try again
        notification.error({
          message: "Error sending verification code",
          description: error.message || "Please try again in a minute"
        });
        console.error("Error sending verification code:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const verifyEmailCode = async (notify = true) => {
    setLoading(true);

    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/v1/auth/verify-otp',
        {
          email: formData.email,
          otp: emailCodeInput.trim()
        },
        {
          headers: {
            'Authorization': `Bearer ${user.accessToken}`
          }
        }
      );

      if (response.status === 200 && response.data.success) {
        try {
          const clientRef = doc(db, "clients", user?.clientId);
          await setDoc(
            clientRef,
            {
              verifications: {
                email: true,
              },
            },
            { merge: true }
          );
          setEmailVerified(true);
          notification.success({ message: "Email verified successfully" });
        } catch (error) {
          notification.error({ message: "Error saving email verification status" });
          console.error("Error updating email verification:", error.message);
        }
      } else {
        notification.error({
          message: "Invalid verification code",
          description: "Please check the code and try again"
        });
      }
    } catch (error) {
      if (notify) {
        if (error.response?.data?.message?.toLowerCase().includes('invalid')) {
          notification.error({
            message: "Invalid OTP",
            description: "The OTP entered is invalid. Please try again."
          });
        } else {
          notification.error({
            message: "Error verifying email code",
            description: error.message || "Please try again"
          });
        }
      }
      console.error("Error verifying email code:", error);
    } finally {
      setLoading(false);
    }
  };

  async function sendSmsCode() {
    setLoading(true);

    try {
      const values = await smsForm.validateFields();
      const { phone } = values;
      setPhoneNumber(phone);

      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/v1/sms/send-verification',
        {
          phoneNumber: phone
        },
        {
          headers: {
            'Authorization': `Bearer ${user.accessToken}`
          }
        }
      );

      if (response.status === 200) {
        setSmsNotificationSent(true);
        notification.success({
          message: "SMS code sent successfully"
        });
      }
    } catch (error) {
      console.error("Error sending SMS verification:", error);
      notification.error({
        message: "Failed to send SMS code",
        description: error.message || "Please try again"
      });
    } finally {
      setLoading(false);
    }
  }

  async function verifySmsCode() {
    smsForm
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        const { smsCode } = values;

        try {
          const response = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/v1/sms/verify-code',
            {
              phoneNumber: phoneNumber,
              code: smsCode
            },
            {
              headers: {
                'Authorization': `Bearer ${user.accessToken}`
              }
            }
          );

          if (response.status === 200 && response.data.success === true) {
            setSmsVerified(true);
            notification.success({ message: "SMS Verified Successfully" });

            if (phoneNumber !== user.phone) {
              const userRef = doc(db, "users", user.uid);
              await setDoc(
                userRef,
                { phone: phoneNumber },
                { merge: true }
              );
            }

            const clientRef = doc(db, "clients", user.clientId);
            await setDoc(
              clientRef,
              {
                verifications: {
                  sms: true,
                },
              },
              { merge: true }
            );
          } else {
            notification.error({
              message: "Verification failed",
              description: "Invalid code or response",
            });
          }
        } catch (error) {
          console.error(
            "Error verifying SMS code or updating Firebase:",
            error
          );
          notification.error({
            message: "Failed to verify SMS code",
            description: error.message || "Unknown error occurred",
          });
        } finally {
          setLoading(false);
        }
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  }

  function handleComplete() {
    setUser({
      ...user,
      clientData: {
        ...user.clientData,
        verifications: {
          ...user.clientData.verifications,
          sms: true,
          email: true,
        },
      },
    });
    navigate(-1);
  }

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {/* Email Verification Column */}
        <Col xs={24} sm={12}>
          <Card style={{ height: "400px" }}>
            <Row>
              <Col xs={24} style={{ textAlign: "center", marginBottom: 24 }}>
                <MailOutlined className="info" style={{ fontSize: "2rem" }} />
                <Typography.Paragraph style={{ marginTop: 10 }}>
                  Email Verification
                </Typography.Paragraph>
              </Col>
            </Row>
            {emailVerified ? (
              <Row>
                <Col xs={24} style={{ textAlign: "center", marginBottom: 24 }}>
                  <CheckCircleOutlined
                    className="success"
                    style={{ fontSize: "3rem", marginTop: 35 }}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Form
                  form={emailForm}
                  initialValues={formData}
                  onValuesChange={(changedValues) =>
                    handleChange({
                      target: {
                        name: Object.keys(changedValues)[0],
                        value: Object.values(changedValues)[0],
                      },
                    })
                  }
                >
                  <Row gutter={16}>
                    <Col xs={24}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "please enter your email",
                          },
                        ]}
                      >
                        <Input placeholder="Email" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      {emailNotificationSent ? (
                        <>
                          <Form.Item
                            name="emailCode"
                            rules={[
                              {
                                required: true,
                                message: "Please enter verification code",
                              },
                              {
                                min: 6,
                                message: "Verification code must be at least 6 characters",
                              }
                            ]}
                          >
                            <Input
                              placeholder="Enter verification code"
                              onChange={(e) => setEmailCodeInput(e.target.value)}
                            />
                          </Form.Item>
                          <Button
                            onClick={verifyEmailCode}
                            block
                            className="button-blue"
                            disabled={loading}
                          >
                            Verify
                          </Button>
                          <Link
                            onClick={sendEmailCode}
                            style={{
                              display: "block",
                              textAlign: "center",
                              marginTop: 10,
                            }}
                          >
                            Resend email
                          </Link>
                          <Alert
                            type="warning"
                            message={`Code sent. Please check inbox, spam and junk folders.`}
                            style={{ marginTop: 15 }}
                          />
                        </>
                      ) : (
                        <Button
                          onClick={sendEmailCode}
                          block
                          className="button-blue"
                          disabled={loading}
                        >
                          Send Verification Email
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Row>
            )}
          </Card>
        </Col>

        {/* SMS Verification Column */}
        <Col xs={24} sm={12}>
          <Card style={{ height: "400px" }}>
            <Row>
              <Col xs={24} style={{ textAlign: "center", marginBottom: 24 }}>
                <MobileOutlined className="info" style={{ fontSize: "2rem" }} />
                <Typography.Paragraph style={{ marginTop: 10 }}>
                  SMS Verification
                </Typography.Paragraph>
              </Col>
            </Row>
            {smsVerified ? (
              <Row>
                <Col xs={24} style={{ textAlign: "center", marginBottom: 24 }}>
                  <CheckCircleOutlined
                    className="success"
                    style={{ fontSize: "3rem", marginTop: 35 }}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Form
                  form={smsForm}
                  initialValues={formData}
                  onValuesChange={(changedValues) =>
                    handleChange({
                      target: {
                        name: Object.keys(changedValues)[0],
                        value: Object.values(changedValues)[0],
                      },
                    })
                  }
                >
                  <Row gutter={16}>
                    <Col xs={24}>
                      {smsNotificationSent ? (
                        <Form.Item
                          name="smsCode"
                          rules={[
                            {
                              required: true,
                              message: "Enter the verification code",
                            },
                            {
                              min: 6,
                              max: 6,
                              message:
                                "Verification code must be at least 6 characters",
                            },
                          ]}
                        >
                          <Input placeholder="Verification Code" />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Enter your phone number",
                            },
                            {
                              min: 10,
                              max: 10,
                              message:
                                "Phone number must be at least 10 characters",
                            },
                          ]}
                        >
                          <Input placeholder="Mobile Number" />
                        </Form.Item>
                      )}
                    </Col>
                    <Col xs={24}>
                      {smsNotificationSent ? (
                        <>
                          <Button
                            onClick={verifySmsCode}
                            block
                            className="button-blue"
                            disabled={loading}
                          >
                            Verify
                          </Button>
                          <Link
                            onClick={sendSmsCode}
                            style={{
                              display: "block",
                              textAlign: "center",
                              marginTop: 10,
                            }}
                          >
                            Resend SMS
                          </Link>
                        </>
                      ) : (
                        <Button
                          onClick={sendSmsCode}
                          block
                          className="button-blue"
                          disabled={loading}
                        >
                          Text me the code
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 30 }}>
        <Col>
          <Typography.Link href="https://simple-checks.com/contact" target="_blank">
            <QuestionCircleOutlined style={{ marginRight: 8 }} />
            I'm having issues validating my email / sms
          </Typography.Link>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} style={{ textAlign: "right" }}>
          {nextStep ? (
            <Button
              className="button-blue"
              onClick={nextStep}
              disabled={!smsVerified || !emailVerified}
            >
              Continue
            </Button>
          ) : (
            <Button
              className="button-blue"
              onClick={handleComplete}
              disabled={!smsVerified || !emailVerified}
            >
              Complete
            </Button>
          )}
        </Col>
      </Row>

     
    </>
  );
}

export default EmailSmsVerification;
