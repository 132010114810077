import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Upload, Space, AutoComplete, message } from 'antd';
import { SendOutlined, PaperClipOutlined } from '@ant-design/icons';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

function EmailModule() {
  const [form] = Form.useForm();
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [editorContent, setEditorContent] = useState('');
  const [selectedText, setSelectedText] = useState('');
  const { user } = useAuth();

  // Modified email search handler
  const handleEmailSearch = async (value) => {
    // Get the last email in the comma-separated list
    const emails = value.split(',');
    const searchTerm = emails[emails.length - 1].trim();

    if (searchTerm.length >= 3) {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '>=', searchTerm), where('email', '<=', searchTerm + '\uf8ff'));
      const querySnapshot = await getDocs(q);
      
      const suggestions = querySnapshot.docs.map(doc => ({
        value: value.substring(0, value.lastIndexOf(',') + 1) + 
              (value.includes(',') ? ' ' : '') + 
              doc.data().email,
        label: doc.data().email
      }));
      setEmailSuggestions(suggestions);
    }
  };

  // Add email validation function
  const validateEmails = (_, value) => {
    if (!value) {
      return Promise.reject('Please enter at least one email address');
    }

    const emails = value.split(',').map(email => email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    const invalidEmails = emails.filter(email => !emailRegex.test(email));
    
    if (invalidEmails.length > 0) {
      return Promise.reject(`Invalid email(s): ${invalidEmails.join(', ')}`);
    }
    
    return Promise.resolve();
  };

  //TODO: attachment is not being received. Priority is not being received. Bold and italic are not working.

  // Simple text editor functions
  const applyStyle = (style) => {
    const textarea = document.getElementById('email-content');
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const text = textarea.value;

    let tag;
    switch (style) {
      case 'bold':
        tag = '**';
        break;
      case 'italic':
        tag = '_';
        break;
      default:
        return;
    }

    const newText = `${text.substring(0, start)}${tag}${text.substring(start, end)}${tag}${text.substring(end)}`;
    setEditorContent(newText);
  };

  // Modified form submission
  const handleSubmit = async (values) => {
    const emails = values.to.split(',').map(email => email.trim());
    
    // Create JSON payload
    const payload = {
      email: emails,
      subject: values.subject,
      htmlMessage: editorContent,
      priority: values.priority
    };

    // If there's an attachment, convert it to base64
    if (values.attachment?.fileList?.length > 0) {
      const file = values.attachment.fileList[0].originFileObj;
      const base64 = await convertFileToBase64(file);
      payload.attachment = {
        filename: file.name,
        content: base64,
        contentType: file.type
      };
    }

    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/v1/mailer`,
        data: payload,
        headers: {
          'Authorization': `Bearer ${user.accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.status === 200) {
        message.success('Email sent successfully!');
        // Reset form and editor content
        form.resetFields();
        setEditorContent('');
        setEmailSuggestions([]);
      }
    } catch (error) {
      console.error('Error sending email:', error);
      message.error('Failed to send email. Please try again.');
    }
  };

  // Helper function to convert file to base64
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div className="email-module">
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        className="email-form"
      >
        <Form.Item
          name="to"
          label="To:"
          rules={[
            { validator: validateEmails }
          ]}
        >
          <AutoComplete
            options={emailSuggestions}
            onSearch={handleEmailSearch}
            placeholder="Enter recipient emails (comma-separated)"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="subject"
          label="Subject:"
          AutoComplete={false}
          rules={[{ required: true, message: 'Please enter subject' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="priority"
          label="Priority:"
        >
          <Select defaultValue="normal">
            <Select.Option value="high">High</Select.Option>
            <Select.Option value="normal">Normal</Select.Option>
            <Select.Option value="low">Low</Select.Option>
          </Select>
        </Form.Item>

 

        <Form.Item
          name="content"
          label="Message:"
          rules={[{ required: true, message: 'Please enter message content' }]}
        >
          <Input.TextArea
            id="email-content"
            value={editorContent}
            onChange={(e) => setEditorContent(e.target.value)}
            rows={10}
          />
        </Form.Item>

        {/* <Form.Item
          name="attachment"
          label="Attachment:"
        >
          <Upload maxCount={1} beforeUpload={() => false}>
            <Button icon={<PaperClipOutlined />}>Add Attachment</Button>
          </Upload>
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<SendOutlined />}>
            Send Email
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default EmailModule;