import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Tooltip,
  Button,
  Row,
  Col,
  Tag,
  Spin,
  Checkbox,
  Upload,
  Form,
  Select,
} from "antd";
import { formatDate } from "../../../utils/functions/dates";
import { decryptAccounts } from "../../../utils/functions/encryption";
import apiCall from "../../../utils/functions/apiCall";
import { SendOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { ref, uploadBytes, deleteObject, getMetadata } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { storage } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";

const DirectDepositModal = ({
  directDepositModalOpen,
  setDirectDepositModalOpen,
  handleSendEmail,
  selectedChecksToPrint,
  emailInputs,
  setEmailInputs,
  // accounts,
}) => {
  const [form] = Form.useForm();
  const [loadingStates, setLoadingStates] = useState({});
  const [loading, setLoading] = useState(true);
  const [decAccounts, setDecAccounts] = useState([]);
  const [localEmailInputs, setLocalEmailInputs] = useState(emailInputs);
  const [smsStates, setSmsStates] = useState({});
  const [attachments, setAttachments] = useState({});
  const { user } = useAuth();

  const handleEditPayee = (payee) => {
    console.log(payee);
  }

  // useEffect(() => {
  //   const decryptAccountsData = async () => {
  //     if (accounts && accounts?.length > 0 && decAccounts?.length === 0) {
  //       try {
  //         const result = await decryptAccounts(accounts);
  //         setDecAccounts(result);
  //       } catch (error) {
  //         console.error("Error decrypting accounts:", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   };
  //   form.setFieldsValue({ startingCheckNumber });
  //   decryptAccountsData();
  // }, [accounts, decAccounts?.length]);

  useEffect(() => {
    if (selectedChecksToPrint?.length > 0) {
      const initialSmsStates = selectedChecksToPrint.reduce((acc, record) => {
        acc[record.id] = {
          smsNotify: false,
          phoneNumber: record.payee?.phoneNumber || "", // Default phone number
          disabled: false,
        };
        return acc;
      }, {});
      setSmsStates(initialSmsStates);
    }
  }, [selectedChecksToPrint]);

  const columns = [
    {
      title: "Payable To",
      dataIndex: "payableTo",
      key: "payableTo",
    },
    {
      title: "ACH Status",
      key: "achStatus",
      render: (_, record) => {
        if (!record.payee?.stripeAccountMeta || !record.payee?.stripeAccountMeta.accountTokens?.length) {
          return (
            <Button 
              type="link" 
              onClick={() => handleEditPayee(record.payee)}
              style={{ color: '#ff4d4f' }}
            >
              Missing Info
            </Button>
          );
        }
        
        return (
          <Select
            style={{ width: 200 }}
            options={record.payee.stripeAccountMeta.accountTokens.map(token => ({
              label: token.nickname,
              value: token.tokenId
            }))}
            placeholder="Select bank account"
          />
        );
      }
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    {
      title: "Fee",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    {
      title: "Total",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    { title: "Payable To", dataIndex: "payableTo", key: "payableTo" },
    {
      title: "Check Memo",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
  ];

  return (
    <Modal
      title="List of Open Payments"
      open={directDepositModalOpen}
      footer={false}
      style={{ minWidth: "90%", marginTop: -70 }}
      onCancel={() => setDirectDepositModalOpen(false)}
      maskClosable={false}
    >
      {/* <Form
        layout="vertical"
        form={form}
        style={{
          backgroundColor: "#ecf0f1",
          padding: 10,
          borderRadius: 10,
          marginBottom: 15,
        }}
      >
        <Row gutter={16}>
          <Col xs={24} lg={6}>
            <Form.Item
              label="Starting Check Number"
              value={startingCheckNumber}
              onChange={(e) => setStartingCheckNumber(e.target.value)}
              name="startingCheckNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter the starting check number",
                },
              ]}
            >
              <Input
                value={startingCheckNumber}
                onChange={(e) => setStartingCheckNumber(e.target.value)}
                placeholder="Enter starting check number"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form> */}
      <div style={{ overflowX: "auto", overflowY: "auto" }}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={selectedChecksToPrint}
          pagination={false}
        />
      </div>
      <Row style={{ marginTop: 15 }}>
        <Col span={20}></Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <Button
            className="button-red"
            onClick={() => setDirectDepositModalOpen(false)}
            disabled={Object.values(loadingStates).some((loading) => loading)}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default DirectDepositModal;
