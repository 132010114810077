import { QuestionCircleOutlined } from "@ant-design/icons";

function Help() {
  return (
    <>

      {/* <div 
      >
        <iframe
          src="https://simple-checks.com/docs?noNavBar"
          style={{
            width: "100%",
            height: "calc(100vh - 120px)", // Adjust height to account for header
            border: "none"
            
          }}
          title="Documentation"
        />
      </div> */}
    </>
  );
}

export default Help;
