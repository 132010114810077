import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Typography,
  Divider,
  Row,
  Col,
  Select,
  notification,
  Button,
} from "antd";
import { addDoc, updateDoc, doc, collection } from "firebase/firestore";
import { db } from "../../../firebase";
import { sanitizeValues } from "../../../utils/functions/strings";
import { useAuth } from "../../../contexts/AuthContext";
import TextArea from "antd/es/input/TextArea";
import { useNavigate, Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import apiCall from "../../../utils/functions/apiCall";
import { loadStripe } from "@stripe/stripe-js";

const PayeeModal = ({
  visible,
  onClose,
  isEditMode,
  payee,
  onOk,
  fetchPayees,
}) => {
  // console.log(payee)
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [nameOnCheck, setNameOnCheck] = useState(null);
  const [nameOnCheckDisabled, setNameOnCheckDisabled] = useState(true);
  const { user } = useAuth();
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (!isEditMode) {
      setNameOnCheck(null);
      setNameOnCheckDisabled(true);
      form.resetFields();
    }
    if (payee?.payableTo) setNameOnCheckDisabled(false);
    if (payee) {
      form.setFieldsValue({
        ...payee,
        address1: payee?.address?.address1 ?? null,
        address2: payee?.address?.address2 ?? null,
        city: payee?.address?.city ?? null,
        state: payee?.address?.state ?? null,
        zip: payee?.address?.zip ?? null,
        country: payee?.address?.country ?? null,
      });
    }
    // Initialize Stripe
    const initStripe = async () => {
      if (!stripe) {
        const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
        setStripe(stripeInstance);
      }
    };
    initStripe();
  }, [payee, form, isEditMode, visible]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (!values.name && !values.companyName) {
        notification.error({
          message: "You must enter either a name or a company name",
        });
        return;
      }
      const now = new Date();
      const transformedValues = {
        ...values,
        address: {
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          zip: values.zip,
          country: values.country,
        },
      };

      delete transformedValues.address1;
      delete transformedValues.address2;
      delete transformedValues.city;
      delete transformedValues.state;
      delete transformedValues.zip;
      delete transformedValues.country;

      const sanitizedValues = sanitizeValues(transformedValues);
      let newPayeeId;

      if (isEditMode && payee) {
        await updateDoc(doc(db, `clients/${user.clientId}/payees`, payee.id), {
          ...sanitizedValues,
          dateModified: now,
          lastEditedBy: { displayName: user.displayName, uid: user.uid },
        });
        newPayeeId = payee.id;
      } else {
        const docRef = await addDoc(
          collection(db, `clients/${user.clientId}/payees`),
          {
            ...sanitizedValues,
            dateAdded: now,
            dateModified: now,
            isDeleted: false,
            // clientId: user.clientId,
            lastEditedBy: { displayName: user.displayName, uid: user.uid },
          }
        );
        newPayeeId = docRef.id;
      }
      notification.success({ message: "Saved" });
      form.resetFields();
      if (fetchPayees) {
        fetchPayees();
      }

      if (onOk) {
        onOk(newPayeeId);
      }
      onClose();
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "Error performing this action",
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const populateNameToPrint = (value) => {
    if (!nameOnCheck) {
      setNameOnCheck(value);
      setNameOnCheckDisabled(false);
      form.setFieldsValue({ payableTo: value });
    }
  };

  const createBankToken = async (bankDetails) => {
    if (!stripe) {
      notification.error({ message: "Stripe is not initialized" });
      return null;
    }

    const { token, error } = await stripe.createToken("bank_account", {
      country: "US",
      currency: "usd",
      routing_number: bankDetails.routingNumber,
      account_number: bankDetails.accountNumber,
      account_holder_name: bankDetails.accountHolderName,
      account_holder_type: bankDetails.accountHolderType,
    });

    if (error) {
      notification.error({ message: error.message });
      return null;
    }

    return token;
  };

  const addBankAccount = async (values) => {
    try {
      if (!values || !values.bankName || !values.routingNumber || !values.accountNumber) {
        notification.warning({ message: "Please fill in all required fields" });
        return;
      }
      setLoading(true);

      // Create bank token
      const token = await createBankToken({
        routingNumber: values.routingNumber.trim(),
        accountNumber: values.accountNumber.trim(),
        accountHolderName: values.accountHolderName.trim(),
        accountHolderType: values.accountHolderType,
      });

      if (!token) return;

      let payeeStripeCustomerId = payee.stripeCustomerId;

      //if payee.stripeCustomerId is null, then create a new customer and grab the id
      if (!payeeStripeCustomerId) {
        console.log('creating new customer')
        const response = await apiCall({
          url: `/v1/payments/stripe/connect/${user.clientData.stripeConnect.id}/customer`,
          method: 'POST',
          body: {
            email: payee.email,
            name: payee.name || payee.companyName,
            scPayeeId: payee.id,
          }
        });
        if (!response.data.success) {
          throw new Error('Failed to create Stripe customer');
        }
        console.log('customer created', response.data);
        payeeStripeCustomerId = response.data.customer.id;
        await updateDoc(doc(db, `clients/${user.clientId}/payees`, payee.id), {
          stripeCustomerId: payeeStripeCustomerId,
        });
      }
      console.log(payeeStripeCustomerId);

      // Create nickname with bank name and last 4 digits
      const lastFourDigits = values.accountNumber.slice(-4);
      const nickname = `${values.bankName} (*${lastFourDigits})`;

      // Only store nickname and token
      const newBankAccount = {
        nickname,
        token: token.id
      };

      setBankAccounts([...bankAccounts, newBankAccount]);

      //call api to create bank account
      const response = await apiCall({
        url: `/v1/payments/stripe/connect/${user.clientData.stripeConnect.id}/${payeeStripeCustomerId}/bank-account`,
        method: 'POST',
        body: {
          bankAccountToken: token.id,
        }
      });

      //save only nickname and token to db
      await updateDoc(doc(db, `clients/${user.clientId}/payees`, payee.id), {
        bankAccounts: [...bankAccounts, newBankAccount]
      });

      form.setFieldsValue({ newBankAccount: undefined });

    } catch (error) {
      notification.error({ message: "Failed to add bank account" });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={isEditMode ? "Edit Payee" : "New Payee"}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ minWidth: "70%", marginTop: -70 }}
      maskClosable={false}
      open={visible}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          status: isEditMode ? payee?.status : "Active",
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={10}>
            <Form.Item name="companyName" label="Company Name">
              <Input onBlur={(e) => populateNameToPrint(e.target.value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={10}>
            <Form.Item
              name="payableTo"
              label="Pay To: Name To Print On Check"
              rules={[
                {
                  required: true,
                  message: "Please add name or company to enable",
                },
              ]}
            >
              <Input
                disabled={nameOnCheckDisabled}
                onBlur={(e) => setNameOnCheck(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={4}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: "Status is required" }]}
            >
              <Select>
                <Select.Option value="Active" key="active">
                  Active
                </Select.Option>
                <Select.Option value="Inactive" key="inactive">
                  Inactive
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>


        <Divider style={{ marginTop: 16, marginBottom: 16 }} > Contact Person </Divider>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item name="name" label="Name">
              <Input onBlur={(e) => populateNameToPrint(e.target.value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item name="phoneNumber" label="Phone Number">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ marginTop: 16, marginBottom: 16 }} >Mailing Address </Divider>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={18}>
            <Form.Item name="address1" label="Address">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="address2" label="Address 2">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="city" label="City">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="state" label="State">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="zip" label="Postal Code">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="country" label="Country">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* <Divider style={{ marginTop: 16, marginBottom: 16 }}>Direct Deposit Information</Divider> */}
        {/* {!user.clientData?.stripeConnect?.id ? (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <Link to="/settings/digital-transactions">
              <Button className="button-blue">Enable ACH Direct Deposit</Button>
            </Link>
          </div>
        ) : (
          <>

            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <Typography.Text strong>Stripe Customer ID: </Typography.Text>
                <Typography.Text>{payee?.stripeCustomerId || "None"}</Typography.Text>
              </Col>
            </Row>


            {bankAccounts.map((account, index) => (
              <Row key={index} gutter={16} style={{ marginBottom: 16 }}>
                <Col span={24}>
                  <Typography.Text strong>{account.nickname}</Typography.Text>
                </Col>
              </Row>
            ))}

            <Form.List name="newBankAccount">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Row key={field.key} gutter={16}>
                      <Col xs={24} sm={12} md={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'bankName']}
                          label="Bank Name"
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'accountHolderName']}
                          label="Account Holder Name"
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'accountHolderType']}
                          label="Account Type"
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Select>
                            <Select.Option value="individual">Individual</Select.Option>
                            <Select.Option value="company">Company</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'routingNumber']}
                          label="Routing Number"
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'confirmRoutingNumber']}
                          label="Confirm Routing Number"
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'accountNumber']}
                          label="Account Number"
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'confirmAccountNumber']}
                          label="Confirm Account Number"
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={6}>
                        <Form.Item label=" ">
                          <Button
                            className="button-blue"
                            onClick={() => {
                              const values = form.getFieldValue(['newBankAccount', field.name]);
                              addBankAccount(values);
                            }}
                            loading={loading}
                            style={{ marginRight: 8 }}
                          >
                            Add
                          </Button>
                          <Button
                            onClick={() => remove(field.name)}
                          >
                            Remove
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Button
                    className="button-green"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    style={{ width: '100%', marginTop: 16 }}
                  >
                    Add Bank Account
                  </Button>
                </>
              )}
            </Form.List>
          </>
        )} */}
        <Divider style={{ marginTop: 16, marginBottom: 16 }} >Optional Information </Divider>
        <Form.Item
          name="additionalInfo"
          label="Additional Information / Comments"
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PayeeModal;
