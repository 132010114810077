// src/components/ForgotPassword.js
import React, { useState } from 'react';
import { Form, Input, Button, Typography, notification, Card } from 'antd';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../firebase';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const onFinish = async (values) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, values.email);
      notification.success({duration:0,  message: 'A password reset link has been sent to your email. If you do not receive the password reset in a few moments, please check your spam folder' });
      // Redirect or other actions
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoading(false);
    navigate('/logout')
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <Card title="Forgot Password" type="inner" style={{ width: 400, borderRadius: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
        <div style={{ textAlign: 'center' }}>
          {/* <Title level={2}>Forgot Password</Title> */}
        </div>
        <Text >Please enter your email address to reset your password.</Text>
        <Form name="forgot_password" onFinish={onFinish} style={{marginTop:"15px"}}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button className="button-blue" htmlType="submit" loading={loading} block>
              Send Reset Email
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
