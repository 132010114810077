import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { List, Card, Typography, Button, Space, Tag } from "antd";
import PropTypes from "prop-types";

const { Title, Text } = Typography;

const PackageCard = ({ plan, isSubscribed, onSubscribeClick, cta, trialMessage, isCanceled }) => {
  // console.log(plan)

  const ctaMessage = cta ? plan.ctaButtonText : "Subscribe"
  return (
    <Card
      style={{
        textAlign: 'center',
        height: '100%',
        // padding: '16px 24px',
        width: '320px',
        borderRadius: '12px',
        boxShadow: plan.mostPopular 
          ? '0 4px 16px rgba(24, 144, 255, 0.2)'
          : '0 4px 12px rgba(0, 0, 0, 0.08)',
        border: plan.mostPopular ? '2px solid #1890ff' : undefined,
        transition: 'transform 0.2s, box-shadow 0.2s',
        position: 'relative',
        ':hover': {
          transform: plan.mostPopular ? 'translateY(-6px)' : 'translateY(-4px)',
          boxShadow: plan.mostPopular 
            ? '0 8px 20px rgba(24, 144, 255, 0.25)'
            : '0 6px 16px rgba(0, 0, 0, 0.12)'
        }
      }}
    >
      {plan.mostPopular && (
        <Tag 
          color="blue" 
          style={{ 
            position: 'absolute',
            top: '-12px',
            right: '24px',
            zIndex: 1,
            padding: '4px 12px',
          }}
        >
          Most Popular
        </Tag>
      )}
      <Space direction="vertical" style={{ width: '100%' }} size={24}>
        <div>
          <Title level={3} style={{ fontSize: '28px' }}>
            {plan.name}
          </Title>
          {trialMessage && plan.trialMessage && <Tag color="green">{plan.trialMessage}</Tag>}

          <Title level={4} style={{ margin: 0 }}>
            <Text strong style={{ fontSize: '32px', color: '#1890ff' }}>${plan.price / 100}</Text>
            <Text style={{ fontSize: '16px', opacity: 0.65 }}> / {plan.frequency}</Text>
          </Title>
        </div>

        <Button
          block
          className={
            isSubscribed && !isCanceled ? "bg-success-light success" : "button-blue "
          }
          style={{
            height: '48px',
            fontSize: '16px',
            fontWeight: 600,
            borderRadius: '8px',
            marginTop: '8px',
            marginBottom: '8px',
            transition: 'all 0.3s'
          }}
          disabled={isSubscribed && !isCanceled || plan.disabled}
          onClick={() => onSubscribeClick(plan)}
        >
          {isSubscribed && !isCanceled ? "Current" : ctaMessage}
        </Button>

        <List
          dataSource={plan.features}
          style={{ textAlign: 'left' }}
          renderItem={(feature) => (
            <List.Item style={{ padding: '12px 0', borderBottom: 'none' }}>
              <Space align="start">
                <CheckOutlined style={{ 
                  color: '#52c41a',
                  backgroundColor: '#f6ffed',
                  padding: '4px',
                  borderRadius: '50%',
                  fontSize: '12px'
                }} />
                <Text style={{ lineHeight: '1.5' }}>{feature}</Text>
              </Space>
            </List.Item>
          )}
        />
      </Space>
    </Card>
  );
};

export default PackageCard;

PackageCard.propTypes = {
  plan: PropTypes.object.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  onSubscribeClick: PropTypes.func.isRequired,
};
